<script>
import {defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton"
import InputText from "primevue/inputtext";
import {corpClientApiCall, updateCorpClientApiCall} from "@/services/website";
import { useApiCall } from '@/composables/useApiCall'
import {useI18n} from "vue-i18n";
import {required} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";
export default defineComponent({
  components: {
    ButtonSuccess,
    Editor,
    MainCard,
    Button,
    Skeleton,
    InputText,
  },
  setup() {
    const {t} = useI18n()
    const form = reactive({
      title_ru: '',
      description_ru: ''
    });
    const formRules = {
      title_ru: {required},
      description_ru: {required},
    }
    const v$ = useVuelidate(formRules, form);
    const isUpdated = ref(false)
    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(corpClientApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateCorpClientApiCall, true)

    const updateForm = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      try {
        await updateContentAction(form)
        if (contentUpdateData.value) {
          isUpdated.value = true
        }
      } catch (e) {
        console.error(e)
      }
    }

    watch(() => form,
        () => {
          isUpdated.value = false
        }, {deep: true})

    onBeforeMount( async () => {
      try {
        await getContentAction()
        if (contentData.value?.data) {
          form.title_ru = contentData.value.data.title_ru
          form.description_ru = contentData.value.data.description_ru
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      v$,
      form,
      isUpdated,
      updateForm,
      isContentLoading,
      contentError,
      contentUpdateError,
      isContentUpdateLoading,
    };
  }
})
</script>

<template>
  <div class="mt-7">
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>{{ t('website.corp-clients.title')}}</h1>
      <ButtonSuccess v-if="isUpdated" :label="t('button.changesSaved')" class="btn-fix-height"/>
      <Button v-else @click="updateForm" :loading="isContentUpdateLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
    </div>
    <Skeleton v-if="isContentLoading" width="100%" height="100px" class="mb-3"></Skeleton>
    <MainCard v-else :title="t('title')" class="mb-3">
      <div class="grid mb-2 mt-2">
        <div class="w-full">
            <span class="p-float-label w-full">
              <InputText
                  v-model="form.title_ru"
                  :class="['w-full', {'p-invalid': v$.title_ru.$errors.length || contentUpdateError?.data.title_ru}]"
                  id="corp-title"
              />
              <label for="corp-title">{{ t('input.placeholder.title') }} <i style="color: #E26B6B">*</i></label>
            </span>
          <span v-if="v$.title_ru.$errors.length || contentUpdateError?.data.title_ru" class="text-xs color-error">
              {{ contentUpdateError ? contentUpdateError.data.title_ru[0] : t(v$.title_ru.$errors[0].$message) }}
          </span>
        </div>
      </div>
    </MainCard>
    <Skeleton v-if="isContentLoading" width="100%" height="100px"></Skeleton>
    <MainCard v-else :title="t('description')" class="mb-3">
      <Editor v-model="form.description_ru" class="w-full"></Editor>
      <span v-if="v$.description_ru.$errors.length || contentUpdateError?.data.description_ru" class="text-xs color-error">
              {{ contentUpdateError ? contentUpdateError.data.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}
      </span>
    </MainCard>
  </div>
</template>

