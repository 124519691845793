import {createApp} from 'vue';
import uuid from "vue3-uuid";
import VueRouterMiddleware from 'vue-router-middleware';
import {createMetaManager} from "vue-meta";
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from '@/i18n/index'
import "./App.css";

import PrimeVue from 'primevue/config';

const app = createApp(App)
async function initApp() {
    app.use(uuid)
    app.use(store)
    app.use(router)
    app.use(PrimeVue)
    app.use(createMetaManager())
    app.use(VueRouterMiddleware, {router})
    app.use(i18n)
    app.mount('#app');
}

window.addEventListener('load', initApp)
