<script>
import {defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import ImageCard from "@/components/cards/ImageCard.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import Button from "primevue/button";
import {useApiCall} from "@/composables/useApiCall";
import {
  deleteHotelInfoPhotoApiCall,
  hotelInfoApiCall, updateHotelInfoApiCall,
  uploadHotelInfoPhotoApiCall
} from "@/services/website";
import Skeleton from "primevue/skeleton"
import {useI18n} from "vue-i18n";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@/utils/i18n-validators";

export default defineComponent({
  components: {
    ButtonSuccess,
    ButtonFileUpload,
    ImageCard,
    InputText,
    Editor,
    MainCard,
    Button,
    Skeleton,
  },
  setup() {
    const { t } = useI18n()
    const form = reactive({
      fields: {
        title_ru: '',
        description_ru: '',
        photos: []
      }
    });
    const formRules = {
      description_ru: {},
      title_ru: {required}
    }
    const files = ref([])
    const isUpdated = ref(false)
    const deleteUuid = ref([])
    const sendIndex = ref(null)
    const v$ = useVuelidate(formRules, form.fields);
    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(hotelInfoApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateHotelInfoApiCall, true)

    const {
      isLoading: isContentDeletePhotoLoading,
      executeApiCall: deleteContentPhotoAction,
    } = useApiCall(deleteHotelInfoPhotoApiCall, true)


    const {
      isLoading: isContentPhotoLoading,
      error: contentPhotoError,
      executeApiCall: uploadContentPhotoAction,
    } = useApiCall(uploadHotelInfoPhotoApiCall, true)

    const destroyFileMemory = (file) => {
      files.value = files.value.filter(item => item !== file)
    }
    const loadAboutFileMemory = (data) => {
      files.value = files.value.concat(data.files)
    }

    const deletePhoto = (uuid) => {
      form.fields.photos = form.fields.photos.filter(item => item.uuid !== uuid)
      deleteUuid.value.push(uuid)
    }
    const updateForm = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      sendIndex.value = null
      try {
        if (deleteUuid.value.length) {
          for (let i = 0; i < deleteUuid.value.length; i += 1) {
            await deleteContentPhotoAction({uuid: deleteUuid.value[i]})
          }
        }
        if (files.value.length) {
          for (let i = 0; i < files.value.length; i += 1) {
            sendIndex.value = files.value[i].objectURL
            const formData = new FormData()
            formData.append('type', 'improvement')
            formData.append('photo', files.value[i])
            await uploadContentPhotoAction(formData)
          }
        }
        await updateContentAction({
          title_ru: form.fields.title_ru,
          description_ru: form.fields.description_ru
        })
        if (contentUpdateData.value) {
          await deleteLocalData()
          isUpdated.value = true
        }
      } catch (e) {
        console.error(e)
      }
    }

    const deleteLocalData = async () => {
      form.fields.photos = contentUpdateData.value.data.photos
      files.value = []
      deleteUuid.value = []
    }

    watch(() => [form.fields, files.value, deleteUuid.value],
        ()=> {
          isUpdated.value = false
        }, {deep: true})

    onBeforeMount( async () => {
      try {
        await getContentAction()
        if (contentData.value?.data) {
          form.fields.title_ru = contentData.value.data.title_ru
          form.fields.description_ru = contentData.value.data.description_ru
          form.fields.photos = contentData.value.data.photos
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      v$,
      form,
      files,
      sendIndex,
      isUpdated,
      isContentLoading,
      contentError,
      isContentUpdateLoading,
      contentUpdateError,
      isContentPhotoLoading,
      isContentDeletePhotoLoading,
      contentPhotoError,
      deletePhoto,
      destroyFileMemory,
      loadAboutFileMemory,
      updateForm,
    };
  }
})
</script>

<template>
  <div class="mt-7">
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>{{ t('website.about.title') }}</h1>
      <ButtonSuccess v-if="isUpdated" :label="t('button.changesSaved')" class="btn-fix-height"/>
      <Button
          v-else
          @click="updateForm"
          :loading="isContentUpdateLoading || isContentPhotoLoading || isContentDeletePhotoLoading"
          :label="t('button.saveChange')"
          class="btn-primary font-light btn-fix-height"
      />
    </div>
    <Skeleton v-if="isContentLoading" width="100%" height="157px" class="mb-3 mt-2"></Skeleton>
    <MainCard v-else :title="t('title')" class="mb-3">
      <div class="mb-2 mt-2">
        <div class="w-full">
            <span class="p-float-label w-full">
              <InputText
                  v-model="form.fields.title_ru"
                  :class="['w-full', {'p-invalid': v$.title_ru.$errors.length || contentUpdateError?.data.title_ru}]"
                  id="about-title"
              />
              <label for="about-title">{{ t('input.placeholder.title') }} <i style="color: #E26B6B">*</i></label>
            </span>
          <span v-if="v$.title_ru.$errors.length || contentUpdateError?.data.title_ru" class="text-xs color-error">
              {{ contentUpdateError ? contentUpdateError.data.title_ru[0] : t(v$.title_ru.$errors[0].$message) }}
            </span>
        </div>
      </div>
    </MainCard>
    <Skeleton v-if="isContentLoading" width="100%" height="153px" class="mb-3"></Skeleton>
    <MainCard v-else :title="t('description')" class="mb-3">
      <Editor v-model="form.fields.description_ru" class="w-full"></Editor>
      <span v-if="contentUpdateError?.data.description_ru" class="text-xs color-error">
            {{ contentUpdateError.data.description_ru[0] }}
          </span>
    </MainCard>
    <Skeleton v-if="isContentLoading" width="100%" height="155px"></Skeleton>
    <MainCard v-else :title="t('images.title')" >
      <div class="grid">
        <template v-if="form.fields.photos.length">
          <div v-for="(item, i) in form.fields.photos" :key="i" class="col-12 md:col-2">
            <ImageCard :src="item.preview_url" :handle="() => deletePhoto(item.uuid)"/>
          </div>
        </template>
        <template v-if="files.length">
          <div v-for="(file, i) in files" :key="i" class="col-12 md:col-2">
            <ImageCard :src="file.objectURL" :handle="() => destroyFileMemory(file)"/>
            <span v-if="contentPhotoError?.data.photo && sendIndex === file.objectURL" class="flex mb-2 text-xs color-error">
              {{ contentPhotoError.data.photo[0] }}
            </span>
          </div>
        </template>
        <div class="col-12">
          <ButtonFileUpload
              @chooseFiles="loadAboutFileMemory"
              :label="t('button.addImage')"
              :multiple="true"
              :clear-files-after-select="true"
          />
        </div>
      </div>
    </MainCard>
  </div>
</template>
