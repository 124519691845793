import {
    loadBuildings,
    loadBuilding,
    createBuilding,
    destroyBuilding,
    createBuildingLocationPoint,
    updateBuildingLocationPoint,
    updateBuilding,
    changePointTypeLocationPoint,
    addBuildingPhoto,
    deleteBuildingPhoto,
    deletePointLocationPoint,
} from "@/services/buildings";

const state = () => ({
    listBuildings: [],
    currentBuilding: {},
    common_photos: [],
    infrastructure_photos: [],
    improvement_photos: [],
    delete_photos: [],
});

const actions = {
    async fetchBuildings({commit}, params = {}) {
        const buildings = await loadBuildings(params);
        commit('updateListBuildings', buildings);
    },
    async fetchCreateBuilding({commit}, body = {}) {
        await createBuilding(body);
    },
    async fetchBuilding({commit}, id) {
        const {data: building} = await loadBuilding(id);
        commit('updateCurrentBuilding', building);
    },
    async fetchUpdateBuilding({commit}, {id, body}) {
        const {data: building} =  await updateBuilding(id, body)
        commit('deletePhotosArray')
        commit('updateCurrentBuilding', building);
    },
    async fetchDestroyBuilding({commit}, id) {
        await destroyBuilding(id);
    },
    async fetchCreateBuildingLocationPoint({commit}, {id, type, body = {}}) {
        await createBuildingLocationPoint(id, type,body);
    },
    async fetchUpdateBuildingLocationPoint({commit}, {buildingId, pointId, type, body = {}}){
        await updateBuildingLocationPoint(buildingId, pointId, type, body);
    },
    async fetchChangePointTypeLocationPoint({commit}, {buildingId, pointId, type, body}) {
        await changePointTypeLocationPoint(buildingId, pointId, type,body);
    },
    async fetchDeleteLocationPoint({commit}, {buildingId, pointId, type}) {
        await deletePointLocationPoint(buildingId, pointId, type);
    },
    async fetchAddBuildingPhoto({commit}, {id, body}) {
        await addBuildingPhoto(id, body);
    },
    async fetchDeleteBuildingPhoto({commit}, {id, body}) {
        await deleteBuildingPhoto(id, body);
    }
};

const mutations = {
    updateListBuildings(state, buildings) {
        state.listBuildings = buildings;
    },
    updateCurrentBuilding(state, building) {
        state.currentBuilding = {attraction_points: state.currentBuilding.attraction_points, location_points: state.currentBuilding.location_points, ...building};
    },
    addBuildingsImages(state, data) {
        state[data.key].push(...data.files)
    },
    deleteBuildingsImages(state, data) {
        state[data.key] = state[data.key].filter(item => item !== data.file)
    },
    addDeleteBuildingsImages(state, data) {
        state.delete_photos.push(data)
    },
    deletePhotosArray(state) {
        state.common_photos = []
        state.infrastructure_photos = []
        state.improvement_photos = []
        state.delete_photos = []
    }
};

const getters = {
    getListBuildings(state) {
        return state.listBuildings;
    },
    getCurrentBuilding(state) {
        return state.currentBuilding;
    },
    getCommonBuildingPhotos(state) {
        return state.common_photos;
    },
    getInfrastructureBuildingPhotos(state) {
        return state.infrastructure_photos;
    },
    getImprovementBuildingPhotos(state) {
        return state.improvement_photos;
    },
    getDeleteBuildingPhotos(state) {
        return state.delete_photos;
    },
};

export default {state, actions, mutations, getters};