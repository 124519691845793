<script>
import {defineComponent, reactive, ref} from "vue";

import InputText from "primevue/inputtext"
import Checkbox from 'primevue/checkbox'
import {useStore} from "vuex";
import {useRoute} from "vue-router";
export default defineComponent({
  components: {InputText, Checkbox},
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const form = reactive({
      fields: {
        id: props.service.id,
        cost: props.service.cost || 0,
        set: !!props.service.set,
      }
    });
    const errors = ref(null)

    const changeSetService = async () => {
      errors.value = null;
      try {
        await store.dispatch('fetchTouchRoomCategoryService', {
          segmentId: route.params.segmentId,
          roomCategoryId: route.params.categoryId,
          body: {
            set: Number(!form.fields.set),
            service: form.fields.id,
            cost: form.fields.cost,
          }
        })
        const prepareData = {...props.service}
        prepareData.set = form.fields.set
        prepareData.cost = form.fields.cost
        store.commit('updateRoomCategoryService', prepareData)
      } catch (e) {
        form.fields.set = false
        errors.value = e.response.data.data
      }
    };

    return {form, changeSetService, errors};
  }
});
</script>

<template>
  <div class="form flex align-items-start p-4 bg-white border-round-xl">
    <div class="flex col-8 gap-3">
      <Checkbox
          @click="changeSetService"
          :binary="true"
          v-model="form.fields.set"
          class="mt-2"
      />
      <div class="flex flex-column sm:align-items-start gap-3">
        <div class="text-2xl font-bold">
                <span class="text-xl font-bold text-900">
                  {{ service.name_ru }}
                </span>
        </div>
        <div class="flex align-items-center gap-3">
                  <span class="flex align-items-center color-black-40 gap-2">
                    <span class="font-semibold" v-html="service.description_ru"></span>
                  </span>
        </div>
      </div>
    </div>
    <div class="flex mt-3 flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1">
      <div class="flex-column align-items-center sm:align-items-end sm:gap-2">
        <span class="p-float-label">
        <InputText
            v-model="form.fields.cost"
            :class="{'p-invalid': errors?.cost}"
            id="cost"
            class="w-full"
            :disabled="form.fields.set"
        />
        <label class="text-xs" for="cost">Укажите стоимость, ₽</label>
      </span>
        <span v-if="errors?.cost" class="flex text-xs color-error">
          {{ errors.cost[0] }}
      </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.form {
  height: 100%;
}
</style>