<script>
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import {useStore} from "vuex";
import Skeleton from "primevue/skeleton"
import Button from "primevue/button";
import Paginator from "primevue/paginator";
import TabMenu from "primevue/tabmenu";
import ObjectsTable from "@/components/tables/ObjectsTable";
import Tab from "@/components/tabs/Tab.vue";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {Button, ObjectsTable, Paginator, TabMenu, Tab, Skeleton},
  setup() {
    const store = useStore();

    const first = ref(0);
    const activeSegmentIndex = ref(0);
    const isLoading = ref(true);
    const isCategoriesLoading = ref(true);
    const objects = computed(() => store.getters.getListObjects);
    const segments = computed(() => store.getters.getListSegments);
    const categories = computed(() => store.getters.getListCategories);

    const activeSegment = computed(() => segments.value[activeSegmentIndex.value]);
    const activeCategory = ref(null);
    const categoriesTab = computed(() => categories.value.map(category => ({
      label: category.name_ru,
      type: category.name_ru,
      ...category
    })))

    const loadCategoriesBySegment = async () => {
      isCategoriesLoading.value = true
      await store.commit('updateListCategories', [])
      try {
        await store.dispatch('loadCategoriesByFilter', activeSegment.value.id);
        first.value = 0
        if (categories.value.length) {
          activeCategory.value = categories.value[0]
          await toggleTabHandler(activeCategory.value)
        } else {
          await store.commit('updateListObjects', [])
        }
      } catch (e) {
        console.error(e);
      }
      isCategoriesLoading.value = false
    };

    const toggleTabHandler = async (category) => {
      isLoading.value = true
      activeCategory.value = category.id
      try {
        await store.dispatch('fetchObjectsByCategory', {
          category: category.id,
          segment: activeSegment.value.id,
          page: 1,
        });
      } catch (e) {
        console.error(e);
      }
      isLoading.value = false
    }

    const loadObject = async () => {
      try {
        isLoading.value = true
        await store.dispatch('fetchObjectsByCategory', {
          category: activeCategory.value,
          segment: activeSegment.value.id,
          page: ((first.value / (objects.value?.data?.pagination?.per_page ?? 1)) + 1),
        });
        window.scrollTo(0, 0);
      } catch (e) {
        console.error(e);
      }
      isLoading.value = false;
    };

    watch(() => activeSegmentIndex.value, async () => await loadCategoriesBySegment());

    watch( () => first.value, async (index) => {
      await loadObject();
    });

    onBeforeMount(async () => {
      try {
        await store.dispatch('loadSegments');
        await store.dispatch('loadCategoriesByFilter', store.getters.getListSegments[0].id);
        activeCategory.value = categories.value[0].id;
        isCategoriesLoading.value = false;
        await store.dispatch('fetchObjectsByCategory', {
          category: store.getters.getListCategories[0].id,
          segment: activeSegment.value.id,
          page: 1,
        });
      } catch (e) {
        console.error(e);
      }
      isLoading.value = false;
    })

    return {
      first,
      objects,
      segments,
      categories,
      categoriesTab,
      activeSegment,
      activeSegmentIndex,
      isLoading,
      toggleTabHandler,
      isCategoriesLoading,
    };
  }
})
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <h1>Объекты</h1>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="flex justify-content-start">
      <TabMenu v-model:activeIndex="activeSegmentIndex" :model="segments.map(segment => ({label: segment.name_ru}))" />
      <Skeleton v-if="!segments.length" width="100%" height="50px"></Skeleton>
    </div>
  </section>
  <section class="py-2 mb-3">
    <div class="flex justify-content-start">
      <Skeleton v-if="isCategoriesLoading" width="100%" height="37px"></Skeleton>
      <Tab v-else :tabs="categoriesTab" :default-tab-switch="categoriesTab[0]" @toggleTab="toggleTabHandler" />
    </div>
  </section>

  <section v-if="!isCategoriesLoading && categoriesTab.length" class="py-2 mb-3">
    <div class="grid">
      <div class="col-12">
        <ObjectsTable :objects="objects?.data?.data ?? []" :isLoading="isLoading"/>
        <Paginator
            v-model:first="first"
            :rows="objects?.data?.pagination?.per_page ?? 0"
            :totalRecords="objects?.data?.pagination?.total ?? 0"
            class="justify-content-start"
        ></Paginator>
      </div>
    </div>
  </section>
  <section>
    <Skeleton v-if="isCategoriesLoading" width="100%" height="150px"></Skeleton>
  </section>
</template>
<style lang="scss" scoped>
::v-deep(.p-component-overlay) {
  background-color: rgba(255, 255, 255, 0.6);
}
::v-deep(.p-datatable-loading-icon) {
  path {
    fill: var(--color-primary);
  }
}
::v-deep(.p-menuitem-text) {
  white-space: nowrap;
}
</style>