import http from "../plugins/axios";

const sendSupportMessageApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/support',
        { ...params },
        { signal: abortController?.signal })
    return data
}
 export { sendSupportMessageApiCall }
