<script>
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import {useVendor} from "@/hooks/vendor";

import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Tab from "@/components/tabs/Tab";
import FileUpload from "@/components/FileUpload";
import Breadcrumb from "@/components/Breadcrumb";
import MainCard from "@/components/cards/MainCard";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import VendorIndividualPersonForm from "@/components/forms/VendorIndividualPersonForm";
import VendorJuridicalPersonForm from "@/components/forms/VendorJuridicalPersonForm";
import {useCountries} from "@/hooks/countries";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    Button, Tab, Breadcrumb, MainCard, FileUpload, ButtonFileUpload, InputText,
    ButtonSuccess, VendorIndividualPersonForm, VendorJuridicalPersonForm
  },
  setup() {
    const {
      selectFileMemory,
      file,
      form,
      v$,
      vip$,
      createVendor,
      isCreate: isCreated,
      deleteFileMemory,
      errors,
    } = useVendor();
    const {loadCountries} = useCountries();
    const breadcrumbs = ref([
      {label: 'Реквизиты', router: '/vendors/list'},
      {label: 'Создание реквизитов'}
    ]);

    const tabs = ref([{
      label: 'Юр.лицо',
      type: 'ul'
    }, {
      label: 'Ип',
      type: 'ip',
    }]);

    const tabSteps = ref(new Map([
      ['ul', 'VendorJuridicalPersonForm'],
      ['ip', 'VendorIndividualPersonForm'],
    ]));

    const currentStep = ref('ul');

    const toggleTabHandler = ({type}) => {
      errors.value = null
      currentStep.value = type
    };

    const create = async () => {
      await createVendor(currentStep.value);
    };

    const validators = ref({
      "ul": v$,
      "ip": vip$
    });

    const validator = computed(() => {
      return validators.value[currentStep.value]
    });

    watch([form, currentStep], () => {
      isCreated.value = false;
    });

    onBeforeMount(async () => {
      try {
        await loadCountries();
      } catch (e) {
        console.error(e)
      }
    })

    return {
      selectFileMemory, file, deleteFileMemory,
      form, tabSteps, currentStep,
      breadcrumbs, create, tabs,
      toggleTabHandler, validator,
      isCreated, v$, vip$, errors
    };
  }
});
</script>

<template>
  <div class="vendor">
    <section class="py-2 mb-3">
      <div class="flex justify-content-between">
        <Breadcrumb :data="breadcrumbs" separator="/"/>
        <div class="flex">
          <ButtonSuccess v-if="isCreated" label="Реквизит создан"/>
          <Button v-else label="Создать реквизиты" class="btn-primary font-light ml-2" @click="create"/>
        </div>
      </div>
    </section>
    <section class="py-2 mb-3">
      <div class="grid">
        <div class="col-12">
          <MainCard title="Выберите тип">
            <Tab @toggleTab="toggleTabHandler" :default-tab-switch="{ type: currentStep }" :tabs="tabs"/>
          </MainCard>
        </div>
      </div>
    </section>

    <template v-if="currentStep === 'ul'">
      <VendorJuridicalPersonForm :form="form" :errors="v$" :responseError="errors?.data"/>
    </template>

    <template v-if="currentStep === 'ip'">
      <VendorIndividualPersonForm :form="form" :errors="vip$" :responseError="errors?.data"/>
    </template>

    <section class="py-2 mb-3">
      <div class="grid">
        <div class="col-12">
          <MainCard title="Банковские реквизиты в формате PDF">
            <div class="flex flex-column">
              <div class="grid" v-if="file">
                <div class="col-12 md:col-3 sm:col-6">
                  <div class="file-vendor-card px-2 py-3 border-round-xl relative flex align-items-center">
                    <div class="flex">
                      <i class="pi pi-file-edit color-primary" style="font-size: 1rem"></i>
                      <span class="ml-5 text-xs">{{ file.name }}</span>
                    </div>
                    <i
                        @click="deleteFileMemory(file)"
                        class="pi pi-times color-black-20 text-white cansel-button cursor-pointer ml-auto"
                        style="font-size: 1rem"
                    ></i>
                  </div>
                </div>
              </div>

              <div class="grid">
                <div class="col-12">
                  <div class="md:col-3:sm-col-6">
                    <ButtonFileUpload @chooseFiles="selectFileMemory" :clear-files-after-select="true"
                                      accept=".pdf,.doc,.docx,.xls" label="Загрузить реквизиты" :multiple="false"/>
                  </div>
                </div>
              </div>
            </div>
          </MainCard>
        </div>
      </div>
    </section>
  </div>


  <!--  <section class="py-2 mb-3">-->
  <!--    <MainCard title="Персональная скидка">-->
  <!--      <div class="grid">-->
  <!--        <div class="col-12 md:col-4">-->
  <!--          <span class="p-float-label w-full">-->
  <!--            <InputText-->
  <!--                v-model="form.discount"-->
  <!--                :class="{'p-invalid': validator.discount.$errors.length}"-->
  <!--                id="discount"-->
  <!--                class="w-full"-->
  <!--            />-->
  <!--            <label for="discount">Скидка  *</label>-->
  <!--          </span>-->
  <!--          <span v-if="validator.discount.$errors.length" class="text-xs color-error">-->
  <!--            {{ validator.discount.$errors[0].$message }}-->
  <!--          </span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </MainCard>-->
  <!--  </section>-->
</template>
<style lang="scss" scoped>
.vendor {
  ::v-deep(.p-float-label) {
    label {
      font-size: 11px;
      margin-top: -0.4rem;
    }

    span {
      font-size: 11px;
    }

    .icon-number {
      span {
        margin-left: 5px;
      }
    }
  }

  ::v-deep(.p-card-title) {
    label {
      font-size: 13px;
    }
  }

  ::v-deep(.p-inputtext) {
    font-size: 11px;
  }

  .file-vendor-card {
    height: 48px;
    border-radius: 4px;
    border: 1px solid var(--color-black-20);
  }

  ::v-deep(.calendar-icon) {
    position: absolute;
    top: 7px;
    right: 10px;
    pointer-events: none;
    cursor: pointer;
  }
}

</style>