<script>
import {defineComponent} from "vue";
import Card from "primevue/card";

export default defineComponent({
  components: {
    Card,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    styles: {
      type: Object,
      required: false
    },
    isRequired: {
      type: Boolean,
      required: false
    },
    withDelete: {
      type: Boolean,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  emits: ['deleteItem'],
  setup(props, {emit}) {
    const deleteItem = () => {
      emit('deleteItem', props.index)
    }
    return {
      deleteItem
    }
  }
});
</script>

<template>
  <Card :class="styles">
    <template #title v-if="title">
      <div class="flex justify-content-between">
        <span>{{ title }}<i v-if="isRequired" style="color: #E26B6B">*</i></span>
        <button v-if="withDelete" class="reset-button">
          <i @click="deleteItem" class="pi pi-times cansel-button cursor-pointer" style="font-size: 1rem"></i>
        </button>
        <slot name="title-action"></slot>
      </div>
    </template>
    <template #content>
      <slot />
    </template>
  </Card>
</template>

<style scoped>
.p-card {
  border-radius: 8px;
  box-shadow: none;
}

.p-card-title {
  font-size: 3rem !important;
}
.reset-button {
  background: unset;
  border: none;
}
</style>
