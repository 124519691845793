<script>
import {defineComponent, computed, ref, watch} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {useLanguages} from "@/hooks/useLanguages";
import {useMeta} from "vue-meta";
import Breadcrumb from "@/components/Breadcrumb.vue";
import MainCard from "@/components/cards/MainCard.vue";
import MultiSelect from "primevue/multiselect";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import InputNumberPhone from "@/components/inputs/InputNumberPhone.vue";
import SelectPhoneModal from "@/components/modals/SelectPhoneModal.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import {useUsers} from "@/hooks/user";
import {useRouter} from "vue-router";

export default defineComponent({
  layout: {
    name: 'AdminLayout',
  },
  components: {
    Breadcrumb, MainCard, MultiSelect, InputText, Button, Dropdown, SelectPhoneModal, InputNumberPhone, ButtonSuccess
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const store = useStore();
      await store.dispatch('fetchRoles');
      await store.dispatch('fetchCountries');
      store.commit('selectCountryByCountryName', "Russia")
      next();
    } catch (e) {
      console.error(e);
    }
  },
  setup() {
    const {t} = useI18n();
    const {form: formReactive, v$} = useUsers();
    const router = useRouter()
    useMeta({
      title: t('pages-names.users')
    });

    const store = useStore();
    const errors = ref(null);
    const {languages} = useLanguages();

    const roles = computed(() => store.getters.getRolesList);
    const countries = computed(() => store.getters.getCountriesList);
    const country = computed(() => store.getters.getSelectCountry);
    const visible = ref(false);

    const breadcrumbs = ref([
      {label: t('pages-names.users'), router: {name: 'users'}},
      {label: t('pages-names.create-user')}
    ]);

    const toggleChangePhoneCode = () => {
      visible.value = !visible.value
    }
    const toCreateUser = async () => {
      errors.value = null
      try {
        const result = await v$.value.$validate();
        if (!result) {
          return;
        }

        await store.dispatch('fetchCreateUser', {
          ...formReactive.value,
          phone_code: country.value.phone_code
        });
        await router.push({name: 'users'})
      } catch (e) {
        if (e.response.data.data) {
          errors.value = {...e.response.data.data}
        } else {
          errors.value = e.response.data.errors
        }
      }
    };

    return {
      v$,
      breadcrumbs,
      roles,
      formReactive,
      toCreateUser,
      languages,
      countries,
      country,
      visible,
      errors,
      t,
      toggleChangePhoneCode,
    };
  }
});
</script>

<template>
  <SelectPhoneModal
      :countries="countries"
      v-model:modelValue="visible"
  />
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>
      <div class="flex">
        <Button
            :disabled="v$.result"
            :label="t('actions.create-user')"
            class="btn-primary font-light ml-2"
            @click="toCreateUser"
        />
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="grid mb-2 h-max">
      <div class="col-12 md:col-4 sm:col-6">
        <MainCard :title="t('card-names.basic-registration-information')" :styles="{'h-full': true }">
          <div class="flex flex-column gap-3">
            <div class="w-full mb-3">
              <span class="p-float-label w-full">
                <InputText
                    :class="['w-full', {'p-invalid': v$.last_name.$errors.length || errors?.last_name}]"
                    id="last_name"
                    v-model="formReactive.last_name"
                />
                <label for="last_name">{{ t('labels.last-name') }}<i style="color: #E26B6B">*</i></label>
              </span>
              <span class="color-error text-xs" v-if="v$.last_name.$errors.length || errors?.last_name">
                {{ errors ? errors.last_name[0] : t(v$.last_name.$errors[0].$message) }}
              </span>
            </div>

            <div class="w-full mb-3">
              <span class="p-float-label w-full">
                <InputText
                    v-model="formReactive.first_name"
                    :class="['w-full', {'p-invalid': v$.first_name.$errors.length || errors?.first_name}]"
                    id="first_name"
                />
                <label for="first_name">{{ t('labels.first-name') }}<i style="color: #E26B6B">*</i></label>
              </span>
              <span class="text-xs color-error" v-if="v$.first_name.$errors.length || errors?.first_name">
                {{ errors ? errors.first_name[0] : t(v$.first_name.$errors[0].$message) }}
              </span>
            </div>
            <div class="w-full mb-3">
              <span class="p-float-label w-full">
                <InputText
                    id="patronymic"
                    v-model="formReactive.patronymic"
                    :class="['w-full', {'p-invalid': errors?.patronymic}]"
                />
                <label for="patronymic">{{ t('labels.patronymic') }}</label>
              </span>
              <span class="text-xs color-error" v-if="errors?.patronymic">
                  {{ errors.patronymic[0] }}
              </span>
            </div>
          </div>
        </MainCard>
      </div>
      <div class="col-12 md:col-4 sm:col-6">
        <MainCard :title="t('card-names.contact-data')" :styles="{'h-full': true }">
          <div class="flex flex-column gap-3">
            <div class="mb-3">
              <div class="w-full">
                <InputNumberPhone
                    :classes="{'p-invalid': v$.phone.$errors.length || errors?.phone}"
                    v-model="formReactive.phone"
                    :phone-code="country?.phone_code"
                    @toggleChangePhoneCode="toggleChangePhoneCode"
                    isRequired
                />
                <span class="color-error text-xs" v-if="v$.phone.$errors.length || errors?.phone">
                {{ errors ? errors.phone[0] : t(v$.phone.$errors[0].$message) }}
                </span>
              </div>
            </div>
            <div class="w-full mb-3">
              <span class="p-float-label w-full">
                <InputText
                    v-model="formReactive.email"
                    :class="['w-full', {'p-invalid': v$.email.$errors.length || errors?.email}]"
                    id="phone"
                />
                <label for="phone">{{ t('labels.email-for-notices') }}<i style="color: #E26B6B">*</i></label>
              </span>
              <span class="text-xs color-error" v-if="v$.email.$errors.length || errors?.email">
                {{ errors ? errors.email[0] : t(v$.email.$errors[0].$message) }}
              </span>
            </div>
          </div>
        </MainCard>
      </div>
      <div class="col-12 md:col-4 sm:col-6">
        <div class="grid flex-column h-max">
          <div class="col-12 h-full">
            <MainCard :title="t('card-names.role')" isRequired :styles="{'h-full': true}">
              <MultiSelect
                  v-model="formReactive.roles"
                  :class="['w-full', {'p-invalid': v$.roles.$errors.length || errors?.roles}]"
                  display="chip"
                  :options="roles"
                  optionLabel="name_ru"
                  option-value="id"
                  :placeholder="t('labels.roles')"
              />
              <span class="color-error text-xs" v-if="v$.roles.$errors.length || errors?.roles">
                {{ errors ? errors.roles[0] : t(v$.roles.$errors[0].$message) }}
              </span>
            </MainCard>
          </div>
<!--          <div class="col-12 h-full">-->
<!--            <MainCard :title="t('card-names.language')" isRequired :styles="{'h-full': true}">-->
<!--              <Dropdown-->
<!--                  v-model="formReactive.language"-->
<!--                  :class="['w-full', {'p-invalid': v$.language.$errors.length || errors?.language}]"-->
<!--                  :options="languages"-->
<!--                  optionLabel="label"-->
<!--                  option-value="value"-->
<!--                  :placeholder="t('labels.language')"-->
<!--              />-->
<!--              <span class="color-error text-xs" v-if="v$.language.$errors.length || errors?.language">-->
<!--                {{ errors ? errors.language[0] : t(v$.language.$errors[0].$message) }}-->
<!--              </span>-->
<!--            </MainCard>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </section>
</template>
