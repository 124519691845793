<script>
import {computed, defineComponent, onBeforeMount, reactive, ref} from "vue";
import {useStore} from "vuex";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import Breadcrumb from "@/components/Breadcrumb";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Skeleton from "primevue/skeleton"
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {required} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";
import {useApiCall} from "@/composables/useApiCall";
import {
  servicesItemApiCall,
  updateServiceApiCall,
  addServiceApiCall,
  deleteServicesItemApiCall,
} from "@/services/articles";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    ButtonSuccess,
    Button,
    InputText,
    MainCard,
    Breadcrumb,
    Editor,
    ButtonFileUpload,
    ImageCard,
    ConfirmationModal,
    Skeleton,
  },
  setup() {
    const {t} = useI18n();
    const store = useStore();
    const router = useRouter()
    const route = useRoute()
    const errors = ref(null)
    const breadcrumbs = ref([]);
    const isContentLoading = ref(true)
    const form = reactive({
      fields: {
        id: '',
        title_ru: '',
        description_ru: '',
        photo: null
      }
    })
    const rules = {
      title_ru: {required},
      description_ru: {required},
      photo: {required},
    };
    const v$ = useVuelidate(rules, form.fields);
    const visibleModal = ref(false);
    const isLoading = computed(() => isContentAddLoading.value || isContentUpdateLoading.value)
    const {
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(servicesItemApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateServiceApiCall, true)

    const {
      isLoading: isContentAddLoading,
      data: contentAddData,
      error: contentAddError,
      executeApiCall: addContentAction,
    } = useApiCall(addServiceApiCall, true)

    const {
      isLoading: isContentDeleteLoading,
      data: contentDeleteData,
      error: contentDeleteError,
      executeApiCall: deleteContentAction,
    } = useApiCall(deleteServicesItemApiCall, true)
    const changeConfirmationStateModal = () => {
      visibleModal.value = !visibleModal.value
      store.dispatch('changeStateModal', {
        type: 'confirmationDestroyPartnerModal',
        bool: visibleModal.value
      });
    };

    const toggleDestroyPartner = async () => {
      try {
        await deleteContentAction({id: route.params.id});
        if (contentDeleteData.value) {
          await router.push({name: 'website', query: {tab: 'services'}});
        }
      } catch (e) {
        console.error(e);
      }
    };

    const loadFileMemory = (data) => {
      form.fields.photo = data.files[0]
    }

    const update = async () => {
      const result = await v$.value.$validate()
      errors.value = null
      if (result) {
        try {
          const formData = new FormData()
          formData.append('title_ru', form.fields.title_ru)
          formData.append('description_ru', form.fields.description_ru)
          if (form.fields.photo?.objectURL) {
            formData.append('photo', form.fields.photo)
          }
          if(route.params.id) {
            await updateContentAction({
              fields: formData,
              id: route.params.id,
            })
          } else {
            await addContentAction(formData)
          }
          if (contentUpdateData.value || contentAddData.value) {
            await router.push({name: 'website', query: {tab: 'services'}})
          }
        } catch (e) {
          if (contentUpdateError.value) {
            errors.value = contentUpdateError.value.data
          }
          if (contentAddError.value) {
            errors.value = contentAddError.value.data
          }
          console.error(e)
          return
        }
      }
    }

    onBeforeMount(async () => {
      if (route.params.id) {
        try {
          await getContentAction({id: route.params.id})
          if (contentData.value) {
            form.fields.title_ru = contentData.value.data.title_ru
            form.fields.description_ru = contentData.value.data.description_ru
            form.fields.photo = contentData.value.data.photo
          }
        } catch (e) {
          console.error(e)
        }
      }
      breadcrumbs.value = [
        {label: 'Сайт / Услуги ', router: {name: 'website', query: {tab: 'services'}}},
        {label: contentData.value ? `${contentData.value.data.title_ru}` : 'Создание услуги'}
      ];
      isContentLoading.value = false
    });

    return {
      t,
      form,
      breadcrumbs,
      update,
      loadFileMemory,
      v$,
      errors,
      route,
      visibleModal,
      isContentDeleteLoading,
      isLoading,
      isContentLoading,
      toggleDestroyPartner,
      changeConfirmationStateModal,
    };
  }
});
</script>

<template>
  <ConfirmationModal v-model:visible="visibleModal">
    <template #header>
      Удалить услугу
    </template>

    <template #default>
      <span class="flex mt-2">Вы уверенны, что хотите удалить услугу?</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="changeConfirmationStateModal"
                class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" :loading="isContentDeleteLoading" @click="toggleDestroyPartner" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>
      <div class="flex">
        <Button
            @click="update"
            :loading="isLoading"
            :label="route.params.id ? 'Сохранить изменения' : 'Создать'"
            :class="['btn-primary btn-fix-height font-light ml-3', {'px-8': !route.params.id}]"
        />
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="service__wrapper">
      <Skeleton v-if="isContentLoading" height="296px"></Skeleton>
      <MainCard v-else title="Изображение">
        <span v-if="errors?.photo || v$.photo.$errors?.length" class="flex text-xs color-error mb-2">
            {{ errors?.photo ? errors.photo[0] : t(v$.photo.$errors[0].$message) }}
        </span>
        <div class="flex flex-column gap-2">
          <div v-if="form.fields.photo" class="service__fix-width">
            <ImageCard :src="form.fields.photo.objectURL ? form.fields.photo.objectURL : form.fields.photo.original_url" withoutDelete/>
          </div>
          <div>
            <ButtonFileUpload
                @chooseFiles="loadFileMemory"
                :label="form.fields.photo? 'Заменить фото' : 'Добавить фото'"
                :multiple="false"
                :clear-files-after-select="true"
                :classes="{'service__fix-width': true}"
            />
          </div>
        </div>
      </MainCard>
      <Skeleton v-if="isContentLoading" height="296px"></Skeleton>
      <MainCard v-else title="Наименование услуги">
            <span class="p-float-label mb-2 w-full">
              <InputText
                  :class="{'p-invalid': v$.title_ru.$errors?.length || errors?.name_ru }"
                  id="name_ru"
                  class="w-full"
                  v-model="form.fields.title_ru"
              />
              <label for="name_ru">Введите наименование *</label>
            </span>
        <span v-if="v$.title_ru.$errors?.length || errors?.title_ru" class="text-xs color-error">{{ errors?.title_ru ? errors.title_ru[0] : t(v$.title_ru.$errors[0].$message) }}</span>
      </MainCard>
    </div>
  </section>

  <section class="py-2 mb-3">
    <Skeleton v-if="isContentLoading" height="195px"></Skeleton>
    <MainCard v-else title="Описание услуги">
      <div class="grid">
        <div class="col-12">
          <Editor v-model="form.fields.description_ru" class="w-full"></Editor>
          <span v-if="v$.description_ru.$errors?.length || errors?.description_ru" class="flex text-xs color-error mt-2">
            {{ errors?.description_ru ? errors.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}
          </span>
        </div>
      </div>
    </MainCard>
  </section>

  <section v-if="route.params.id" class="py-2 mb-4">
    <div class="flex justify-content-end">
      <span class="color-error underline cursor-pointer" @click="changeConfirmationStateModal">
        Удалить услугу
      </span>
    </div>
  </section>
</template>

<style lang="scss">
.service {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    @media screen and (min-width: 1200px) {
      column-gap: 30px;
      grid-template-columns: calc(45% - 30px) calc(55.5% - 30px);
    }
    @media screen and (min-width: 1600px) {
      column-gap: 30px;
      grid-template-columns: calc(25% - 30px) calc(32% - 30px);
    }
  }
  &__fix-width {
    width: 208px;
  }
}
</style>