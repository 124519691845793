<script>
import {defineComponent, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useLanguages} from "@/hooks/useLanguages";
import {useCountries} from "@/hooks/countries";
import {useRoles} from "@/hooks/role";
import {useUsers} from "@/hooks/user";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import Breadcrumb from "@/components/Breadcrumb.vue";
import MainCard from "@/components/cards/MainCard.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import InputNumberPhone from "@/components/inputs/InputNumberPhone.vue";
import SelectPhoneModal from "@/components/modals/SelectPhoneModal.vue";
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  layout: {
    name: 'AdminLayout',
  },
  components: {
    Breadcrumb, MainCard, InputText,
    Dropdown, Button, MultiSelect,
    ButtonSuccess, InputNumberPhone, SelectPhoneModal, ProgressSpinner
  },
  setup() {
    const {t} = useI18n();
    const errors = ref(null)
    useMeta({
      title: 'Редактирование пользователя'
    });

    const route = useRoute();
    const {loadRoles, roles} = useRoles();
    const {languages} = useLanguages();
    const {countries, selectCountry: country, loadCountries, toSelectCountryByPhoneCode} = useCountries();
    const {loadUser, updateUser, user, v$, form: formReactive, isUpdated} = useUsers();

    const visible = ref(false);
    const isLoading = ref(true);
    const isSaveLoading = ref(false);
    const saveUserData = async () => {
      errors.value = null
      isSaveLoading.value = true
      try {
        const data = {};
        for (let key in formReactive.value) {
          if (key === 'avatar') continue;
          data[key] = formReactive.value[key];
        }
        await updateUser({
          id: route.params.id,
          data
        });
      } catch (e) {
        if (e.response.data.data) {
          errors.value = {...e.response.data.data}
        } else {
          errors.value = e.response.data.errors
        }
      }
      isSaveLoading.value = false
    };

    watch(() => formReactive.value, () => {
      isUpdated.value = false;
    }, {deep: true});

    const breadcrumbs = ref([]);

    const toggleChangePhoneCode = () => {
      visible.value = !visible.value
    }

    onMounted(async () => {
      try {
        await loadUser(route.params.id);
        await loadRoles();
        await loadCountries();
        toSelectCountryByPhoneCode();
        const label = ((user.value.last_name ? user.value.last_name + ' ' : '') ?? ' -')
            + ((user.value.first_name ? user.value.first_name + ' ' : '') ?? ' -')
            + ((user.value.patronymic ? user.value.patronymic + ' ' : '') ?? ' -');

        breadcrumbs.value = [
          {label: t('pages-names.edit-user'), router: {name: 'users'}},
          {label}
        ];
        formReactive.value = {...user.value};
        formReactive.value.roles = user.value.roles.map(role => role.id);
      } catch (e) {
        console.error(e)
      }
      isLoading.value = false
    });

    return {
      user,
      roles,
      breadcrumbs,
      formReactive,
      saveUserData,
      isUpdated,
      visible,
      languages,
      countries,
      country,
      t,
      v$,
      errors,
      toggleChangePhoneCode,
      isLoading,
      isSaveLoading,
    };
  }
})
</script>

<template>
  <SelectPhoneModal
      v-model:modelValue="visible"
      :countries="countries"
  />
  <div v-if="isLoading" class="spinner__wrapper">
    <ProgressSpinner />
  </div>
  <section v-else class="py-2 mb-3">
    <div class="flex justify-content-between mb-3">
      <Breadcrumb :data="breadcrumbs" separator="/"/>

      <div class="flex">
        <Button v-if="!isUpdated" :loading="isSaveLoading" :label="t('labels.save-changes')" class="btn-primary font-light" @click="saveUserData"/>
        <ButtonSuccess v-if="isUpdated" :label="t('labels.changes-saved')"/>
      </div>
    </div>
    <div class="flex justify-content-between mb-3">
      <span class="color-black-40">
        <template v-for="(role, i) in user?.roles" :key="i">
          {{ role.name_ru }}
          <template v-if="i !== user.roles.length - 1"> + </template>
        </template>
      </span>
    </div>

    <div class="grid mb-3 h-max">
      <div class="col-12 md:col-4">
        <MainCard :styles="{'h-full': true}" :title="t('card-names.basic-registration-information')">
          <div class="flex flex-column gap-3">
            <div class="mb-3">
              <span class="p-float-label w-full">
                <InputText
                    v-model="formReactive.last_name"
                    :class="['w-full', {'p-invalid': v$.last_name.$errors.length || errors?.last_name}]"
                    id="last_name"
                />
                <label for="last_name">{{ t('labels.last-name') }}<i style="color: #E26B6B">*</i></label>
              </span>
              <span v-if="v$.last_name.$errors.length || errors?.last_name" class="color-red text-xs">
                {{ errors ? errors.last_name[0] : t(v$.last_name.$errors[0].$message) }}
              </span>
            </div>
            <div class="mb-3">
              <span class="p-float-label w-full">
                <InputText
                    id="first_name"
                    :class="['w-full', {'p-invalid': v$.first_name.$errors.length || errors?.first_name}]"
                    v-model="formReactive.first_name"
                />
                <label for="first_name">{{ t('labels.first-name') }}<i style="color: #E26B6B">*</i></label>
              </span>
              <span v-if="v$.first_name.$errors.length || errors?.first_name" class="color-red text-xs">
                {{ errors ? errors.first_name[0] : t(v$.first_name.$errors[0].$message) }}
              </span>
            </div>
            <div class="mb-3">
              <span class="p-float-label w-full">
                <InputText
                    id="patronymic"
                    :class="['w-full', {'p-invalid': errors?.patronymic}]"
                    v-model="formReactive.patronymic"
                />
                <label for="patronymic">{{ t('labels.patronymic') }}</label>
              </span>
              <span v-if="errors?.patronymic" class="color-red text-xs">
                {{ errors.patronymic[0] }}
              </span>
            </div>
          </div>
        </MainCard>
      </div>
      <div class="col-12 md:col-4">
        <MainCard :styles="{'h-full': true}" :title="t('card-names.contact-data')">
          <div class="flex flex-column gap-3">
            <div class="mb-3">
              <InputNumberPhone
                  v-model="formReactive.phone"
                  :class="{'p-invalid': v$.phone.$errors.length || errors?.phone}"
                  @toggleChangePhoneCode="toggleChangePhoneCode"
                  :phone-code="country?.phone_code ? country.phone_code : '+7'"
                  :country="country?.name"
                  isRequired
              />
              <span v-if="v$.phone.$errors.length || errors?.phone" class="color-red text-xs">
                {{ errors ? errors.phone[0] : t(v$.phone.$errors[0].$message) }}
              </span>
            </div>

            <div class="mb-3">
              <span class="p-float-label w-full">
                <InputText
                    id="email"
                    :class="['w-full', {'p-invalid': v$.email.$errors.length || errors?.email}]"
                    v-model="formReactive.email"
                />
                <label for="email">{{ t('labels.email-for-notices') }}<i style="color: #E26B6B">*</i></label>
              </span>
              <span v-if="v$.email.$errors.length || errors?.email" class="color-red text-xs">
                {{ errors ? errors.email[0] : t(v$.email.$errors[0].$message) }}
              </span>
            </div>
          </div>
        </MainCard>
      </div>
      <div class="col-12 md:col-4">
        <div class="flex flex-column gap-3">
          <MainCard :title="t('card-names.role')" isRequired>
            <MultiSelect
                v-model="formReactive.roles"
                :class="{'p-invalid': v$.roles.$errors.length || errors?.roles}"
                display="chip"
                :options="roles"
                optionLabel="name_ru"
                option-value="id"
                :placeholder="t('placeholders.roles')"
                class="w-full"
            />

            <span v-if="v$.roles.$errors.length || errors?.roles" class="color-error text-xs">
              {{ errors ? errors.roles[0] : t(v$.roles.$errors[0].$message) }}
            </span>
          </MainCard>

<!--          <MainCard :title="t('card-names.language')" isRequired>-->
<!--            <Dropdown-->
<!--                v-model="formReactive.language"-->
<!--                :class="{'p-invalid': v$.language?.$errors?.length || errors?.language}"-->
<!--                :options="languages"-->
<!--                optionLabel="label"-->
<!--                option-value="value"-->
<!--                :placeholder="t('card-names.language')"-->
<!--                class="w-full"-->
<!--            />-->

<!--            <span v-if="v$.language?.$errors?.length || errors?.language" class="color-error text-xs">-->
<!--              {{ errors ? errors.language[0] : t(v$.language.$errors[0].$message) }}-->
<!--            </span>-->
<!--          </MainCard>-->
<!--          <MainCard title="Быстрые ссылки"></MainCard>-->
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.spinner__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 500px;
}
::v-deep(.p-progress-spinner-circle) {
  stroke: var(--color-primary) !important;
}

</style>
