import ru from './ru.json';
import en from './en.json';
import { createI18n } from 'vue-i18n'

// export const defaultLanguage = 'ru';
// export const messages = {en, ru};

export default createI18n({
    legacy: false,
    locale: 'ru',
    fallbackLocale: 'en',
    warnHtmlInMessage: "off",
    messages: {
        en,
        ru,
    },
})