<script>
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {useError} from "@/hooks/useErrors";
import {usePartnerCategory} from "@/hooks/partner-category";

import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import MainCard from "@/components/cards/MainCard.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import {useI18n} from "vue-i18n";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {ConfirmationModal, InputText, Button, MainCard, Breadcrumb, ButtonSuccess},
  async beforeRouteEnter(to, from, next) {
    try {
      const {loadPartnerCategory} = usePartnerCategory();
      await loadPartnerCategory(to.params.id);
    } catch (e) {
      console.error(e);
    }

    next();
  },
  setup() {
    const {t} = useI18n();
    const store = useStore();
    const {category, form, updatePartnerCategory: update, v$, destroyPartnerCategory} = usePartnerCategory();
    const route = useRoute();
    const router = useRouter()
    const visibleModal = ref(false);
    form.value = {...category.value};
    const errors = ref(null)
    const breadcrumbs = ref([]);

    const updatePartnerCategory = async () => {
      try {
        await update(route.params.id, form.value);
        await router.push({name: 'partners-categories'});
      } catch (e) {
        errors.value = e.response.data.data.name_ru[0]
      }
    };

    const destroyPartnerCategoryHandler = async () => {
      try {
        await changeConfirmationStateModal();
        await destroyPartnerCategory(route.params.id);
        await router.push({name: 'partners-categories'});
      } catch (e) {
        errors.value = e.response.data.error
      }
    }

    const changeConfirmationStateModal = () => {
      visibleModal.value = !visibleModal.value
      store.dispatch('changeStateModal', {
        type: 'confirmationDestroyPartnerModal',
        bool: visibleModal.value
      });
    };

    watch(() => form.value, () => {
      errors.value = null
    }, {deep: true});

    onMounted( () => {
      breadcrumbs.value = [
        {label: 'Партнеры', router: {name: 'partners-categories'}},
        {label: category.value.name_ru}
      ];

      form.name_ru = category.value.name_ru;
    });

    return {t, errors, category, form, breadcrumbs, updatePartnerCategory, v$, visibleModal, changeConfirmationStateModal, destroyPartnerCategoryHandler};
  }
})
</script>

<template>
  <ConfirmationModal v-model:visible="visibleModal">
    <template #header>
      Удалить предложение
    </template>

    <template #default>
      <span>Вы уверенны, что хотите удалить категорию партнера?</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="changeConfirmationStateModal"
                class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" @click="destroyPartnerCategoryHandler" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>

  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>

      <div class="flex">

        <Button
            @click="updatePartnerCategory"
            label="Сохранить изменения"
            class="btn-primary font-light ml-2"
        />
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="grid col-12 md:col-4">
      <MainCard title="Наименование категории">
        <div class="grid">
          <div class="col-12">
            <span class="p-float-label w-full">
              <InputText
                  v-model="form.name_ru"
                  id="last_name"
                  class="w-full"
                  :class="{'p-invalid': v$.name_ru.$errors.length || errors}"
              />
              <label for="last_name">Наименование категории *</label>
            </span>

            <span v-if="v$.name_ru.$errors.length || errors" class="text-xs color-error">
              {{ errors ? errors : v$.name_ru.$errors[0].$message }}
            </span>
          </div>
        </div>
      </MainCard>
    </div>
  </section>
  <section  class="py-2 mb-4">
    <div class="flex justify-content-end">
      <span class="color-error underline cursor-pointer" @click="changeConfirmationStateModal">
        Удалить категорию
      </span>
    </div>
  </section>
</template>