<script>
import {defineComponent, ref, watch} from "vue";
import Card from "primevue/card";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import Textarea from 'primevue/textarea';
import {useVuelidate} from "@vuelidate/core";
import {required} from "@/utils/i18n-validators";
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {
    ButtonFileUpload,
    Card,
    Textarea,
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    inputTitle: {
      type: String,
      required: true,
    },
    imgTitle: {
      type: String,
      required: false,
    },
    rows: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    scope: {
      type: String,
      required: true,
    },
    validError: {
      type: Boolean,
      required: false,
    }
  },
  emits: ['deleteSlide', 'changeSlide'],
  setup(props, {emit}) {
    const {t} = useI18n()
    const form = ref({...props.slide})
    const formRules = {
      image: {required},
      description_ru: {required},
    }
    const v$ = useVuelidate(formRules, form.value, { $scope: props.scope });

    const deleteSlide = () => {
      emit('deleteSlide', props.index)
    }
    const loadFileMemory = (data) => {
      form.value.image = data.files[0]
    }

    watch(() => form.value,
        () => {
          emit('changeSlide', form.value, props.index)
        }, { deep: true }
    )
    watch(() => props.validError,
        async () => {
        if (props.validError)
          await v$.value.$validate();
        })
    return {
      form,
      v$,
      t,
      deleteSlide,
      loadFileMemory,
    };
  }
});
</script>

<template>
  <Card>
    <template #content>
      <div class="flex justify-content-between">
        <h3>{{title}} {{index + 1}}</h3>
        <i @click="deleteSlide" class="pi pi-times cansel-button cursor-pointer" style="font-size: 1rem"></i>
      </div>
      <div class="w-full mt-4">
          <span class="p-float-label w-full">
            <Textarea
               v-model="form.description_ru"
               :class="['w-full', 'p-inputtext', {'p-invalid': v$.description_ru.$errors.length || errors?.description_ru}]"
               :rows="rows"
               id="title"
            />
            <label for="title">{{inputTitle}} <i style="color: #E26B6B">*</i></label>
            </span>
          <span v-if="v$.description_ru.$errors.length || errors?.description_ru" class="text-xs color-error">
            {{ errors?.description_ru ? errors.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}
          </span>
        </div>
        <div class="flex flex-column mt-2 gap-2">
          <h3>{{ imgTitle ? imgTitle : 'Изображение' }} <i style="color: #E26B6B">*</i></h3>
          <span  v-if="v$.image.$errors.length || errors?.image" class="text-xs color-error">
              {{ errors?.image ? errors.image[0] : t(v$.image.$errors[0].$message) }}
          </span>
          <div v-if="form.image">
            <div>
              <img width="226" height="226" :src="form.image.preview_url ? form.image.preview_url : form.image.objectURL" />
            </div>
          </div>
          <div>
            <ButtonFileUpload
                @chooseFiles="loadFileMemory"
                :label="form.image ? 'Заменить фотографию' : 'Добавить фотографию'"
                :multiple="false"
                :clear-files-after-select="true"
                :classes="{'btn-fix-height brand__image': true}"
            />
          </div>
      </div>
    </template>
  </Card>
</template>

<style scoped>
.p-card {
  border-radius: 8px;
  box-shadow: none;
}

.p-card-title {
  font-size: 3rem !important;
}
.card__wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #DBD9D9;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}
</style>
