<script>
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {useFlat} from "@/hooks/flat";
import {useVendor} from "@/hooks/vendor";
import {useCountries} from "@/hooks/countries";
import Skeleton from "primevue/skeleton"
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import VendorJuridicalPersonForm from "@/components/forms/VendorJuridicalPersonForm";
import VendorIndividualPersonForm from "@/components/forms/VendorIndividualPersonForm.vue";
import SelectPhoneModal from "@/components/modals/SelectPhoneModal";
import Breadcrumb from "@/components/Breadcrumb";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import MainCard from "@/components/cards/MainCard.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import InputNumberPhone from "@/components/inputs/InputNumberPhone.vue";
import {useI18n} from "vue-i18n";


export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    InputNumberPhone,
    ButtonSuccess,
    MainCard,
    InputText,
    VendorJuridicalPersonForm,
    VendorIndividualPersonForm,
    SelectPhoneModal,
    ButtonFileUpload,
    Breadcrumb,
    Button,
    Skeleton,
  },
  setup() {
    const {t} = useI18n()
    const store = useStore();
    const route = useRoute();
    const {vendor, form, v$, vip$, loadVendor} = useVendor();
    const {countries, selectCountry, loadCountries} = useCountries();
    const formVendorTypes = {
      'ul': 'VendorJuridicalPersonForm',
      'ip': 'VendorIndividualPersonForm'
    };
    const validators = ref({
      'ul' : v$,
      'ip': vip$,
    });
    const errors = ref(null)
    const validator = computed(() => validators.value[vendor.value.type]);
    const isLoading = ref(true)
    const breadcrumbs = ref([]);
    const visible = ref(false);
    const isUpdated = ref(false);

    const changeVisible = () => {
      visible.value = !visible.value;
    };

    const fileUpload = async ({ files }) => {
      try {
        const form = new FormData();
        form.append('payment_details', files[0]);

        await store.dispatch('fetchUploadVendorPayment', {
          id: vendor.value.id,
          body: form
        });

        await store.dispatch('fetchVendor', route.params.id);
      } catch (e) {
        console.error(e);
      }
    };

    const fileDestroy = async (id) => {
      try {
        await store.dispatch('fetchDestroyVendorPayment', id);
        await store.dispatch('fetchVendor', route.params.id);
      } catch (e) {
        console.error(e);
      }
    };

    const updateVendor = async () => {
      try {
        const result = await validator.value.$validate();
        if (!result) {
          return;
        }
        const flat = useFlat(form.value);
        const flatData = new FormData();
        for (let key in flat) {
          if (key === 'payment_details')
            continue;

          flatData.append(key, flat[key] === null ? '' : flat[key])
        }
        flatData.append('discount', 0)

        await store.dispatch('fetchUpdateVendor', {
          id: route.params.id,
          form: flatData,
        });

        isUpdated.value = true;
      } catch (e) {
        errors.value = e.response?.data.data
        console.error(e);
      }
    };

    onBeforeMount(async () => {
      try {
        await loadVendor(route.params.id);
        await loadCountries();
        const label = vendor.value.type === 'ul' ?
            vendor.value.data.full_name :
            vendor.value.data.org_name;
        form.value = useFlat(vendor.value);

        breadcrumbs.value = [
          {label: 'Обслуживающие компании', router: {name: 'vendors-list'}},
          {label: label}
        ];
        isLoading.value = false
      } catch (e) {
        console.error(e);
      }
    });

    return {
      isUpdated,
      vendor,
      breadcrumbs,
      visible,
      changeVisible,
      countries,
      selectCountry,
      updateVendor,
      fileUpload,
      fileDestroy,
      formVendorTypes,
      form,
      v$,
      vip$,
      validator,
      errors,
      t,
      isLoading,
    };
  }
});
</script>

<template>
  <div class="vendor">
    <SelectPhoneModal :countries="countries" v-model:modelValue="visible" @toggleCloseModal="changeVisible"/>

    <section class="py-2 mb-3">
      <div class="flex justify-content-between">
        <Breadcrumb :data="breadcrumbs" separator="/"/>

        <ButtonSuccess v-if="isUpdated" label="Измения сохранены" />
        <Button v-if="!isUpdated" @click="updateVendor" label="Сохранить реквизиты" class="btn-primary font-light"/>
      </div>
    </section>
    <Skeleton v-if="isLoading" height="353px" class="my-4"></Skeleton>
    <Skeleton v-if="isLoading" height="268px" class="mb-4"></Skeleton>
    <Skeleton v-if="isLoading" height="69px" class="mb-4"></Skeleton>
    <template v-else-if="vendor?.type === 'ul' && !isLoading">
      <VendorJuridicalPersonForm
          :errors="v$"
          :form="form"
          :responseError="errors"
      />
    </template>
    <template v-else-if="vendor?.type === 'ip' && !isLoading">
      <VendorIndividualPersonForm
          :errors="vip$"
          :form="form"
          :responseError="errors"
      />
    </template>

    <section class="py-2 mb-3">
      <div class="grid mb-2">
        <div class="col-12 md:col-7">
          <Skeleton v-if="isLoading" height="207px"></Skeleton>
          <MainCard v-else title="Банковские реквизиты">
            <div class="grid">
              <div class="col-12 md:col-6">
                <div class="grid gap-4 m-0 flex-column">
                  <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="form.account"
                        :class="{'p-invalid': errors?.account}"
                        id="checking_account"
                        class="w-full"
                    />
                    <label for="checking_account">Расчетный счет *</label>
                  </span>
                    <span v-if="errors?.account" class="text-xs color-error">
                    {{ errors.account[0] }}
                  </span>
                  </div>
                  <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="form.bic"
                        :class="{'p-invalid': errors?.bic}"
                        id="bik"
                        class="w-full"
                    />
                    <label for="bik">БИК *</label>
                  </span>
                    <span v-if="errors?.bic" class="text-xs color-error">
                    {{ errors.bic[0] }}
                  </span>
                  </div>
                </div>
              </div>
              <div class="col-12 md:col-6">
                <div class="grid gap-4 m-0 flex-column">
                <span class="p-float-label w-full">
                    <InputText
                        v-model="form.bank"
                        :class="{'p-invalid': errors?.bank}"
                        id="bik"
                        class="w-full"
                    />
                    <label for="bik">Наименования банка *</label>
                </span>
                  <span v-if="errors?.bank" class="text-xs color-error">
                  {{ errors.bank[0] }}
                </span>
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="form.corr_account"
                        :class="{'p-invalid': errors?.corr_account}"
                        id="correspondent_account"
                        class="w-full"
                    />
                    <label for="correspondent_account">Корр. счет *</label>
                </span>
                  <span v-if="errors?.corr_account" class="text-xs color-error">
                  {{ errors.corr_account[0] }}
                </span>
                </div>
              </div>
            </div>
          </MainCard>
        </div>

        <div class="col-12 md:col-5">
          <Skeleton v-if="isLoading" height="207px"></Skeleton>
          <MainCard v-else title="Контактные данные">
            <div class="grid">
              <div class="col-12">
                <div class="grid gap-4 m-0 flex-column">
                  <div>
                    <InputNumberPhone
                        v-model="form.phone"
                        :country="selectCountry?.name"
                        :classes="{'p-invalid': validator?.phone.$errors.length || errors?.phone?.length}"
                        :phone-code="selectCountry?.phone_code ? selectCountry.phone_code : '+7'"
                        @toggleChangePhoneCode="changeVisible"
                        isRequired
                    />
                    <span v-if="validator?.phone.$errors.length || errors?.phone?.length" class="text-xs color-error">
                    {{ errors ? errors.phone[0] : t(validator.phone.$errors[0].$message) }}
                  </span>
                  </div>
                  <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="form.email"
                        :class="{'p-invalid': validator.email.$errors.length || errors?.email?.length}"
                        id="email"
                        class="w-full"
                    />
                    <label for="email">Электронная почта (для уведомлений бенефициару) *</label>
                  </span>
                    <span v-if="validator.email.$errors.length || errors?.email?.length" class="text-xs color-error">
                    {{ errors ? errors.email[0] : t(validator.email.$errors[0].$message) }}
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </MainCard>
        </div>
      </div>
    </section>

    <!--  <section class="py-2 mb-3">-->
    <!--    <MainCard title="Персональная скидка">-->
    <!--      <div class="grid">-->
    <!--        <div class="col-12 md:col-4">-->
    <!--          <span class="p-float-label w-full">-->
    <!--            <InputText-->
    <!--                v-model="form.discount"-->
    <!--                :class="{'p-invalid': validator.discount.$errors.length}"-->
    <!--                id="discount"-->
    <!--                class="w-full"-->
    <!--            />-->
    <!--            <label for="discount">Скидка  *</label>-->
    <!--          </span>-->
    <!--          <span v-if="validator.discount.$errors.length" class="text-xs color-error">-->
    <!--            {{ validator.discount.$errors[0].$message }}-->
    <!--          </span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </MainCard>-->
    <!--  </section>-->

    <section class="py-2 mb-3">
      <MainCard title="Банковские реквизиты в формате PDF">
        <div class="grid">
          <div class="col-12">
            <div class="grid flex-column" v-if="vendor.payment_details">
              <div class="col-12 md:col-3 sm:col-6">
                <div class="file-vendor-card px-2 py-3 relative flex align-items-center">
                  <div class="flex">
                    <i class="pi pi-file-edit color-primary" style="font-size: 1rem"></i>
                    <span class="ml-5 text-xs">{{ vendor.payment_details.split('/')[vendor.payment_details.split('/').length - 1]}}</span>
                  </div>
                  <i
                      @click="fileDestroy(vendor.id)"
                      class="pi pi-times color-black-20 text-white cansel-button cursor-pointer ml-auto"
                      style="font-size: 1rem"
                  ></i>
                </div>
              </div>
              <div class="col-12">
                <ButtonFileUpload
                    buttonText
                    @chooseFiles="fileUpload"
                    :clearFilesAfterSelect="true"
                    :multiple="false"
                    accept=".pdf,.doc,.docx,.xls"
                    label="Изменить"
                />
              </div>
            </div>
            <ButtonFileUpload
                v-else
                @chooseFiles="fileUpload"
                :clearFilesAfterSelect="true"
                :multiple="false"
                accept="application/pdf"
                label="Добавить реквизиты PDF"
            />
          </div>
        </div>
      </MainCard>
    </section>
  </div>

</template>

<style lang="scss" scoped>
.vendor {
  ::v-deep(.p-float-label) {
    label {
      font-size: 11px;
      margin-top: -0.4rem;
    }
    span {
      font-size: 11px;
    }
    .icon-number {
      span {
        margin-left: 5px;
      }
    }
  }
  ::v-deep(.p-card-title) {
    label {
      font-size: 13px;
    }
  }
  ::v-deep(.p-inputtext) {
    font-size: 11px;
  }
  .file-vendor-card {
    height: 48px;
    border-radius: 4px;
    border: 1px solid var(--color-black-20);
  }
  ::v-deep(.calendar-icon) {
    position: absolute;
    top: 7px;
    right: 10px;
    pointer-events: none;
    cursor: pointer;
  }
}
</style>