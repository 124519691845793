<script>
import {
  defineComponent, onBeforeMount,
  ref, watch,
} from "vue";
import {useMeta} from "vue-meta";
import Editor from "primevue/editor";
import {useI18n} from "vue-i18n";
import Skeleton from "primevue/skeleton"
import Button from "primevue/button";
import { useApiCall } from "@/composables/useApiCall";
import { checkInInfoApiCall, oneTimeInfoApiCall, updateCheckInInfoApiCall, updateOneTimeInfoApiCall } from "@/services/blocks"
import MainCard from "@/components/cards/MainCard.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
export default defineComponent({
  layout: { name: 'AdminLayout' },
  components: {
    ButtonSuccess,
    MainCard,
    Editor,
    Button,
    Skeleton,
  },
  setup() {
    const {t} = useI18n()
    useMeta({
      title: t('menu.blocks'),
    });
    const checkInFields = ref('')
    const oneTimeFields = ref('')
    const isUpdatedCheckInInfo = ref(false)
    const isUpdatedOneTime = ref(false)

    const {
      isLoading: isCheckInInfoLoading,
      data: checkInInfoData,
      error: checkInInfoError,
      executeApiCall: getCheckInInfoAction,
    } = useApiCall(checkInInfoApiCall, true)
    const {
      isLoading: isOneTimeInfoLoading,
      data: oneTimeInfoData,
      error: oneTimeInfoError,
      executeApiCall: getOneTimeInfoAction,
    } = useApiCall(oneTimeInfoApiCall, true)

    const {
      isLoading: isCheckInUpdateLoading,
      data: checkInUpdateData,
      error: checkInUpdateError,
      executeApiCall: checkInUpdateAction,
    } = useApiCall(updateCheckInInfoApiCall, true)

    const {
      isLoading: isOneTimeUpdateLoading,
      data: oneTimeUpdateData,
      error: oneTimeUpdateError,
      executeApiCall: oneTimeUpdateAction,
    } = useApiCall(updateOneTimeInfoApiCall, true)

    const updateCheckInInfo = async () => {
      try {
        await checkInUpdateAction({text_ru: checkInFields.value})
        isUpdatedCheckInInfo.value = true
      } catch (e) {
        console.error(e)
      }
    }

    const updateOneTimeInfo = async () => {
      try {
        await oneTimeUpdateAction({text_ru: oneTimeFields.value})
        isUpdatedOneTime.value = true
      } catch (e) {
        console.error(e)
      }
    }

    watch(() => checkInFields.value,
        () => {
          isUpdatedCheckInInfo.value = false
        })

    watch(() => oneTimeFields.value,
        () => {
          isUpdatedOneTime.value = false
        })

    onBeforeMount(async () => {
      try {
        await getCheckInInfoAction()
        if (checkInInfoData.value) {
          checkInFields.value = checkInInfoData.value.data.text_ru
        }
      } catch (e) {
        console.error(e)
      }
      try {
        await getOneTimeInfoAction()
        if (oneTimeInfoData.value) {
          oneTimeFields.value = oneTimeInfoData.value.data.text_ru
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      checkInFields,
      oneTimeFields,
      isCheckInInfoLoading,
      isOneTimeInfoLoading,
      checkInInfoError,
      oneTimeInfoError,
      isUpdatedCheckInInfo,
      isUpdatedOneTime,
      updateCheckInInfo,
      updateOneTimeInfo,
      isCheckInUpdateLoading,
      isOneTimeUpdateLoading,
      checkInUpdateError,
      oneTimeUpdateError,
    };
  },
})
</script>

<template>
  <div class="relative">
    <section class="py-2">
      <div class="flex justify-content-between">
        <h1 class="website__title" style="min-height: 46px">{{ t('menu.blocks') }}</h1>
      </div>
    </section>
    <section v-if="!isCheckInInfoLoading">
      <div class="flex justify-content-between align-items-center mb-4">
        <h2>{{ t('blocks.checkIn') }}</h2>
        <ButtonSuccess v-if="isUpdatedCheckInInfo" :label="t('button.changesSaved')" class="btn-fix-height"/>
        <Button v-else @click="updateCheckInInfo" :loading="isCheckInUpdateLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
      </div>
      <MainCard :title="t('websiteCard.infrastructure.input')">
        <Editor v-model="checkInFields"></Editor>
        <span v-if="checkInUpdateError?.data.text_ru" class="flex text-xs color-error">
        {{ checkInUpdateError?.data.text_ru[0] }}
      </span>
      </MainCard>
    </section>
    <Skeleton v-else height="256px"></Skeleton>

    <section v-if="!isOneTimeInfoLoading" class="mt-6">
      <div class="flex justify-content-between align-items-center mb-4">
        <h2>{{ t('blocks.oneTime') }}</h2>
        <ButtonSuccess v-if="isUpdatedOneTime" :label="t('button.changesSaved')" class="btn-fix-height"/>
        <Button v-else @click="updateOneTimeInfo" :loading="isOneTimeUpdateLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
      </div>
      <MainCard :title="t('websiteCard.infrastructure.input')">
        <Editor v-model="oneTimeFields"></Editor>
        <span v-if="oneTimeUpdateError?.data.text_ru" class="flex text-xs color-error">
        {{ oneTimeUpdateError?.data.text_ru[0] }}
      </span>
      </MainCard>
    </section>
    <Skeleton v-else height="256px"></Skeleton>
  </div>
</template>
<style lang="scss" scoped>
</style>