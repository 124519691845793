<script>
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import ImageCard from "@/components/cards/ImageCard.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import Button from "primevue/button";
import Textarea from 'primevue/textarea';
import {useApiCall} from "@/composables/useApiCall";
import {
  founderInfoApiCall,
  updateFounderInfoApiCall,
  addFounderApiCall,
  updateFounderApiCall,
  deleteFounderApiCall,
} from "@/services/website";
import {helpers} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import {required} from "@/utils/i18n-validators";
import Skeleton from "primevue/skeleton"

export default defineComponent({
  components: {
    ConfirmationModal,
    ButtonSuccess,
    ButtonFileUpload,
    ImageCard,
    InputText,
    Editor,
    MainCard,
    Button,
    Textarea,
    Skeleton,
  },
  setup() {
    const {t} = useI18n()
    const deleteItem = ref(null)
    const visible = ref(false)
    const form = ref({
      text_ru: '',
      founders: []
    });
    const formRules = {
      text_ru: { required },
      founders: {
        $each: helpers.forEach({
          name_ru: {
            required
          },
          photo: {
            required
          },
          text_ru: {
            required
          }
        })
      }
    }
    const v$ = useVuelidate(formRules, form.value);

    const isUpdated = ref(false)
    const addError = ref(null)

    const isLoading = computed(() =>
        isContentUpdateLoading.value ||
        isAddFounderLoading.value ||
        isUpdateFounderLoading.value
    )

    const isError = computed(() =>
        contentUpdateData.value ||
        addFounderError.value ||
        updateFounderError.value
    )

    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(founderInfoApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateFounderInfoApiCall, true)

    const {
      isLoading: isAddFounderLoading,
      data: addFounderData,
      error: addFounderError,
      executeApiCall: addFounderAction,
    } = useApiCall(addFounderApiCall, true)

    const {
      isLoading: isUpdateFounderLoading,
      data: updateFounderData,
      error: updateFounderError,
      executeApiCall: updateFounderAction,
    } = useApiCall(updateFounderApiCall, true)

    const {
      isLoading: isDeleteFounderLoading,
      data: deleteFounderData,
      error: deleteFounderError,
      executeApiCall: deleteFounderAction,
    } = useApiCall(deleteFounderApiCall, true)

    const loadBrandPersonFileMemory = (data, index) => {
      form.value.founders[index].photo = data.files[0]
    }
    const updateForm = async () => {
      addError.value = null
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      try {
        await updateContentAction({text_ru: form.value.text_ru})
        for (let i = 0; i < form.value.founders.length; i += 1) {
          if (!form.value.founders[i].id.toString().includes('newItem')) {
            const oldItem = contentData.value.data.founders.findIndex(item => item.id === form.value.founders[i].id)
            if (
                contentData.value.data.founders[oldItem] &&
                (contentData.value.data.founders[oldItem].photo !== form.value.founders[i].photo
                  || contentData.value.data.founders[oldItem].text_ru !== form.value.founders[i].text_ru
                  || contentData.value.data.founders[oldItem].name_ru !== form.value.founders[i].name_ru)
            ) {
              const formData = new FormData()
              if (form.value.founders[i].photo.objectURL) {
                formData.append('photo', form.value.founders[i].photo)
              }
                formData.append('name_ru', form.value.founders[i].name_ru)
                formData.append('text_ru', form.value.founders[i].text_ru)
              try {
                await updateFounderAction({id: form.value.founders[i].id, fields: formData})
                if (updateFounderData.value) {
                  contentData.value.data.founders[oldItem] = {...updateFounderData.value.data}
                  form.value.founders[i] = {...updateFounderData.value.data}
                }
              } catch (e) {
                addError.value = {item: form.value.founders[i], error: updateFounderError.value.data}
                console.error(e)
                return
              }
            }
          } else {
            const formData = new FormData()
            formData.append('name_ru', form.value.founders[i].name_ru)
            formData.append('text_ru', form.value.founders[i].text_ru)
            formData.append('photo', form.value.founders[i].photo)
            try {
              await addFounderAction(formData)
              if (addFounderData.value) {
                contentData.value.data.founders.push(addFounderData.value.data)
                form.value.founders[i] = {...addFounderData.value.data}
              }
            } catch (e) {
              addError.value = {item: form.value.founders[i], error: addFounderError.value.data}
              console.error(e)
              return
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
      if (!isError.value) {
        isUpdated.value = true
      }
    }

    const changeVisibleModal = (index = null) => {
      visible.value = !visible.value
      if (index || index === 0) {
        deleteItem.value = index
      }
    };

    const deleteSlide = async () => {
      if (!form.value.founders[deleteItem.value].id.toString().includes('newItem')) {
        try {
          await deleteFounderAction({id: form.value.founders[deleteItem.value].id })
        } catch (e) {
          console.error(e)
        }
      }
      if (deleteFounderData.value || form.value.founders[deleteItem.value].id.toString().includes('newItem')) {
        form.value.founders.splice(deleteItem.value, 1);
        deleteItem.value = null
        changeVisibleModal()
      }
    }

    const addFounder = () => {
      form.value.founders.push({
        id: `newItem${form.value.founders.length + 1}`,
        photo: null,
        text_ru: '',
        name_ru: '',
      })
    }

    watch(() => form.value,
        () => {
          isUpdated.value = false
        }, {deep: true})

    onMounted(async () => {
      try {
        await getContentAction()
        if (contentData.value) {
          form.value.text_ru = contentData.value.data.text_ru
          contentData.value.data.founders.forEach(item => form.value.founders.push({
            id: item.id,
            photo: item.photo,
            text_ru: item.text_ru,
            name_ru: item.name_ru,
          }))
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      v$,
      form,
      isUpdated,
      loadBrandPersonFileMemory,
      updateForm,
      contentUpdateError,
      changeVisibleModal,
      visible,
      isLoading,
      isDeleteFounderLoading,
      deleteSlide,
      addFounder,
      addError,
      isContentLoading,
    };
  }
})
</script>

<template>
  <ConfirmationModal :visible="visible">
    <template #header>
      Удалить основателя
    </template>

    <template #default>
      <span>Вы уверены,  что хотите удалить основателя? Это действие будет невозможно отменить</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="changeVisibleModal" class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" :loading="isDeleteFounderLoading" @click="deleteSlide" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>
  <div class="mt-7">
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>{{ t('website.founders.title')}}</h1>
      <div class="flex gap-2">
        <button
            v-if="form.founders.length < 2"
            @click="addFounder"
            class="p-button btn-fix-height btn-black-20-outlined website__link"
        >{{ t('founders.add') }}</button>
        <ButtonSuccess v-if="isUpdated" label="Изменения сохранены" class="btn-fix-height"/>
        <Button v-else @click="updateForm" :loading="isLoading" label="Сохранить изменение" class="btn-primary font-light btn-fix-height" />
      </div>
    </div>
    <Skeleton v-if="isContentLoading" height="205px"></Skeleton>
    <MainCard v-else title="Описание блока" class="mb-3">
      <Editor v-model="form.text_ru" class="w-full"></Editor>
      <span v-if="v$.text_ru.$errors.length || contentUpdateError?.data.text_ru.length" class="text-xs color-error">
        {{ contentUpdateError ? contentUpdateError.data.text_ru[0] : t(v$.text_ru.$errors[0].$message) }}
      </span>
    </MainCard>
    <div v-if="!isContentLoading" class="brand__persons">
      <MainCard
          v-for="(person, i) in form.founders"
          :key="person.id"
          :title="t('founders.title', i + 1)"
          withDelete
          @deleteItem="changeVisibleModal"
          :index="i"
          class="mb-3"
      >
        <div class="mb-5 mt-2">
          <div class="w-full">
            <span class="p-float-label w-full">
              <InputText
                  v-model="person.name_ru"
                  :class="['w-full', {'p-invalid': v$.founders?.$errors[0]?.$response.$errors[i].name_ru?.length || (addError?.item.id === person.id && addError?.error.name_ru?.length) }]"
                  id="person-name"
              />
              <label for="person-name">{{ t('founders.placeholder') }} <i style="color: #E26B6B">*</i></label>
            </span>
            <span v-if="v$.founders?.$errors[0]?.$response.$errors[i].name_ru?.length || (addError?.item.id === person.id && addError?.error.name_ru?.length)" class="text-xs color-error">
              {{ addError?.item.id === person.id && addError?.error.name_ru?.length ? addError.error.name_ru[0] : t(v$.founders?.$errors[0]?.$response.$errors[i].name_ru[0].$message) }}
            </span>
          </div>
        </div>
        <div class="w-full">
          <span class="p-float-label w-full">
            <Textarea
                v-model="person.text_ru"
                :class="['w-full', 'p-inputtext', {'p-invalid': v$.founders?.$errors[0]?.$response.$errors[i].text_ru?.length || (addError?.item.id === person.id && addError?.error.text_ru?.length) }]"
                rows="7"
                cols="30"
                :id="i.toString()"
            />
            <label :for="i">Фраза <i style="color: #E26B6B">*</i></label>
          </span>
          <span v-if="v$.founders?.$errors[0]?.$response.$errors[i].text_ru?.length || (addError?.item.id === person.id && addError?.error.text_ru?.length)" class="text-xs color-error">
              {{ addError?.item.id === person.id && addError?.error.text_ru?.length ? addError.error.text_ru[0] : t(v$.founders?.$errors[0]?.$response.$errors[i].text_ru[0].$message) }}
          </span>
        </div>
        <div class="flex flex-column mt-2 gap-2">
          <h1>Фотография основателя <i style="color: #E26B6B">*</i></h1>
          <span v-if="v$.founders?.$errors[0]?.$response.$errors[i].photo?.length || (addError?.item.id === person.id && addError?.error.photo?.length)" class="text-xs color-error">
              {{ addError?.item.id === person.id && addError?.error.photo?.length ? addError.error.photo[0] : t(v$.founders?.$errors[0]?.$response.$errors[i].photo[0].$message) }}
            </span>
          <div v-if="person.photo" >
            <img class="brand__persons-image" :src="person.photo.objectURL ? person.photo.objectURL : person.photo.preview_url" />
          </div>
          <div>
            <ButtonFileUpload
                @chooseFiles="loadBrandPersonFileMemory"
                :label="person.photo ? 'Заменить фотографию' : 'Добавить фотографию'"
                :multiple="false"
                :index="i"
                :clear-files-after-select="true"
                :classes="{'brand__image': true}"
            />
          </div>
        </div>
      </MainCard>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.brand {
  &__image {
    width: 208px;
  }
  &__persons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    @media screen and (min-width: 1200px) {
      column-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
    &-image {
      width: 205px;
      height: 205px;
      border-radius: 100px;
    }
  }
}
</style>