<script>
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Paginator from "primevue/paginator";
import MainCard from "@/components/cards/MainCard.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import BuildingCategoriesTable from "@/components/tables/BuildingCategoriesTable.vue";
import {useI18n} from "vue-i18n";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {InputText, MainCard, Breadcrumb, Button, ButtonSuccess, BuildingCategoriesTable, Paginator, Checkbox},
  async beforeRouteEnter(to, from, next) {
    try {
      const store = useStore();
      await store.dispatch('fetchBuilding', to.params.id);
      await store.dispatch('fetchBuildingSegment', to.params.segment);
      await store.dispatch('fetchRoomCategories', {
        segmentId: store.getters.getCurrentBuildingSegment.id,
        params: {page: 1}
      });
      next();
    } catch (e) {
      console.error(e);
    }
  },
  setup() {
    const { t } = useI18n()
    const store = useStore();
    const route = useRoute();

    const form = ref({
      name_ru: '',
      title: '',
      for_long_term: false,
      for_short_term: false,
      is_special: false
    });

    const first = ref(0);

    const isUpdated = ref(false);
    const breadcrumbs = ref([]);
    const building = computed(() => store.getters.getCurrentBuilding);
    const segment = computed(() => store.getters.getCurrentBuildingSegment);
    const categories = computed(() => store.getters.getRoomCategoriesList);

    const updateSegment = async () => {
      await store.dispatch('fetchUpdateSegment', {
        id: route.params.segment,
        body: {
          ...form.value,
          for_long_term: Number(form.value.for_long_term),
          for_short_term: Number(form.value.for_short_term),
          is_special: Number(form.value.is_special),
        },
      });

      isUpdated.value = true;
    };

    onMounted(() => {
      breadcrumbs.value = [
        {label: 'Структура', router: {name: 'buildings'}},
        {label: building.value.name_ru, router: {name: 'building-edit', params: {id: building.id}}},
        {label: segment.value.name_ru}
      ];

      form.value = {
        name_ru: segment.value.name_ru,
        title: segment.value.title,
        for_long_term: segment.value.for_long_term,
        for_short_term: segment.value.for_short_term,
        is_special: segment.value.is_special,
      }
    });

    watch(() => first.value, async () => {
      await store.dispatch('fetchRoomCategories', {
        segmentId: store.getters.getCurrentBuildingSegment.id,
        params: {page: ((first.value / (categories.value?.data?.pagination?.per_page ?? 1)) + 1)}
      });
    });

    watch(() => form.value, () => isUpdated.value = false, {deep: true});

    return {t, form, breadcrumbs, segment, building, updateSegment, isUpdated, categories, first};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>
      <div class="flex">
        <ButtonSuccess v-if="isUpdated" label="Изменения сохранены"/>
        <Button v-if="!isUpdated" @click="updateSegment" label="Сохранить изменения" class="btn-primary font-light ml-2"/>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12 md:col-4">
        <MainCard title="Название сегмента">
          <InputText disabled placeholder="Название *" v-model="form.title"></InputText>
        </MainCard>
      </div>
      <div class="col-12 md:col-4">
        <MainCard :title="t('segment.title')">
          <InputText placeholder="Название *" v-model="form.name_ru"></InputText>
        </MainCard>
      </div>
      <div class="col-12 md:col-4">
        <MainCard :title="t('segment.availability.title')">
          <div class="flex align-items-center mb-2">
            <Checkbox
                v-model="form.for_short_term"
                binary
                name="for_short_term"
            />
            <label class="ml-2" for="for_short_term">{{ t('segment.availability.forShortTerm') }}</label>
          </div>
          <div class="flex align-items-center mb-2">
            <Checkbox
                v-model="form.for_long_term"
                binary
                name="for_long_term"
            />
            <label class="ml-2" for="for_long_term">{{ t('segment.availability.forLongTerm') }}</label>
          </div>
          <div class="flex align-items-center mb-2">
            <Checkbox
                v-model="form.is_special"
                binary
                name="is_special"
            />
            <label class="ml-2" for="is_special">{{ t('segment.availability.isSpecial') }}</label>
          </div>
        </MainCard>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12">
        <BuildingCategoriesTable
            :categories="categories?.data?.data ?? []"
            :builder-id="building.id"
            :segment-id="segment.id"
        />

        <Paginator
            v-model:first="first"
            :rows="categories?.data?.pagination?.per_page ?? 0"
            :totalRecords="categories?.data?.pagination?.total ?? 0"
            class="justify-content-start"
        ></Paginator>
      </div>
    </div>
  </section>
</template>

<style scoped>
input {
  width: 100%;
}
</style>