<script>
import {defineComponent} from "vue";

import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default defineComponent({
  components: {DataTable, Column},
  props: {
    objects: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    }
  },
  setup() {

  }
});
</script>

<template>
  <DataTable :value="objects" :loading="isLoading" showGridlines tableStyle="min-width: 50rem" selectionMode="single">
    <Column field="id" header="ID"></Column>
    <Column field="number" header="Номер"></Column>
    <Column field="pib" header="ПИБ"></Column>
    <Column field="area" header="Площадь"></Column>
    <Column field="building" header="Здание">
      <template #body="slotProps">
        {{ slotProps.data.building.name_ru }}
      </template>
    </Column>
<!--    <Column field="status" header="Статус"></Column>-->
<!--    <Column field="tariff" header="Тариф, Р"></Column>-->
<!--    <Column field="bron" header="Бронь"></Column>-->
  </DataTable>
</template>

<style scoped>

</style>