<script>
import {computed, defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton"
import {agentInfoApiCall, updateAgentInfoApiCall, awardApiCall, updateAwardApiCall} from "@/services/website";
import { useApiCall } from '@/composables/useApiCall'
import {useI18n} from "vue-i18n";
export default defineComponent({
  components: {
    ButtonSuccess,
    Editor,
    MainCard,
    Button,
    Skeleton,
  },
  props: {
    type: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const {t} = useI18n()
    const isAgentInfo = computed(() => props.type === 'agentInfo')
    const form = reactive({
      description_ru: ''
    });
    const isUpdated = ref(false)
    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(isAgentInfo.value ? agentInfoApiCall : awardApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(isAgentInfo.value ? updateAgentInfoApiCall: updateAwardApiCall, true)

    const updateForm = async () => {
      try {
        await updateContentAction(form)
        if (contentUpdateData.value) {
          isUpdated.value = true
        }
      } catch (e) {
        console.error(e)
      }
    }

    watch(() => form.description_ru,
        () => {
          isUpdated.value = false
        })

    onBeforeMount( async () => {
      try {
        await getContentAction()
        if (contentData.value?.data) {
          form.description_ru = contentData.value.data.description_ru
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      form,
      isUpdated,
      updateForm,
      contentUpdateError,
      isContentLoading,
      contentError,
      isContentUpdateLoading,
      isAgentInfo,
    };
  }
})
</script>

<template>
  <div class="mt-7">
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>{{ isAgentInfo ? t('website.agent.title') : t('website.award.title')}}</h1>
      <ButtonSuccess v-if="isUpdated" :label="t('button.changesSaved')" class="btn-fix-height"/>
      <Button v-else @click="updateForm" :loading="isContentUpdateLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
    </div>
    <Skeleton v-if="isContentLoading" width="100%" height="100px"></Skeleton>
    <MainCard v-else :title="t('description')" class="mb-3">
      <Editor v-model="form.description_ru" class="w-full"></Editor>
    </MainCard>
  </div>
</template>
