<script>
import {defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import ImageCard from "@/components/cards/ImageCard.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import Skeleton from "primevue/skeleton"
import Button from "primevue/button";
import {useI18n} from "vue-i18n";
import {required} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";
import {useApiCall} from "@/composables/useApiCall";
import {conceptApiCall, updateConceptApiCall} from "@/services/website";

export default defineComponent({
  components: {
    ButtonSuccess,
    ButtonFileUpload,
    ImageCard,
    Skeleton,
    Editor,
    MainCard,
    Button,
  },
  setup() {
    const { t } = useI18n()
    const form = reactive({
      fields: {
        text_ru: '',
        photo: null,
      }
    });
    const formRules = {
      photo: {},
      text_ru: {required}
    }
    const v$ = useVuelidate(formRules, form.fields);
    const isUpdated = ref(false)

    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(conceptApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateConceptApiCall, true)

    const loadFileMemory = (data) => {
      form.fields.photo = data.files[0]
    }
    const updateForm = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      try {
        const formData = new FormData()
        formData.append('text_ru', form.fields.text_ru)
        if (form.fields.photo?.objectURL) {
          formData.append('photo', form.fields.photo)
        }
        await updateContentAction(formData)
        if (contentUpdateData.value) {
          if (contentUpdateData.value.data.photo) {
            await updateFormFields(contentUpdateData.value.data)
          }
          isUpdated.value = true
        }
      } catch (e) {
        console.error(e)
      }
    }

    const updateFormFields = (data) => {
      form.fields.text_ru = data.text_ru
      form.fields.photo = data.photo
    }

    watch(() => form.fields,
        ()=> {
          isUpdated.value = false
        }, {deep: true})

    onBeforeMount( async () => {
      try {
        await getContentAction()
        if (contentData.value?.data) {
          await updateFormFields(contentData.value.data)
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      v$,
      form,
      isUpdated,
      loadFileMemory,
      updateForm,
      isContentUpdateLoading,
      isContentLoading,
      contentUpdateError,
    };
  }
})
</script>

<template>
  <div class="mt-7">
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>{{ t('website.concept.title') }}</h1>
      <ButtonSuccess v-if="isUpdated" :label="t('button.changesSaved')" class="btn-fix-height"/>
      <Button
          v-else
          @click="updateForm"
          :loading="isContentUpdateLoading"
          :label="t('button.saveChange')"
          class="btn-primary font-light btn-fix-height"
      />
    </div>
    <Skeleton v-if="isContentLoading" width="100%" height="153px" class="mb-3"></Skeleton>
    <MainCard v-else :title="t('description')" class="mb-3">
      <Editor v-model="form.fields.text_ru" class="w-full"></Editor>
      <span v-if="v$.text_ru.$errors.length || contentUpdateError?.data.text_ru" class="text-xs color-error">
         {{ contentUpdateError ? contentUpdateError.data.text_ru[0] : t(v$.text_ru.$errors[0].$message) }}
      </span>
    </MainCard>
    <Skeleton v-if="isContentLoading" width="100%" height="155px"></Skeleton>
    <MainCard v-else :title="t('image.title')" >
      <div class="grid">
        <template v-if="form.fields.photo">
          <div class="col-12 md:col-2">
            <ImageCard v-if="form.fields.photo.preview_url" :src="form.fields.photo.preview_url" withoutDelete/>
            <ImageCard v-else :src="form.fields.photo.objectURL" withoutDelete/>
            <span v-if="contentUpdateError?.data.photo" class="text-xs color-error">
              {{ contentUpdateError.data.photo[0] }}
            </span>
          </div>
        </template>
        <div class="col-12">
          <ButtonFileUpload
              @chooseFiles="loadFileMemory"
              :label="form.fields.photo ? 'Изменить изображение' : 'Добавить изображение'"
              :multiple="false"
              :clear-files-after-select="true"
          />
        </div>
      </div>
    </MainCard>
  </div>

</template>
