<script>
import {defineComponent} from "vue";
import BrandBanner from "@/components/website/BrandBanner.vue";
import BrandValues from "@/components/website/BrandValues.vue";
import BrandAbout from "@/components/website/BrandAbout.vue";
import BrandApartHotels from "@/components/website/BrandApartHotels.vue";
import BrandFounders from "@/components/website/BrandFounders.vue";

export default defineComponent({
  components: {
    BrandFounders,
    BrandApartHotels,
    BrandAbout,
    BrandValues,
    BrandBanner,
  },
  setup() {
    return {
    };
  }
})
</script>

<template>
  <section class="brand">
    <BrandBanner />
    <BrandValues type="values" />
    <BrandAbout />
    <BrandValues type="advantages" />
    <BrandApartHotels />
    <BrandFounders />
  </section>
</template>