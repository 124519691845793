<script>
import {computed, defineComponent, ref} from "vue";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import CategorySegmentServiceForm from "@/views/buildings/category-segment/tabs/CategorySegmentServiceForm.vue";
import {useStore} from "vuex";

export default defineComponent({
  components: {InputText, Checkbox, Button, CategorySegmentServiceForm},
  setup() {
    const store = useStore();
    const categoryServices = computed(() => store.getters.getRoomCategoryServicesList);

    return {categoryServices};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <span class="text-3xl font-bold">
      Доступные услуги
    </span>
  </section>

  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12 md:col-6" v-for="(service, i) in categoryServices" :key="i">
        <CategorySegmentServiceForm :service="service" />
      </div>
    </div>
  </section>
</template>