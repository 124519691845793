<script>
import {defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import Button from "primevue/button";
import InputNumberPhone from "@/components/inputs/InputNumberPhone.vue";
import {useCountries} from "@/hooks/countries";
import SelectPhoneModal from "@/components/modals/SelectPhoneModal.vue";
import {useI18n} from "vue-i18n";
import {email, minLength, required} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";
import {useApiCall} from "@/composables/useApiCall";
import {contactApiCall, updateContactApiCall} from "@/services/website";
import Skeleton from "primevue/skeleton"

export default defineComponent({
  components: {
    SelectPhoneModal,
    InputNumberPhone,
    ButtonSuccess,
    ButtonFileUpload,
    InputText,
    Editor,
    MainCard,
    Button,
    Skeleton,
  },
  setup() {
    const {t} = useI18n()
    const form = reactive({
      phone: '',
      email: ''
    });
    const formRules = {
      phone: {required, minLength: minLength(10)},
      email: {required, email},
    }
    const v$ = useVuelidate(formRules, form);
    const isUpdated = ref(false)
    const {countries, selectCountry: country, loadCountries} = useCountries();
    let visible = ref(false);

    const toggleChangePhoneCode = () => {
      visible.value = !visible.value
    }
    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(contactApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateContactApiCall, true)

    const updateForm = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      try {
        await updateContentAction(form)
        if (contentUpdateData.value) {
          isUpdated.value = true
        }
      } catch (e) {
        console.error(e)
      }
    }

    watch(() => form,
        () => {
          isUpdated.value = false
        }, {deep: true})

    onBeforeMount( async () => {
      try {
        await loadCountries();
        await getContentAction()
        if (contentData.value?.data) {
          form.phone = contentData.value.data.phone
          form.email = contentData.value.data.email
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      v$,
      form,
      isUpdated,
      updateForm,
      isContentLoading,
      contentError,
      contentUpdateError,
      isContentUpdateLoading,
      countries,
      country,
      toggleChangePhoneCode,
      visible,
    };
  }
})
</script>

<template>
  <SelectPhoneModal
      v-model:modelValue="visible"
      :countries="countries"
  />
  <div class="mt-7">
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>{{ t('website.contacts.title')}}</h1>
      <ButtonSuccess v-if="isUpdated" :label="t('button.changesSaved')" class="btn-fix-height"/>
      <Button v-else @click="updateForm" :loading="isContentUpdateLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
    </div>
    <div class="flex gap-4">
      <Skeleton v-if="isContentLoading" width="100%" height="157px" class="mb-3 mt-2"></Skeleton>
      <MainCard v-else :title="t('input.phone.title')" class="mb-3 w-full">
        <InputNumberPhone
            v-model="form.phone"
            :class="['w-full', {'p-invalid': v$.phone.$errors.length || contentUpdateError?.data.phone}]"
            @toggleChangePhoneCode="toggleChangePhoneCode"
            :phone-code="country?.phone_code ? country.phone_code : '+7'"
            :country="country?.name"
            isRequired
            :placeholder="t('input.phone.placeholder')"
        />
        <span v-if="v$.phone.$errors.length || contentUpdateError?.data.phone" class="text-xs color-error">
              {{ contentUpdateError ? contentUpdateError.data.phone[0] : t(v$.phone.$errors[0].$message) }}
        </span>
      </MainCard>
      <Skeleton v-if="isContentLoading" width="100%" height="157px" class="mb-3 mt-2"></Skeleton>
      <MainCard v-else :title="t('input.email.title')" class="mb-3 w-full">
            <span class="p-float-label w-full">
              <InputText
                  v-model="form.email"
                  :class="['w-full', {'p-invalid': v$.email.$errors.length || contentUpdateError?.data.email}]"
                  id="email"
              />
              <label for="email">{{ t('input.email.placeholder') }} <i style="color: #E26B6B">*</i></label>
            </span>
        <span v-if="v$.email.$errors.length || contentUpdateError?.data.email" class="text-xs color-error">
              {{ contentUpdateError ? contentUpdateError.data.email[0] : t(v$.email.$errors[0].$message) }}
        </span>
      </MainCard>
    </div>
  </div>
</template>
