<script>
import {computed, defineComponent, ref, watch} from "vue";

import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Editor from "primevue/editor";
import FileUpload from "@/components/FileUpload.vue";
import MainCard from "@/components/cards/MainCard.vue";
import {useStore} from "vuex";
import ImageCard from "@/components/cards/ImageCard.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import uuid from "vue3-uuid";

export default defineComponent({
  components: {ButtonFileUpload, ImageCard, InputText, Checkbox, Editor, FileUpload, MainCard},
  props: {
    formData: {
      type: Object,
      required: true,
    },
    inclusions: {
      type: Array,
      required: true,
    },
    photos: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const form = ref(props.formData);
    const store = useStore();

    const deleteFile = (uuid, type) => {
      store.commit('addDeleteRoomCategoryPhotos', uuid)
      switch (type) {
        case 'common':
          props.formData.common_photos = props.formData.common_photos.filter(item => item.uuid !== uuid)
          return
        case 'plan':
          props.formData.plan_photos = props.formData.plan_photos.filter(item => item.uuid !== uuid)
          return;
        case 'places':
          props.formData.places_photos = props.formData.places_photos.filter(item => item.uuid !== uuid)
          return;
      }
    }
    const destroyFileMemory = (file, type) => {
      store.commit('deleteRoomCategoryPhotos', {file, type})
    }
    const loadFileMemory = (data, index) => {
      switch (index) {
        case 0:
          store.commit('addRoomCategoryPhotos', {files: data.files, type: 'common'})
          return
        case 1:
          store.commit('addRoomCategoryPhotos', {files: data.files, type: 'plan'})
          return
        case 2:
          store.commit('addRoomCategoryPhotos', {files: data.files, type: 'places'})
          return
      }
    }
    watch(() => form.value, () => {
      const inclusions = []
      form.value.inclusions.forEach(item => {
        if(item.include) {
          inclusions.push(item.id)
        }
      })
      store.commit('updateCurrentRoomCategory', {
        ...form.value,
        inclusions: inclusions,
      })
    }, {deep: true})

    return {form, deleteFile, destroyFileMemory, loadFileMemory};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12 md:col-4">
        <MainCard title="Название категории">
          <span class="p-float-label w-full">
            <InputText
                id="name"
                class="w-full"
                v-model="form.name_ru"
                disabled
            />
            <label for="name">Название *</label>
          </span>
          <span v-if="errors?.name_ru" class="flex text-xs color-error">
            {{ errors.name_ru[0] }}
          </span>
        </MainCard>
      </div>
      <div class="col-12 md:col-8">
        <MainCard title="Основные данные">
          <div class="grid">
            <div class="col-12 md:col-6">
              <span class="p-float-label w-full">
                <InputText
                    id="area"
                    class="w-full mb-2"
                    v-model="form.area"
                    :disabled="form.area_missing"
                />
                <label for="area">Площадь, м</label>
              </span>
              <span v-if="errors?.area" class="flex text-xs color-error">
                {{ errors.area[0] }}
              </span>
              <Checkbox
                  :binary="true"
                  v-model="form.area_missing"
              />
              <label class="ml-2">Площадь отсутствует</label>
            </div>
            <div class="col-12 md:col-6">
              <span class="p-float-label w-full">
                <InputText
                    v-model="form.capacity"
                    id="area"
                    class="w-full mb-2"
                    :disabled="form.capacity_missing"
                />
                <label for="area">Вместимость, чел.</label>
              </span>
              <span v-if="errors?.capacity" class="flex text-xs color-error">
                {{ errors.capacity[0] }}
              </span>
              <div class="flex justify-content-between">
                <div>
                  <Checkbox
                      v-model="form.capacity_missing"
                      :binary="true"
                  />
                  <label class="ml-2">Вместимость отсутствует</label>
                </div>
                <div>
                  <Checkbox
                      v-model="form.has_guests"
                      :binary="true"
                  />
                  <label class="ml-2">Учет гостей</label>
                </div>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  </section>

<!--  <section class="py-2 mb-3">-->
<!--    <div class="grid">-->
<!--      <div class="col-12 md:col-6">-->
<!--        <MainCard title="Описание разовых платежей">-->
<!--          <Editor/>-->
<!--        </MainCard>-->
<!--      </div>-->
<!--      <div class="col-12 md:col-6">-->
<!--        <MainCard title="Условия заселения">-->
<!--          <Editor/>-->
<!--        </MainCard>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->

  <section class="py-2 mb-3">
    <span class="text-3xl font-bold">Наличие в категории</span>
  </section>

  <section class="py-2 mb-3">
    <div class="grid border-round-xl">
      <div class="col-12 md:col-4 p-0" v-for="(inclusion, i) in form.inclusions" :key="i">
        <div class="bg-white m-1 p-2">
          <Checkbox
              v-model="inclusion.include"
              :name="`inclusion_${i}`"
              :binary="true"
          />
          <label class="ml-3 font-bold" :for="`inclusion_${i}`">
            {{ inclusion.name_ru }}
          </label>
        </div>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Описание категории" isRequired>
      <Editor v-model="form.description_ru"></Editor>
      <span v-if="errors?.description_ru" class="flex text-xs color-error">
        {{ errors.description_ru[0] }}
      </span>
    </MainCard>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Изображения">
      <div v-for="(value, key) in errors" class="flex flex-column">
        <span v-if="key.includes('common_photos')" class="flex mb-2 text-xs color-error">{{ errors[key][0] }}</span>
      </div>
      <div class="grid">
        <div v-for="(image, i) in formData.common_photos" :key="i" class="col-12 md:col-2">
          <ImageCard :src="image.preview_url" :handle="() => deleteFile(image.uuid, 'common')"/>
        </div>
        <template v-if="photos.common.length">
          <div v-for="(file, i) in photos.common" :key="i" class="col-12 md:col-2">
            <ImageCard :src="file.objectURL" :handle="() => destroyFileMemory(file, 'common')"/>
          </div>
        </template>
        <div class="col-12">
          <ButtonFileUpload @chooseFiles="loadFileMemory" label="Добавить изображение" :multiple="true"
                            :clear-files-after-select="true" :index="0"/>
        </div>
      </div>
    </MainCard>
  </section>
  <section class="py-2 mb-3">
    <MainCard title="Общий план">
      <div v-for="(value, key) in errors" class="flex flex-column">
        <span v-if="key.includes('plan_photos')" class="flex mb-2 text-xs color-error">{{ errors[key][0] }}</span>
      </div>
      <div class="grid">
        <div v-for="(image, i) in formData.plan_photos" :key="i" class="col-12 md:col-2">
          <ImageCard :src="image.preview_url" :handle="() => deleteFile(image.uuid, 'plan')"/>
        </div>
        <template v-if="photos.plan.length">
          <div v-for="(file, i) in photos.plan" :key="i" class="col-12 md:col-2">
            <ImageCard :src="file.objectURL" :handle="() => destroyFileMemory(file, 'plan')"/>
          </div>
        </template>
        <div class="col-12">
          <ButtonFileUpload @chooseFiles="loadFileMemory" label="Добавить изображение" :multiple="true"
                            :clear-files-after-select="true" :index="1"/>
        </div>
      </div>
    </MainCard>
  </section>
  <section class="py-2 mb-3">
    <MainCard title="План рассадочных мест">
      <div v-for="(value, key) in errors" class="flex flex-column">
        <span v-if="key.includes('places_photos')" class="flex mb-2 text-xs color-error">{{ errors[key][0] }}</span>
      </div>
      <div class="grid">
        <div v-for="(image, i) in formData.places_photos" :key="i" class="col-12 md:col-2">
          <ImageCard :src="image.preview_url" :handle="() => deleteFile(image.uuid, 'places')"/>
        </div>
        <template v-if="photos.places.length">
          <div v-for="(file, i) in photos.places" :key="i" class="col-12 md:col-2">
            <ImageCard :src="file.objectURL" :handle="() => destroyFileMemory(file, 'places')"/>
          </div>
        </template>
        <div class="col-12">
          <ButtonFileUpload @chooseFiles="loadFileMemory" label="Добавить изображение" :multiple="true"
                            :clear-files-after-select="true" :index="2"/>
        </div>
      </div>
    </MainCard>
  </section>
</template>

<style scoped>

</style>