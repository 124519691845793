<script>
import {defineComponent, watch, ref, computed} from "vue";

import InputNumber from "primevue/inputnumber";
import InputMask from "primevue/inputmask"
import LoadImageCountryPlugin from "@/plugins/country-flags-image";

export default defineComponent({
  components: {InputNumber, InputMask},
  props: {
    country: {
      type: String,
    },
    phoneCode: {
      type: String,
    },
    modelValue: String,
    placeholder: {
      type: String,
      required: false,
    },
    isRequired: { type: Boolean, required: false}
  },
  emits: ['update:modelValue','toggleChangePhoneCode'],
  setup(props, {emit}) {
    const phone = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    });

    const toggleChangePhoneCode = () => {
      emit('toggleChangePhoneCode', true);
    };

    const source = ref("");

    source.value = LoadImageCountryPlugin();

    watch(
        () => props.country,
        (val) => {
          source.value = LoadImageCountryPlugin(val);
        }
    )
    return {source, phone, toggleChangePhoneCode};
  }
});

</script>

<template>
      <span class="p-input-icon-left p-float-label w-full">
        <i
            @click="toggleChangePhoneCode"
            class="icon-number flex align-items-center left-0 cursor-pointer color-black-80"
        >
          <img width="20" :src="source" alt="">
          <span>{{ phoneCode }}</span>
        </i>
        <InputMask
            id="phone"
            class="w-full color-black-80"
            v-bind="$attrs"
            v-model="phone"
            mask="(999) 999 99 99"
            style="font-family: 'RF Dewi', sans-serif;"
            :style="`padding-left:${2.5   + (phoneCode.length) / 1.85}rem !important`"
        />
        <label
            class="left-number-label"
            for="phone"
        >{{ placeholder ? placeholder : 'Контактный телефон' }}<i v-if="isRequired" style="color: #E26B6B">*</i></label>
      </span>
  <span>
</span>
</template>

<style lang="scss" scoped>
::v-deep(.icon-number) {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  margin: -11px -1px 0 15px !important;
  top: 52.5% !important;
}

::v-deep(.left-number-label) {
  left: 3.5rem !important;
}

::v-deep(.p-filled ~ label) {
  left: 0.5rem !important;
}

::v-deep(input) {
  &:focus ~ label {
    left: 0.5rem !important;
  }
}
</style>