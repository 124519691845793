<script>
import BrandCard from "@/components/cards/BrandCard.vue";
import {computed, defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import Button from "primevue/button";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import WebsiteAddButton from "@/components/website/WebsiteAddButton.vue";
import {useApiCall} from "@/composables/useApiCall";
import {
  valuesApiCall,
  addValuesApiCall,
  updateValuesApiCall,
  deleteValuesApiCall,
  advantageApiCall,
  addAdvantageApiCall,
  updateAdvantageApiCall,
  deleteAdvantageApiCall,
} from "@/services/website";
import {useI18n} from "vue-i18n";
import {useVuelidate} from "@vuelidate/core";
import Skeleton from "primevue/skeleton"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import {helpers, required} from "@vuelidate/validators";

export default defineComponent({
  components: {
    ConfirmationModal,
    ButtonSuccess,
    BrandCard,
    Button,
    Skeleton,
    WebsiteAddButton,
  },
  props: {
    type: {
      type: String,
      required: true,
    }
  },
  setup(props) {
    const {t} = useI18n()
    const isValues = computed(() => props.type === 'values')
    const scope = computed(() => isValues.value ? 'values' : 'advantages')
    const form = reactive({
      fields: []
    });
    const isUpdated = ref(false)
    const formRules = {
      fields: {
        $each: helpers.forEach({
          text_ru: {
            required
          },
          photo: {
            required
          }
        })
      }
    }
    const validError = ref(false)
    const deleteItem = ref(null)
    const visible = ref(false)
    const addError = ref(null)
    const v$ = useVuelidate(formRules, form);

    const isLoading = computed(() =>
        isContentUpdateLoading.value ||
        isContentAddLoading.value ||
        isContentDeleteLoading.value
    )

    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(isValues.value ? valuesApiCall : advantageApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(isValues.value ? updateValuesApiCall : updateAdvantageApiCall, true)

    const {
      isLoading: isContentAddLoading,
      data: contentAddData,
      error: contentAddError,
      executeApiCall: addContentAction,
    } = useApiCall(isValues.value ? addValuesApiCall : addAdvantageApiCall, true)

    const {
      isLoading: isContentDeleteLoading,
      data: contentDeleteData,
      error: contentDeleteError,
      executeApiCall: deleteContentAction,
    } = useApiCall(isValues.value ? deleteValuesApiCall : deleteAdvantageApiCall, true)
    const deleteSlide = async () => {
      if (!form.fields[deleteItem.value].id.toString().includes('newItem')) {
        try {
          await deleteContentAction({id: form.fields[deleteItem.value].id })
        } catch (e) {
          console.error(e)
        }
      }
      if (contentDeleteData.value || form.fields[deleteItem.value].id.toString().includes('newItem')) {
        form.fields.splice(deleteItem.value, 1);
        deleteItem.value = null
        changeVisibleModal()
      }
    }

    const addSlide = () => {
      form.fields.push({
        id: `newItem${form.fields.length + 1}`,
        text_ru: '',
        photo: null,
      })
    }
    const changeSlide = (data, index) => {
      form.fields[index] = data
    }
    const updateForm = async () => {
      validError.value = false
      addError.value = null
      const result = await v$.value.$validate();
      if (!result) {
        validError.value = true
        return;
      }
      for (let i = 0; i < form.fields.length; i += 1) {
        if (!form.fields[i].id.toString().includes('newItem')) {
          const oldItem = contentData.value.data.findIndex(item => item.id === form.fields[i].id)
          if (contentData.value.data[oldItem] && (form.fields[i].photo.objectURL || (contentData.value.data[oldItem].photo.id !== form.fields[i].photo.id) || (contentData.value.data[oldItem].text_ru !== form.fields[i].text_ru))) {
            const formData = new FormData()
            if (form.fields[i].photo.objectURL) {
              formData.append('photo', form.fields[i].photo)
            }
            if (contentData.value.data[oldItem].text_ru !== form.fields[i].text_ru) {
              formData.append('text_ru', form.fields[i].text_ru)
            }
            try {
              await updateContentAction({id: form.fields[i].id, fields: formData})
              if (contentUpdateData.value) {
                await updateData(contentUpdateData.value.data, i, oldItem)
              }
            } catch (e) {
              addError.value = {item: form.fields[i], error: contentUpdateError.value.data}
              console.error(e)
              return
            }
          }
        } else {
          const formData = new FormData()
          formData.append('text_ru', form.fields[i].text_ru)
          formData.append('photo', form.fields[i].photo)
          try {
            await addContentAction(formData)
            if (contentAddData.value) {
              await addData(contentAddData.value.data, i)
            }
          } catch (e) {
            addError.value = {item: form.fields[i], error: contentAddError.value.data}
            console.error(e)
            return
          }
        }
      }
      if(!contentAddError.value && !contentUpdateError.value && !contentError.value) {
        isUpdated.value = true
      }
    }

    const updateData = async (data, index, oldItem) => {
      contentData.value.data[oldItem] = {...data}
      form.fields[index] = {...data}
    }

    const addData = async (data, index) => {
      contentData.value.data.push(data)
      form.fields[index] = {...data}
    }

    watch(() => form.fields,
        ()=> isUpdated.value = false, {deep: true})

    const loadSlides = async() => {
      try {
        await getContentAction()
        if (contentData.value?.data) {
          form.fields = [...contentData.value.data]
        }
      } catch (e) {
        console.error(e)
      }
    }

    const changeVisibleModal = (index = null) => {
      visible.value = !visible.value
      if (index || index === 0) {
        deleteItem.value = index
      }
    };

    onBeforeMount( async () => {
      await loadSlides()
    })

    return {
      t,
      form,
      isUpdated,
      isLoading,
      scope,
      changeSlide,
      addSlide,
      deleteSlide,
      updateForm,
      v$,
      validError,
      addError,
      isContentLoading,
      visible,
      changeVisibleModal,
      isValues,
    };
  }
})
</script>

<template>
  <ConfirmationModal :visible="visible">
    <template #header>
      Удалить {{ t(`confirmationModal.${type}.title`) }}
    </template>

    <template #default>
      <span>Вы уверены,  что хотите удалить {{ t(`confirmationModal.${type}.title`) }}? Это действие будет невозможно отменить</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="changeVisibleModal" class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" @click="deleteSlide" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>
  <div class="website-slider mt-7">
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>{{ t(`website.${type}.title`) }}</h1>
      <ButtonSuccess v-if="isUpdated" :label="t('button.changesSaved')" class="btn-fix-height"/>
      <Button
          v-else
          @click="updateForm"
          :loading="isLoading"
          :label="t('button.saveChange')"
          class="btn-primary font-light btn-fix-height"
      />
    </div>
    <div v-if="form.fields.length" class="website-slider__wrapper">
      <BrandCard
          v-for="(item, i) in form.fields"
          :key="item.id"
          :scope="scope"
          :slide="item"
          :index="i"
          :title="t(`websiteCard.${type}.title`)"
          :inputTitle="t(`websiteCard.${type}.input`)"
          :imgTitle="t(`websiteCard.${type}.image`)"
          :rows="isValues ? 1 : 4"
          :isIcon="!isValues"
          @deleteSlide="changeVisibleModal"
          @changeSlide="changeSlide"
          :validError="validError"
          :errors="addError && addError.item === item ? addError.error : null"
      />
    </div>
    <Skeleton v-if="isContentLoading" height="121px"></Skeleton>
    <div v-if="!isValues || (isValues && form.fields.length < 5)" class="website-slider__wrapper mt-4">
      <WebsiteAddButton
          @clickHandler="addSlide"
          :title="`Добавить ${ t(`confirmationModal.${type}.title`) }`"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.website-slider {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    row-gap: 16px;
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>