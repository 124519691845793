import http from "@/plugins/axios";

const receiptApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/document/history', { signal: abortController?.signal, params })
    return data
}

const receiptUploadApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/document/${params.type}/upload`,
        params.data,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    return data
}

export {
    receiptApiCall,
    receiptUploadApiCall,
}