<script>
import {defineComponent, ref, watch} from "vue";

import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import FileUpload from "@/components/FileUpload";
import ImagesBuilding from "@/views/buildings/ImagesBuilding.vue";

export default defineComponent({
  components: {ImagesBuilding, Editor, MainCard, FileUpload},
  props: {
    formData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    }
  },
  emits: ['changeFormData'],
  setup(props, {emit}) {
    const form = ref(props.formData);

    const changePhotoArray = (array) => {
      form.value.improvement_photos = array
    }

    watch(() => form.value, () => emit('changeFormData', form.value), {deep: true});

    return {form, changePhotoArray};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12">
        <MainCard title="Описание">
          <div class="grid">
            <div class="col-12">
              <Editor v-model="form.improvement_ru" class="w-full"></Editor>
              <span v-if="errors?.improvement_ru" class="text-xs color-error">
                {{ errors.improvement_ru[0] }}
              </span>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  </section>
  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12">
        <ImagesBuilding
            :errors="errors"
            :photos="formData.improvement_photos"
            type="improvement_photos"
            :files="files"
            @changeFormData="changePhotoArray"
        />
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>