import http from "../plugins/axios";

const repairFormApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/service/repair-form',
        {
            signal: abortController?.signal,
        })
    return data
}
const updateRepairFormApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/service/repair-form',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const addPhotoRepairFormApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/service/repair-form/photo/upload',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const deletePhotoRepairFormApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/service/repair-form/photo/delete',
        {...params},
        {
            signal: abortController?.signal,
        })
    return data
}

export {
    repairFormApiCall,
    updateRepairFormApiCall,
    deletePhotoRepairFormApiCall,
    addPhotoRepairFormApiCall,
};