<script>
import {defineComponent, ref, watch} from "vue";
import Card from "primevue/card";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@/utils/i18n-validators";
import {useI18n} from "vue-i18n";
import ImageCard from "@/components/cards/ImageCard.vue";
import MainCard from "@/components/cards/MainCard.vue";
import InputText from "primevue/inputtext";

export default defineComponent({
  components: {
    MainCard, ImageCard,
    ButtonFileUpload,
    Card,
    InputText,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    scope: {
      type: String,
      required: true,
    },
    validError: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
    }
  },
  emits: ['deleteCategory', 'changeCategory', 'deletePhotoCategory'],
  setup(props, {emit}) {
    const {t} = useI18n()
    const form = ref({...props.category})
    const formRules = {
      photos: {},
      title_ru: {required},
    }
    const v$ = useVuelidate(formRules, form.value, { $scope: props.scope });

    const deleteCategory = () => {
      emit('deleteCategory', props.index)
    }
    const loadFileMemory = (data) => {
      form.value.photos = form.value.photos.concat(data.files)
    }
    const deleteCategoryPhoto = (uuid, id) => {
      form.value.photos = form.value.photos.filter(item => item.uuid !== uuid)
      emit('deletePhotoCategory', {uuid: uuid, id: id})
    }
    const deleteFileMemory = (url) => {
      form.value.photos = form.value.photos.filter(item => item.objectURL !== url)
    }

    watch(() => form.value,
        () => {
          emit('changeCategory', form.value, props.index)
        }, { deep: true }
    )
    watch(() => props.validError,
        async () => {
          if (props.validError)
            await v$.value.$validate();
        })
    return {
      form,
      v$,
      t,
      deleteCategory,
      loadFileMemory,
      deleteCategoryPhoto,
      deleteFileMemory,
    };
  }
});
</script>

<template>
  <MainCard :title="!type ? `Категория ${index + 1}` : '' " :withDelete="!type" :index="index" @deleteItem="deleteCategory" class="mb-3">
    <div class="mt-2">
      <div class="w-full">
            <span class="p-float-label w-full">
              <InputText
                  v-model="form.title_ru"
                  id="categoryTitle"
                  :class="['w-full', {'p-invalid': v$.title_ru.$errors.length || errors?.title_ru}]"
              />
              <label for="categoryTitle">Введите название категории <i style="color: #E26B6B">*</i></label>
            </span>
        <span v-if="v$.title_ru.$errors.length || errors?.title_ru" class="text-xs color-error">
              {{ errors?.title_ru ? errors.title_ru[0] : t(v$.title_ru.$errors[0].$message) }}
        </span>
      </div>
      <div class="mt-4" >
        <span class="flex mb-4 website-category__bold">Изображения слайдов</span>
        <div class="flex flex-wrap gap-2">
          <template v-if="form.photos.length">
            <div v-for="(file, i) in form.photos" :key="i" class="website-category__image">
              <ImageCard v-if="file.preview_url" :src="file.preview_url" :handle="() => deleteCategoryPhoto(file.uuid, form.id)"/>
              <ImageCard v-else :src="file.objectURL" :handle="() => deleteFileMemory(file.objectURL)"/>
              <span  v-if="errors?.text.photo && errors?.url === file.objectURL" class="text-xs color-error">
                {{ errors.text.photo[0] }}
              </span>
            </div>
          </template>
          <div class="col-12 px-0 pb-0 flex align-items-end">
            <ButtonFileUpload
                @chooseFiles="loadFileMemory"
                label="Добавить изображение"
                :multiple="true"
                :clear-files-after-select="true"
                :index="index"
            />
            <button v-if="type === 'gallery'" @click="deleteCategory" class="website-category__delete">Удалить категорию</button>
          </div>
        </div>
      </div>
    </div>
  </MainCard>
</template>
<style lang="scss" scoped>
.website-category {
  &__image {
    width: 208px;
  }
  &__bold {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
  }
  &__delete {
    display: flex;
    margin-left: auto;
    border: unset;
    background: transparent;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    color: #E26B6B;
    text-decoration: underline;
  }
}
</style>
