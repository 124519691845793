<script>
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

import Paginator from "primevue/paginator";
import Button from "primevue/button";
import TariffsTable from "@/components/tables/TariffsTable";
import {useStore} from "vuex";

export default defineComponent({
  layout: {name: "AdminLayout"},
  components: {TariffsTable, Button, Paginator},
  setup() {
    const {t} = useI18n();
    const router = useRouter();
    const store = useStore();
    const first = ref(0);
    const tariffs = computed(() => store.getters.getListTariffs);
    const toCreateTariff = async () => {
      await router.push({ name: 'tariff-create' });
    };

    const loadTariffs = async () => {
      try {
        await store.dispatch('fetchTariffs', {
          page: ((first.value / (tariffs.value?.data?.pagination?.per_page ?? 1)) + 1)
        });
        window.scrollTo(0,0);
      } catch (e) {
        console.error(e)
      }
    };

    watch((first), async (index) => {
      await loadTariffs();
    });

    onBeforeMount(async () => {
      await loadTariffs()
    });

    return { toCreateTariff, t, tariffs, first};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <h1>{{ t('menu.tariffs') }}</h1>
      <Button :label="t('labels.tariff-create')" @click="toCreateTariff" class="btn-primary font-light" />
    </div>
  </section>
  <template v-if="tariffs.data?.data">
    <section class="py-2 mb-3">
      <TariffsTable :tariffs="tariffs?.data?.data ?? []" />
    </section>
    <section class="py-2 mb-3">
      <Paginator
          v-model:first="first"
          :rows="tariffs?.data?.pagination?.per_page ?? 0"
          :totalRecords="tariffs?.data?.pagination?.total ?? 0"
          class="justify-content-start"
      ></Paginator>
    </section>
  </template>

  <template >
    <section class="py-2 mb-3">
      <div class="flex justify-content-center">
        <span>{{ t('empty-data') }}</span>
      </div>
    </section>
  </template>
</template>

<style scoped>

</style>