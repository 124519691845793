<script>
import {computed, defineComponent, ref, watch} from "vue";
import Breadcrumb from "@/components/Breadcrumb";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import MainCard from "@/components/cards/MainCard";
import InputNumberPhone from "@/components/inputs/InputNumberPhone";
import SelectPhoneModal from "@/components/modals/SelectPhoneModal";
import {useI18n} from "vue-i18n";
import {useCountries} from "@/hooks/countries";
import {useRoute} from "vue-router";

export default defineComponent({
  components: {
    Breadcrumb, Button, Dropdown, Calendar, InputText, MainCard, InputNumberPhone, SelectPhoneModal, Checkbox
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    responseError: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, {emit}) {
    const {t} = useI18n()
    const route = useRoute()
    const {countries, selectCountry} = useCountries();
    const isItemPage = computed(() => route.name === 'vendor-view')
    const legalForms = ref([{label: 'ООО'}, {label: 'ИП'}]);

    const formData = ref(props.form);
    formData.value.type = 'ul';

    const isMatch = computed(() => {
      return (formData.value.postcode === formData.value.postcode_fact) &&
          (formData.value.building === formData.value.building_fact) &&
          (formData.value.city === formData.value.city_fact) &&
          (formData.value.corps === formData.value.corps_fact) &&
          (formData.value.floor === formData.value.floor_fact) &&
          (formData.value.region === formData.value.region_fact) &&
          (formData.value.room === formData.value.room_fact) &&
          (formData.value.street === formData.value.street_fact) &&
          ((formData.value.house === formData.value.house_fact));
    });

    const mergeAddresses = () => {
      formData.value.postcode_fact = formData.value.postcode
      formData.value.building_fact = formData.value.building
      formData.value.city_fact = formData.value.city
      formData.value.corps_fact = formData.value.corps
      formData.value.floor_fact = formData.value.floor
      formData.value.region_fact = formData.value.region
      formData.value.room_fact = formData.value.room
      formData.value.street_fact = formData.value.street
      formData.value.house_fact = formData.value.house
    }
    const showFact = ref(isMatch.value)

    watch(formData, () => {
      emit('formChange', formData.value);
    });

    const visible = ref(false);
    const changeVisible = () => {
      visible.value = !visible.value;
    };

    const toggleFact = () => {
      showFact.value = !showFact.value
    }

    watch(() => isMatch.value,
        () => {
          if(!isMatch.value) {
            showFact.value = false
          }
        })

    watch(() => showFact.value,
        () => {
          if(showFact.value) {
            mergeAddresses()
          }
        })

    return {formData, showFact, toggleFact, changeVisible, selectCountry, legalForms, mergeAddresses, isMatch, t, countries, visible, isItemPage};
  }
});
</script>

<template>
  <SelectPhoneModal :countries="countries" v-model:modelValue="visible" @toggleCloseModal="changeVisible"/>

  <section class="py-2 mb-3">
    <div class="grid m-0 mb-2">
      <div class="col-12">
        <MainCard title="Основные регистрационные сведения">
          <div class="grid mb-4">
            <div class="col-12 md:col-4">
              <div class="grid gap-4 m-0 flex-column">
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.full_name"
                        :class="{'p-invalid': errors.full_name.$errors.length || responseError?.full_name?.length}"
                        id="full_name"
                        class="w-full"
                    />
                    <label for="full_name">Полное наименование  <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.full_name.$errors.length || responseError?.full_name?.length" class="text-xs color-error">
                    {{ responseError ? responseError.full_name[0] : t(errors.full_name.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.short_name"
                        :class="{'p-invalid': errors.short_name.$errors.length || responseError?.short_name?.length}"
                        id="first_name"
                        class="w-full"
                    />
                    <label for="first_name">Краткое наименование   <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.short_name.$errors.length|| responseError?.short_name?.length" class="text-xs color-error">
                    {{ responseError ? responseError.short_name[0] : t(errors.short_name.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                      <Dropdown
                        v-model="formData.legal_form"
                        :class="{'p-invalid': errors.legal_form.$errors.length || responseError?.legal_form?.length}"
                        :options="legalForms"
                        optionLabel="label"
                        optionValue="label"
                        class="w-full">
                      </Dropdown>
                     <label for="legal_form">Организационно-правовая форма <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.legal_form.$errors.length || responseError?.legal_form?.length" class="text-xs color-error">
                    {{ responseError ? responseError.legal_form[0] : t(errors.legal_form.$errors[0].$message) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="grid m-0 gap-4">
                <div class="w-full">
                  <span class="p-float-label w-full">
                      <InputText
                          v-model="formData.inn"
                          :class="{'p-invalid': errors.inn.$errors.length || responseError?.inn?.length}"
                          id="inn"
                          class="w-full"
                      />
                      <label for="inn">ИНН  <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.inn.$errors.length || responseError?.inn?.length" class="text-xs color-error">
                    {{ responseError ? responseError.inn[0] : t(errors.inn.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                      <InputText
                          v-model="formData.ogrn"
                          :class="{'p-invalid': errors.ogrn.$errors.length || responseError?.ogrn?.length}"
                          id="ogrnip"
                          class="w-full"
                      />
                      <label for="ogrnip">ОГРН  <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.ogrn.$errors.length || responseError?.ogrn?.length" class="text-xs color-error">
                    {{ responseError ? responseError.ogrn[0] : t(errors.ogrn.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.ogrn_place"
                        :class="{'p-invalid': errors.ogrn_place.$errors.length || responseError?.ogrn_place?.length}"
                        id="ogrn"
                        class="w-full"
                    />
                    <label for="ogrn">Место присвоения ОГРН  <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.ogrn_place.$errors.length || responseError?.ogrn_place?.length" class="text-xs color-error">
                    {{ responseError ? responseError.ogrn_place[0] : t(errors.ogrn_place.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label">
                        <Calendar
                            date-format="dd.mm.yy"
                            v-model="formData.ogrn_date"
                            :class="['w-full', {'p-invalid': errors.ogrn_date.$errors.length || responseError?.ogrn_date?.length}]"
                            inputId="ogrn_date"
                        />
                        <i class="pi pi-calendar calendar-icon" />
                      <label for="ogrn_date">Дата присвоения ОГРН (после 2002 года) <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.ogrn_date.$errors.length || responseError?.ogrn_date?.length" class="text-xs color-error">
                    {{ responseError ? responseError.ogrn_date[0] : t(errors.ogrn_date.$errors[0].$message) }}
                  </span>
                </div>
              </div>
            </div>

            <div class="col-12 md:col-4">
              <div class="grid m-0 gap-4">
                <div class="w-full">
                  <span class="p-float-label">
                        <Calendar
                            date-format="dd.mm.yy"
                            v-model="formData.reg_date"
                            :class="['w-full', {'p-invalid': errors.reg_date.$errors.length || responseError?.reg_date?.length}]"
                            inputId="reg_date"
                        />
                      <i class="pi pi-calendar calendar-icon" />
                      <label for="reg_date">Дата регистрации организации (до 2002 года) <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.reg_date.$errors.length || responseError?.reg_date?.length" class="text-xs color-error">
                    {{ responseError ? responseError.reg_date[0] : t(errors.reg_date.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.kpp"
                        :class="{'p-invalid': errors.kpp.$errors.length || responseError?.kpp?.length}"
                        id="kpp"
                        class="w-full"
                    />
                    <label for="kpp">КПП <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.kpp.$errors.length || responseError?.kpp?.length" class="text-xs color-error">
                    {{ responseError ? responseError.kpp[0] : t(errors.kpp.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                      <InputText
                          v-model="formData.oktmo"
                          :class="{'p-invalid': errors.oktmo.$errors.length || responseError?.oktmo?.length}"
                          id="oktmo"
                          class="w-full"
                      />
                      <label for="oktmo">ОКТМО  <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.oktmo.$errors.length || responseError?.oktmo?.length" class="text-xs color-error">
                    {{ responseError ? responseError.oktmo[0] : t(errors.oktmo.$errors[0].$message) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
    </div>

    <div class="grid m-0 mb-2">
      <div class="col-12">
        <MainCard title="Юридический адрес">
          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="grid gap-4 m-0 flex-column">
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.postcode"
                        :class="{'p-invalid': errors.postcode.$errors.length || responseError?.postcode?.length}"
                        id="postcode"
                        class="w-full"
                    />
                    <label for="postcode">Индекс <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.postcode.$errors.length || responseError?.postcode?.length" class="text-xs color-error">
                    {{ responseError ? responseError.postcode[0] : t(errors.postcode.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.region"
                        :class="{'p-invalid': errors.region.$errors.length || responseError?.region?.length}"
                        id="region"
                        class="w-full"
                    />
                    <label for="region">Регион <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.region.$errors.length || responseError?.region?.length" class="text-xs color-error">
                    {{ responseError ? responseError.region[0] : t(errors.region.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.city"
                        :class="{'p-invalid': errors.city.$errors.length || responseError?.city?.length}"
                        id="city"
                        class="w-full"
                    />
                    <label for="city">Город <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.city.$errors.length || responseError?.city?.length" class="text-xs color-error">
                    {{ responseError ? responseError.city[0] : t(errors.city.$errors[0].$message) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="grid gap-4 m-0 flex-column">
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.street"
                        :class="{'p-invalid': errors.street.$errors.length || responseError?.street?.length}"
                        id="street"
                        class="w-full"
                    />
                    <label for="street">Улица  <i style="color: #E26B6B">*</i></label>
                 </span>
                  <span v-if="errors.street.$errors.length || responseError?.street?.length" class="text-xs color-error">
                    {{ responseError ? responseError.street[0] : t(errors.street.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.house"
                        :class="{'p-invalid': errors.house.$errors.length || responseError?.house?.length}"
                        id="house"
                        class="w-full"
                    />
                    <label for="house">Дом  <i style="color: #E26B6B">*</i></label>
                 </span>
                  <span v-if="errors.house.$errors.length || responseError?.house?.length" class="text-xs color-error">
                    {{ responseError ? responseError.house[0] : t(errors.house.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.building"
                        :class="['w-full', {'p-invalid': responseError?.building?.length}]"
                        id="street"
                    />
                    <label for="street">Номер здания, строение и т.д...</label>
                  </span>
                  <span v-if="responseError?.building?.length" class="text-xs color-error">
                    {{ responseError.building[0] }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="grid gap-4 m-0 flex-column">
                <div class="w-full">
                  <span class="p-float-label w-full">
                      <InputText
                          v-model="formData.corps"
                          :class="['w-full', {'p-invalid': responseError?.corps?.length}]"
                          id="frame"
                      />
                      <label for="frame">Корпус</label>
                  </span>
                  <span v-if="responseError?.corps?.length" class="text-xs color-error">
                    {{ responseError.corps[0] }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.floor"
                        :class="['w-full', {'p-invalid': responseError?.floor?.length}]"
                        id="floor"
                    />
                    <label for="floor">Этаж</label>
                  </span>
                  <span v-if="responseError?.floor?.length" class="text-xs color-error">
                    {{ responseError.floor[0] }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.room"
                        :class="['w-full', {'p-invalid': responseError?.room?.length}]"
                        id="number_office"
                    />
                    <label for="number_office">Номер офиса</label>
                  </span>
                  <span v-if="responseError?.room?.length" class="text-xs color-error">
                    {{ responseError.room[0] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
    </div>

    <div class="grid mb-2">
      <div :class="['col-12', {'fact-info': showFact}]">
        <MainCard title="Фактический адрес">
          <template v-slot:title-action>
            <div class="flex align-items-center">
              <Checkbox
                  @click="toggleFact"
                  :model-value="showFact"
                  :binary="true"
                  name="match-address"
                  class="mr-2"
              />
              <label for="match-address">Совпадает с юридическим адресом</label>
            </div>
          </template>

          <div v-if="!showFact" class="grid">
            <div class="col-12 md:col-4">
              <div class="grid gap-4 m-0 flex-column">
                <div class="w-full">
                  <span class="p-float-label w-full">
                      <InputText
                          v-model="formData.postcode_fact"
                          :class="{'p-invalid': errors.postcode_fact.$errors.length || responseError?.postcode_fact?.length}"
                          id="postcode"
                          class="w-full"
                      />
                      <label for="postcode">Индекс <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.postcode_fact.$errors.length || responseError?.postcode_fact?.length" class="text-xs color-error">
                    {{ responseError ? responseError.postcode_fact[0] : t(errors.postcode_fact.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                      <InputText
                          v-model="formData.region_fact"
                          :class="{'p-invalid': errors.region_fact.$errors.length || responseError?.region_fact?.length}"
                          id="region"
                          class="w-full"
                      />
                      <label for="region">Регион <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.region_fact.$errors.length || responseError?.region_fact?.length" class="text-xs color-error">
                    {{ responseError ? responseError.region_fact[0] : t(errors.region_fact.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                      <InputText
                          v-model="formData.city_fact"
                          :class="{'p-invalid': errors.city_fact.$errors.length || responseError?.city_fact?.length}"
                          id="city"
                          class="w-full"
                      />
                      <label for="city">Город <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.city_fact.$errors.length || responseError?.city_fact?.length" class="text-xs color-error">
                    {{ responseError ? responseError.city_fact[0] : t(errors.city_fact.$errors[0].$message) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="grid gap-4 m-0 flex-column">
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.street_fact"
                        :class="{'p-invalid': errors.street_fact.$errors.length || responseError?.street_fact?.length}"
                        id="street"
                        class="w-full"
                    />
                    <label for="street">Улица  <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.street_fact.$errors.length || responseError?.street_fact?.length" class="text-xs color-error">
                    {{ responseError ? responseError.street_fact[0] : t(errors.street_fact.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.house_fact"
                        :class="{'p-invalid': errors.house_fact.$errors.length || responseError?.house_fact?.length}"
                        id="house"
                        class="w-full"
                    />
                    <label for="house">Дом  <i style="color: #E26B6B">*</i></label>
                  </span>
                  <span v-if="errors.house_fact.$errors.length || responseError?.house_fact?.length" class="text-xs color-error">
                    {{ responseError ? responseError.house_fact[0] : t(errors.house_fact.$errors[0].$message) }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.building_fact"
                        :class="{'p-invalid': responseError?.building_fact?.length}"
                        id="street"
                        class="w-full"
                    />
                    <label for="street">Номер здания, строение и т.д...</label>
                  </span>
                  <span v-if="responseError?.building_fact?.length" class="text-xs color-error">
                    {{ responseError.building_fact[0] }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 md:col-4">
              <div class="grid gap-4 m-0 flex-column">
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.corps_fact"
                        :class="{'p-invalid': responseError?.corps_fact?.length}"
                        id="frame"
                        class="w-full"
                    />
                    <label for="frame">Корпус</label>
                  </span>
                  <span v-if="responseError?.corps_fact?.length" class="text-xs color-error">
                    {{ responseError.corps_fact[0] }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.floor_fact"
                        :class="{'p-invalid': responseError?.floor_fact?.length}"
                        id="floor"
                        class="w-full"
                    />
                    <label for="floor">Этаж</label>
                  </span>
                  <span v-if="responseError?.floor_fact?.length" class="text-xs color-error">
                    {{ responseError.floor_fact[0] }}
                  </span>
                </div>
                <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.room_fact"
                        :class="{'p-invalid': responseError?.room_fact?.length}"
                        id="number_office"
                        class="w-full"
                    />
                    <label for="number_office">Номер офиса</label>
                  </span>
                  <span v-if="responseError?.room_fact?.length" class="text-xs color-error">
                    {{ responseError.room_fact[0] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
    </div>

    <div v-if="!isItemPage" class="col-12 md:col-5">
      <MainCard title="Контактные данные">
        <div class="grid">
          <div class="col-12">
            <div class="grid gap-4 m-0 flex-column">
              <div>
                <InputNumberPhone
                    v-model="formData.phone"
                    :country="selectCountry?.name"
                    :class="{'p-invalid': errors.email.$errors.length || responseError?.email?.length}"
                    :phone-code="selectCountry?.phone_code ? selectCountry.phone_code : '+7'"
                    @toggleChangePhoneCode="changeVisible"
                    isRequired
                />
                <span v-if="errors.phone.$errors.length || responseError?.phone?.length" class="text-xs color-error">
                    {{ responseError ? responseError.phone[0] : t(errors.phone.$errors[0].$message) }}
                  </span>
              </div>
              <div class="w-full">
                  <span class="p-float-label w-full">
                    <InputText
                        v-model="formData.email"
                        :class="{'p-invalid': errors.email.$errors.length || responseError?.email?.length}"
                        id="email"
                        class="w-full"
                    />
                    <label for="email">Электронная почта (для уведомлений бенефициару) <i style="color: #E26B6B">*</i></label>
                  </span>
                <span v-if="errors.email.$errors.length || responseError?.email?.length" class="text-xs color-error">
                    {{ responseError ? responseError.email[0] : t(errors.email.$errors[0].$message) }}
                  </span>
              </div>
            </div>
          </div>
        </div>
      </MainCard>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.fact-info {
  ::v-deep(.p-card-content) {
    padding: 0;
  }
}
</style>
