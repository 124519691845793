import http from "@/plugins/axios";
const articlesApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/articles',
        {
            signal: abortController?.signal,
        })
    return data
}

const articlesItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.get(`/api/company/v1/articles/${params.id}`,
        {
            signal: abortController?.signal,
        })
    return data
}

const deleteArticlesItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/articles/${params.id}`,
        {
            signal: abortController?.signal,
        })
    return data
}

const addArticlesApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/articles',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const updateArticlesApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/articles/${params.id}`,
        { ...params.fields },
        { signal: abortController?.signal })
    return data
}

const uploadArticlesPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/articles/${params.id}/upload-photo`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const deleteArticlesPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/articles/${params.id}/photo?uuid=${params.uuid}`,
        { signal: abortController?.signal })
    return data
}

const servicesApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/service-info',
        {
            signal: abortController?.signal,
        })
    return data
}

const servicesItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.get(`/api/company/v1/service-info/${params.id}`,
        {
            signal: abortController?.signal,
        })
    return data
}
const deleteServicesItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/service-info/${params.id}`,
        {
            signal: abortController?.signal,
        })
    return data
}
const addServiceApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/service-info',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }})
    return data
}

const updateServiceApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/service-info/${params.id}`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    return data
}

export {
    articlesApiCall,
    articlesItemApiCall,
    deleteArticlesItemApiCall,
    addArticlesApiCall,
    updateArticlesApiCall,
    uploadArticlesPhotoApiCall,
    deleteArticlesPhotoApiCall,
    servicesApiCall,
    servicesItemApiCall,
    deleteServicesItemApiCall,
    addServiceApiCall,
    updateServiceApiCall,
}