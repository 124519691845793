import {loadTypeLocations, loadTypeAttractions} from "@/services/type-locations";

const state = () => ({
   listTypeLocations: [],
    listTypeAttractions: []
});

const actions = {
    async fetchTypeLocations({commit}) {
        const {data: typeLocations} = await loadTypeLocations();
        commit('updateListTypeLocations', typeLocations);
    },
    async fetchTypeAttractions({commit}) {
        const {data: typeAttractions} = await loadTypeAttractions();
        commit('updateListTypeAttractions', typeAttractions);
    },
};

const mutations = {
    updateListTypeLocations(state, typeLocations) {
        state.listTypeLocations = typeLocations;
    },
    updateListTypeAttractions(state, typeAttractions) {
        state.listTypeAttractions = typeAttractions;
    }
};

const getters = {
    getListTypeLocations(state) {
        return state.listTypeLocations;
    },
    getListTypeAttractions(state) {
        return state.listTypeAttractions;
    }
};

export default {state, actions, mutations, getters};