<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    }
  },
  emits: ['clickHandler'],
  setup(props, {emit}) {
    const clickHandler = () => {
      emit('clickHandler')
    }
    return {
      clickHandler
    };
  }
})
</script>

<template>
  <button @click="clickHandler" class="add-button">
    <span>{{ title }}</span>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4V20M20 12H3.99998" stroke="#C3987A" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
</template>

<style lang="scss" scoped>
.add-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  gap: 8px;
  border: 1px dashed #C3987A;
  border-radius: 8px;
  background: transparent;
  color: #C3987A;
  font-size: 18px;
  line-height: 27px;
  cursor: pointer;
}
</style>