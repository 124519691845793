<script>
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import {useStore} from "vuex";

import TabMenu from "primevue/tabmenu";
import Button from "primevue/button";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import CategorySegmentDescriptionTabView from "@/views/buildings/category-segment/tabs/CategorySegmentDescriptionTabView.vue";
import CategorySegmentServicesTabView from "@/views/buildings/category-segment/tabs/CategorySegmentServicesTabView.vue";
import CategorySegmentTariffsView from "@/views/buildings/category-segment/tabs/CategorySegmentTariffsView.vue";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    Breadcrumb,
    ButtonSuccess,
    TabMenu,
    Button,
    CategorySegmentDescriptionTabView,
    CategorySegmentServicesTabView,
    CategorySegmentTariffsView,
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const store = useStore();

      await store.dispatch('fetchBuilding', to.params.id);
      await store.dispatch('fetchBuildingSegment', to.params.segmentId);

      await store.dispatch('fetchRoomCategory', {
        segmentId: to.params.segmentId,
        roomCategoryId: to.params.categoryId,
      });

      await store.dispatch('fetchInclusions');

      await store.dispatch('fetchRoomCategoryTariffs', {
        segmentId: to.params.segmentId,
        roomCategoryId: to.params.categoryId,
      });

      await store.dispatch('fetchRoomCategoryServices', {
        segmentId: to.params.segmentId,
        roomCategoryId: to.params.categoryId,
      });

      next();
    } catch (e) {
      console.error(e);
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const breadcrumbs = ref([]);
    const tabs = ref([
      {label: 'Описание', component: 'CategorySegmentDescriptionTabView'},
      {label: 'Тарифы', component: 'CategorySegmentTariffsView'},
      {label: 'Услуги', component: 'CategorySegmentServicesTabView'},
    ]);
    const errors = ref(null)
    const activeTabIndex = ref(0);
    const activeTabComponent = computed(() => tabs.value[activeTabIndex.value]);
    const segment = computed(() => store.getters.getCurrentBuildingSegment);
    const building = computed(() => store.getters.getCurrentBuilding);
    const category = computed(() => store.getters.getCurrentRoomCategory);
    const inclusions = computed(() => store.getters.getListInclusions);
    const localPhoto = computed(() => store.getters.getRoomCategoryPhotos);
    const deletePhoto = computed(() => store.getters.getRoomCategoryDeletePhotos);

    const form = ref(null);
    const updateForm = async () => {
      form.value = {
        ...category.value,
        has_guests: !!category.value.has_guests,
        inclusions: inclusions.value.map(inclusion => {
          const find = category.value.inclusions.find(categoryInclusion => categoryInclusion.id === inclusion.id);
          return {...inclusion, include: !!find};
        })

      }
    }
    const isUpdate = ref(false)
    const updateRoomCategory = async () => {
      errors.value = null
      const prepareData = {...form.value}
      prepareData.area_missing = form.value.area_missing ? 1 : 0
      prepareData.capacity_missing = form.value.capacity_missing ? 1 : 0
      prepareData.has_guests = form.value.has_guests ? 1 : 0

      delete prepareData.id
      delete prepareData.segment_id
      delete prepareData.ext_id
      delete prepareData.common_photos
      delete prepareData.plan_photos
      delete prepareData.places_photos
      const formData = new FormData()
      for (const key in prepareData) {
        if(key === 'inclusions') {
          prepareData.inclusions.forEach(item => {
            if (item.include) {
              formData.append('inclusions[]', item.id)
            }
          })
        } else {
          formData.append(key,prepareData[key])
        }
      }
      if (localPhoto.value.common.length) {
        localPhoto.value.common.forEach(item => formData.append('common_photos[]', item))
      }
      if (localPhoto.value.plan.length) {
        localPhoto.value.plan.forEach(item => formData.append('plan_photos[]', item))
      }
      if (localPhoto.value.places.length) {
        localPhoto.value.places.forEach(item => formData.append('places_photos[]', item))
      }
      if (deletePhoto.value.length) {
        for(const item in deletePhoto.value) {
          try {
            await store.dispatch('fetchDeleteRoomCategoryPhoto', {
              segmentId: route.params.segmentId,
              roomCategoryId: route.params.categoryId,
              body: {uuid: deletePhoto.value[item]}
            })
          } catch (e) {
            errors.value = e.response.data.data
          }
        }
      }
      try {
        await store.dispatch('fetchUpdateRoomCategory', {
          segmentId: route.params.segmentId,
          roomCategoryId: route.params.categoryId,
          body: formData
        })
        store.commit('deleteLocalRoomCategoryPhotos')
        await updateForm()
        await router.push({name: 'building-segment-edit', params: {id: route.params.id, segment: route.params.segmentId}})
      } catch (e) {
        errors.value = e.response.data.data
      }
    }

    watch(() => [category.value, form.value], () => isUpdate.value = false, {deep: true})

    onBeforeMount(() => {
      updateForm();
      breadcrumbs.value = [
        {label: 'Структура', router: {name: 'buildings'}},
        {label: building.value.name_ru, router: {name: 'building-edit', id: building.value.id}},
        {label: segment.value.name_ru, router: {name: 'building-segment-edit', params: {id: building.value.id, segment: segment.value.id}}},
        {label: category.value.name_ru}
      ]
    });

    return {
      category,
      breadcrumbs,
      tabs,
      activeTabIndex,
      activeTabComponent,
      inclusions,
      form,
      errors,
      localPhoto,
      updateRoomCategory,
    };
  }
})
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/" />
      <Button @click="updateRoomCategory" label="Сохранить изменения" style="maxHeight: 50px" class="btn-primary font-light" />
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="flex">
      <TabMenu v-model:active-index="activeTabIndex" :model="tabs" />
    </div>
  </section>

  <template v-if="activeTabComponent.component === 'CategorySegmentTariffsView'">
    <CategorySegmentTariffsView />
  </template>

  <template v-if="activeTabComponent.component === 'CategorySegmentDescriptionTabView'">
    <CategorySegmentDescriptionTabView
        :form-data="form"
        :inclusions="inclusions"
        :errors="errors"
        :photos="localPhoto"
    />
  </template>

  <template v-if="activeTabComponent.component === 'CategorySegmentServicesTabView'">
    <CategorySegmentServicesTabView />
  </template>

</template>

<style lang="scss" scoped>
.p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
  top: -1rem;
}
</style>