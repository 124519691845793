<script>
import {computed, defineComponent, ref, watch} from "vue";
import Card from "primevue/card";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import Textarea from 'primevue/textarea';
import {useVuelidate} from "@vuelidate/core";
import {required} from "@/utils/i18n-validators";
import {useI18n} from "vue-i18n";

export default defineComponent({
  components: {
    ButtonFileUpload,
    Card,
    Textarea,
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    inputTitle: {
      type: String,
      required: true,
    },
    imgTitle: {
      type: String,
      required: false,
    },
    rows: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    isIcon: {
      type: Boolean,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
    validError: {
      type: Boolean,
      required: false,
    }
  },
  emits: ['deleteSlide', 'changeSlide'],
  setup(props, {emit}) {
    const {t} = useI18n()
    const form = ref({...props.slide})
    const formRules = {
      photo: {required},
      text_ru: {required},
    }
    const buttonText = computed(() => {
      switch (props.isIcon) {
        case true:
          return form.value.photo ? 'Сменить иконку' : 'Добавить иконку'
        default:
          return form.value.photo ? 'Заменить фотографию' : 'Добавить фотографию'
      }
    })
    const v$ = useVuelidate(formRules, form.value, { $scope: props.scope });

    const deleteSlide = () => {
      emit('deleteSlide', props.index)
    }
    const loadFileMemory = (data) => {
      form.value.photo = data.files[0]
    }

    watch(() => form.value,
        () => {
          emit('changeSlide', form.value, props.index)
        }, { deep: true }
    )
    watch(() => props.validError,
        async () => {
          if (props.validError)
            await v$.value.$validate();
        })
    return {
      form,
      v$,
      t,
      buttonText,
      deleteSlide,
      loadFileMemory,
    };
  }
});
</script>

<template>
  <Card>
    <template #content>
      <div class="flex justify-content-between">
        <h3>{{title}} {{index + 1}}</h3>
        <i @click="deleteSlide" class="pi pi-times cansel-button cursor-pointer" style="font-size: 1rem"></i>
      </div>
      <div class="w-full mt-4">
          <span class="p-float-label w-full">
            <Textarea
                v-model="form.text_ru"
                :class="['w-full', 'p-inputtext', {'p-invalid': v$.text_ru.$errors.length || errors?.text_ru}]"
                :rows="rows"
                id="title"
            />
            <label for="title">{{inputTitle}} <i style="color: #E26B6B">*</i></label>
            </span>
        <span v-if="v$.text_ru.$errors.length || errors?.text_ru" class="text-xs color-error">
            {{ errors?.text_ru ? errors.text_ru[0] : t(v$.text_ru.$errors[0].$message) }}
          </span>
      </div>
      <div class="flex flex-column mt-2 gap-2">
        <h3>{{ imgTitle ? imgTitle : 'Изображение' }} <i style="color: #E26B6B">*</i></h3>
        <span  v-if="v$.photo.$errors.length || errors?.photo" class="text-xs color-error">
              {{ errors?.photo ? errors.photo[0] : t(v$.photo.$errors[0].$message) }}
          </span>
        <div v-if="!isIcon && form.photo">
          <div>
            <img width="226" height="226" :src="form.photo.preview_url ? form.photo.preview_url : form.photo.objectURL" />
          </div>
        </div>
        <div v-else>
          <div v-if="form.photo" class="card__wrapper">
            <img :src="form.photo.preview_url ? form.photo.preview_url : form.photo.objectURL" class="mr-2 h-2rem w-2rem" alt=""/>
            <span>{{ form.photo.name ? form.photo.name : form.photo.original_url.split('/').pop() }}</span>
          </div>
        </div>
        <div>
          <ButtonFileUpload
              @chooseFiles="loadFileMemory"
              :label="buttonText"
              :multiple="false"
              :clear-files-after-select="true"
              :classes="{'btn-fix-height brand__image': true}"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<style scoped>
.p-card {
  border-radius: 8px;
  box-shadow: none;
}

.p-card-title {
  font-size: 3rem !important;
}
.card__wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #DBD9D9;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  span {
    word-break: break-all;
  }
}
</style>
