import http from "@/plugins/axios";

const checkInInfoApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/check-in/condition', { signal: abortController?.signal })
    return data
}

const oneTimeInfoApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/one-time/description', { signal: abortController?.signal })
    return data
}

const updateCheckInInfoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/check-in/condition',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const updateOneTimeInfoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/one-time/description',
        { ...params },
        { signal: abortController?.signal })
    return data
}

export {
    checkInInfoApiCall,
    oneTimeInfoApiCall,
    updateCheckInInfoApiCall,
    updateOneTimeInfoApiCall
}