<script>
import {defineComponent, ref, watch, unref} from "vue";

import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import Button from "primevue/button";
import FileUpload from "primevue/fileupload";
import InputMask from "primevue/inputmask";
import Breadcrumb from "@/components/Breadcrumb.vue";
import MainCard from "@/components/cards/MainCard.vue";
import ImageCard from "@/components/cards/ImageCard";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload";
import DatePicker from "@/components/DatePicker";
import Checkbox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import {useServices} from "@/hooks/services";
// import {useBanks} from "@/hooks/banks";
import {useServiceCategory} from "@/hooks/service-category";
import {useI18n} from "vue-i18n";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    Breadcrumb, MainCard, Dropdown, InputText, Editor, Button, FileUpload, ImageCard,
    ButtonSuccess, ButtonFileUpload, DatePicker, Checkbox, Calendar, InputMask
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const {loadService} = useServices();
      const {loadServiceCategories} = useServiceCategory();
      // const {loadBanks} = useBanks();

      await loadService(to.params.id);
      await loadServiceCategories();
      // await loadBanks();
      next();
    } catch (e) {
      console.error(e);
    }
  },
  setup() {
    const {t} = useI18n()
    const {
      form, subServiceForm, intervalForm, isUpdate, service,
      changeDays,
      addSubService,
      updateService,
      destroyService,
      destroySubService,
      destroyFileLocal,
      selectFiles,
      deletePhotoAction,
      files,
      v$, sv$, iv$,
    } = useServices();
    const {listCategory} = useServiceCategory();
    const errors = ref(null)
    // const {banks, selectBank, acquiring, loadAcquiring} = useBanks();
    form.value = unref(service);
    form.value.has_persons = Boolean(form.value.has_persons);
    form.value.has_intervals = Boolean(form.value.has_intervals);
    form.value.has_persons = Boolean(form.value.has_persons);
    form.value.has_date = Boolean(form.value.has_date);
    form.value.days = form.value?.days ?? [];
    if (service.value.category) {
      form.value.service_category_id = service.value.category.id;
    }

    const normalizeTime = (time) => {
      const regex = /^\b[0-2]?\d:[0-5]\d\b$/;
      let date = '';
      if (regex.test(time)) {
        const [h, m] = time.split(':');
        date = new Date();
        date.setHours(h);
        date.setMinutes(m);
      }

      return date;
    }

    if (service.value.intervals && service.value.intervals !== "null") {
      let {end, start, duration} = JSON.parse(service.value.intervals);
      if (!(end && start && duration)) {
        intervalForm.value = {end: '', start: '', duration: ''};
      } else {
        end = normalizeTime(end);
        start = normalizeTime(start);
        duration = duration.length === 4 ? `0${duration}` : duration;
        intervalForm.value = {end, start, duration};
      }
    }

    const serviceTypes = [
      {label: 'Штучная', value: 'single'},
      {label: 'Пакет услуг', value: 'package'},
      {label: 'Разовый платеж', value: 'one_time'}
    ];

    const costTypes = [
      {value: 'common', label: 'Единая'},
      {value: 'inherited', label: 'Указывается в категории'},
      {value: 'calculated', label: 'Оценивается в процессе'},
      {value: 'sum', label: 'Основывается на вложенных услугах'}
    ];

    // TODO: Пофиксить мерджинг дат
    const datePickers = ref([
      {day: 0, label: 'ПН', pick: false},
      {day: 1, label: 'ВТ', pick: false},
      {day: 2, label: 'СР', pick: false},
      {day: 3, label: 'ЧТ', pick: false},
      {day: 4, label: 'ПТ', pick: false},
      {day: 5, label: 'СБ', pick: false},
      {day: 6, label: 'ВС', pick: false},
    ]);

    const breadcrumbs = ref([
      {label: 'Услуги', router: {name: 'services-list', params: {type: 'single'}}},
      {label: service.value.name_ru}
    ]);


    // watch(() => selectBank.value, async () => await loadAcquiring());

    const update = async () => {
      errors.value = null
      try {
        await updateService();
      } catch (e) {
        if (e.response?.data?.data) {
          errors.value = e.response.data.data
        }
        console.log(e)
      }
    };

    const deletePhoto = (uuid) => {
      deletePhotoAction(uuid)
    }

    watch(() => [form.value, files.value, intervalForm.value], () => {
          isUpdate.value = false
        },
        {deep: true}
    )

    return {
      isUpdate, listCategory,
      // banks, acquiring, selectBank,
      breadcrumbs, form, service,
      intervalForm, subServiceForm, serviceTypes, costTypes,
      addSubService, destroySubService, destroyService, update, destroyFileLocal, selectFiles, changeDays,
      files, datePickers,
      v$, sv$, iv$, deletePhoto, t, errors,
    };
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>

      <ButtonSuccess v-if="isUpdate" label="Услуга обновлена"/>
      <Button v-else @click="update" label="Обновить услугу" class="btn-primary font-light"/>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="grid h-max">
      <div class="col-12 md:col-6">
        <MainCard :styles="{'h-full': true}" title="Наименование услуги">
          <div class="flex flex-column gap-3">
            <div class="w-full mb-3">
              <span class="p-float-label w-full">
                <InputText :class="{'p-invalid': v$.name_ru.$errors.length} || errors?.name_ru" v-model="form.name_ru"
                           id="last_name"
                           class="w-full"/>
                <label for="last_name">Введите наименование</label>
              </span>
              <span v-if="v$.name_ru.$errors.length || errors?.name_ru" class="text-xs color-error">
                {{ errors?.name_ru ? errors.name_ru[0] : t(v$.name_ru.$errors[0].$message) }}
              </span>
            </div>

            <div class="w-full">
              <Dropdown
                  v-model="form.type"
                  :options="serviceTypes"
                  :class="{'p-invalid': v$.type.$errors.length || errors?.type}"
                  optionLabel="label"
                  optionValue="value"
                  placeholder="Тип услуги"
                  class="w-full"
              />
              <span v-if="v$.type.$errors.length || errors?.type" class="text-xs color-error">
                {{ errors?.type ? errors.type[0] : t(v$.type.$errors[0].$message) }}
              </span>
            </div>
            <div class="w-full">
              <Dropdown
                  v-model="form.service_category_id"
                  :value="form.service_category_id"
                  :class="{'p-invalid': v$.service_category_id.$errors.length || errors?.service_category_id}"
                  :options="listCategory?.data?.data ?? []"
                  optionLabel="name_ru"
                  optionValue="id"
                  placeholder="Категория услуги"
                  class="w-full"
              />
              <span v-if="v$.service_category_id.$errors.length || errors?.service_category_id"
                    class="text-xs color-error">
                {{
                  errors?.service_category_id ? errors.service_category_id[0] : t(v$.service_category_id.$errors[0].$message)
                }}
            </span>
            </div>
          </div>
        </MainCard>
      </div>
      <div class="col-12 md:col-6">
        <MainCard :styles="{'h-full': true}" title="Стоимость услуги">
          <div class="flex flex-column gap-3">
            <div class="w-full mb-3">
              <span class="p-float-label w-full">
                <InputText
                    v-model="form.cost"
                    :class="{'p-invalid': v$.cost.$errors.length || errors?.cost}"
                    id="last_name"
                    class="w-full"
                />
                <label for="last_name">Укажите стоимость, Р</label>
              </span>
              <span v-if="v$.cost.$errors.length || errors?.cost" class="text-xs color-error">
                {{ errors?.cost ? errors.cost[0] : t(v$.cost.$errors[0].$message) }}
              </span>
            </div>

<!--            <div class="w-full">-->
<!--              <Dropdown-->
<!--                  v-model="form.cost_type"-->
<!--                  :class="{'p-invalid': v$.cost_type.$errors.length || errors?.cost_type}"-->
<!--                  :options="costTypes"-->
<!--                  optionLabel="label"-->
<!--                  optionValue="value"-->
<!--                  placeholder="Тип стоимости"-->
<!--                  class="w-full"-->
<!--              />-->
<!--              <span v-if="v$.cost_type.$errors.length || errors?.cost_type" class="text-xs color-error">-->
<!--                {{ errors?.cost_type ? errors.cost_type[0] : t(v$.cost_type.$errors[0].$message) }}-->
<!--              </span>-->
<!--            </div>-->
          </div>
        </MainCard>
      </div>
      <!--      <div class="col-12 md:col-4">-->
      <!--        <MainCard :styles="{'h-full': true}" title="Реквизиты и эквайринг">-->
      <!--          <div class="flex flex-column gap-3">-->
      <!--            <Dropdown-->
      <!--                v-model="selectBank"-->
      <!--                :options="banks"-->
      <!--                optionLabel="name"-->
      <!--                optionValue="id"-->
      <!--                placeholder="Банк"-->
      <!--                class="w-full"-->
      <!--            />-->

      <!--            <Dropdown-->
      <!--                v-model="form.acquiring"-->
      <!--                :options="acquiring"-->
      <!--                optionLabel="identifier"-->
      <!--                optionValue="id"-->
      <!--                placeholder="Идентификатор эквайринга"-->
      <!--                class="w-full"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </MainCard>-->
      <!--      </div>-->
    </div>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Описание услуги">
      <Editor v-model="form.description_ru" class="w-full"></Editor>
      <span v-if="v$.description_ru.$errors.length || errors?.description_ru" class="text-xs color-error">
        {{ errors?.description_ru ? errors.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}
      </span>
    </MainCard>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Количество персон">
      <!--      <div class="flex w-100">-->
      <!--        <span class="p-float-label mb-3 w-full">-->
      <!--          <InputText v-model="form.persons" id="persons" class="w-full" :disabled="form.has_persons"/>-->
      <!--          <label for="persons">Количество персон</label>-->
      <!--        </span>-->
      <!--      </div>-->
      <Checkbox
          v-model="form.has_persons"
          class="mt-2"
          name="persons"
          binary
      />
      <label class="ml-2" for="persons">Оказание услуг требуется указания количества персон</label>
    </MainCard>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Изображения">
      <span v-if="errors?.photo" class="flex mb-2 text-xs color-error">
        {{ errors.photo[0] }}
      </span>
      <div class="grid">
        <div class="col-12 md:col-2" v-for="(file, i) in form.photos" :key="i">
          <ImageCard :src="file.preview_url" :handle="() => deletePhoto(file.uuid)"/>
        </div>
        <div class="col-12 md:col-2" v-for="(file, i) in files.photos" :key="i">
          <ImageCard :src="file.objectURL" :handle="() => destroyFileLocal(file)"/>
        </div>
        <div class="col-12">
          <ButtonFileUpload
              @chooseFiles="selectFiles"
              label="Добавить изображения"
              :clear-files-after-select="true"
              :multiple="true"
          />
        </div>
      </div>
    </MainCard>
  </section>

  <section class="py-2 mb-3">
    <div class="grid ">
      <div class="col-12 md:col-8">
        <MainCard title="Вложенные услуги">
          <template v-for="(subservice, i) in form.subservices" :key="i">
            <div class="grid mb-2">
              <div class="col-6">
                <span class="p-float-label mb-3 w-full">
                  <InputText v-model="form.subservices[i].name" id="subservice_name" class="w-full"/>
                  <label for="subservice_name">Введите наименование</label>
                </span>
              </div>

              <div class="col-6 flex">
                <span class="p-float-label mb-3 w-full">
                  <InputText v-model="form.subservices[i].cost" id="subservice_cost" class="w-full"/>
                  <label for="subservice_cost">Укажите стоимость, Р</label>
                </span>

                <Button
                    @click="destroySubService(subservice)"
                    class="ml-2"
                    icon="pi pi-times"
                    severity="danger"
                    text
                    rounded aria-label="Cancel"
                />
              </div>
            </div>
          </template>

          <div class="grid mb-2">
            <div class="col-6">
              <span class="p-float-label w-full">
                <InputText :class="{'p-invalid': sv$.name.$errors.length}" v-model="subServiceForm.name"
                           id="subservice_name" class="w-full"/>
                <label for="subservice_name">Введите наименование</label>
              </span>
              <span v-if="sv$.name.$errors.length" class="text-xs color-error">
                {{ t(sv$.name.$errors[0].$message) }}
              </span>
            </div>

            <div class="col-6">
              <span class="p-float-label w-full">
                <InputText :class="{'p-invalid': sv$.name.$errors.length}" v-model="subServiceForm.cost"
                           id="subservice_cost" class="w-full"/>
                <label for="subservice_cost">Укажите стоимость, Р</label>
              </span>
              <span v-if="sv$.cost.$errors.length" class="text-xs color-error">
                {{ t(sv$.cost.$errors[0].$message) }}
              </span>
            </div>
          </div>

          <Button @click="addSubService" label="Добавить" class="btn-primary"/>
        </MainCard>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <h2 class="mb-4">Интервалы оказания услуг</h2>

    <div class="grid h-max">
      <div class="col-12 md:col-6 h-full">
        <MainCard :styles="{'h-full': true}" title="Дни оказания услуги">
          <!--          <DatePicker @togglePick="changeDays" :items="datePickers"/>-->
          <Checkbox
              v-model="form.has_date"
              class="mt-2"
              name="interval_day"
              binary
          />
          <label class="ml-2" for="interval_day">Есть ограничения по дням</label>
        </MainCard>
      </div>
      <div class="col-12 md:col-6">
        <MainCard title="Период оказания услуги">
          <span v-if="iv$.start.$errors.length" class="flex mb-2 text-xs color-error">
            {{ t(iv$.start.$errors[0].$message) }}: От
          </span>
          <span v-if="(errors && errors['intervals.start'])" class="flex mb-2 text-xs color-error">
            {{ errors['intervals.start'][0] }}
          </span>
          <span v-if="iv$.end.$errors.length" class="flex mb-2 text-xs color-error">
            {{ t(iv$.end.$errors[0].$message) }}: До
          </span>
          <span v-if="(errors && errors['intervals.end'])" class="flex mb-2 text-xs color-error">
            {{ errors['intervals.end'][0] }}
          </span>
          <span v-if="iv$.duration.$errors.length" class="flex mb-2 text-xs color-error">
            {{ t(iv$.duration.$errors[0].$message) }}: Продолжительность
          </span>
          <span v-if="(errors && errors['intervals.duration'])" class="flex mb-2 text-xs color-error">
            {{ errors['intervals.duration'][0] }}
          </span>
          <div class="flex w-100">
            <Calendar
                :disabled="!form.has_intervals"
                v-model="intervalForm.start"
                placeholder="От"
                timeOnly
                class="w-10rem"
            />
            <Calendar
                :disabled="!form.has_intervals"
                v-model="intervalForm.end"
                placeholder="До"
                timeOnly
                class="ml-2 w-10rem"/>
            <InputMask
                :disabled="!form.has_intervals"
                v-model="intervalForm.duration"
                mask="99:99"
                placeholder="Продолжительность"
                class="ml-2 w-12rem"/>
          </div>
          <span
              class="text-xs color-black-20 mb-1">Доступные интервалы: 08:00, 08:30, 09:00, 09:30, ... 18:30</span><br>
          <Checkbox
              v-model="form.has_intervals"
              class="mt-2"
              name="intervals"
              binary
              :trueValue="false"
              :falseValue="true"
          />
          <label class="ml-2" for="intervals">Оказание услуг не требует интервалов</label>
        </MainCard>
      </div>
    </div>
  </section>
</template>

<style>
.p-fileupload-content {
  display: none !important;
}

.p-fileupload-buttonbar {
  background: transparent !important;
  border: none !important;
}
</style>