import http from "../plugins/axios";

const agentInfoApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/landing/agent-info', { signal: abortController?.signal })
    return data
}

const updateAgentInfoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/agent-info',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const awardApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/landing/award', { signal: abortController?.signal })
    return data
}

const updateAwardApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/award',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const aboutBrandApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/about-brand/about-brand', { signal: abortController?.signal })
    return data
}

const updateAboutBrandApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/about-brand',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const corpClientApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/landing/corp-client', { signal: abortController?.signal })
    return data
}

const updateCorpClientApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/corp-client',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const contactApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/landing/contact', { signal: abortController?.signal })
    return data
}

const updateContactApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/contact',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const conceptApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/about-brand/conception',
        {
            signal: abortController?.signal,
        })
    return data
}

const updateConceptApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/conception',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    return data
}

const bannerApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/about-brand/main-banner',
        {
            signal: abortController?.signal,
        })
    return data
}

const updateBannerApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/main-banner',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    return data
}

const hotelInfoApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/landing/hotel-info',
        {
            signal: abortController?.signal,
        })
    return data
}

const updateHotelInfoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/hotel-info',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const uploadHotelInfoPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/hotel-info/photo/upload',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const deleteHotelInfoPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/hotel-info/photo/delete',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const sliderApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/landing/slider', { signal: abortController?.signal })
    return data
}

const addSliderItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/slider',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const updateSliderItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/landing/slider/${params.id}`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }})
    return data
}

const deleteSliderItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/landing/slider/${params.id}`,
        { signal: abortController?.signal })
    return data
}

const valuesApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/about-brand/value', { signal: abortController?.signal })
    return data
}

const addValuesApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/value',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const updateValuesApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/about-brand/value/${params.id}`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }})
    return data
}

const deleteValuesApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/about-brand/value/${params.id}`,
        { signal: abortController?.signal })
    return data
}

const advantageApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/about-brand/advantage', { signal: abortController?.signal })
    return data
}

const addAdvantageApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/advantage',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const updateAdvantageApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/about-brand/advantage/${params.id}`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }})
    return data
}

const deleteAdvantageApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/about-brand/advantage/${params.id}`,
        { signal: abortController?.signal })
    return data
}

const infrastructureApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/landing/infrastructure', { signal: abortController?.signal })
    return data
}

const addInfrastructureItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/infrastructure',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const updateInfrastructureItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/landing/infrastructure/${params.id}`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }})
    return data
}

const deleteInfrastructureItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/landing/infrastructure/${params.id}`,
        { signal: abortController?.signal })
    return data
}

const categoriesApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/landing/category', { signal: abortController?.signal })
    return data
}

const addCategoryApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/landing/category',
        {...params},
        {
            signal: abortController?.signal,
        })
    return data
}

const updateCategoryApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/landing/category/${params.id}`,
        {...params.fields},
        {
            signal: abortController?.signal,
        })
    return data
}

const deleteCategoryApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/landing/category/${params.id}`,
        { signal: abortController?.signal })
    return data
}

const addCategoryPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/landing/category/${params.id}/photo/upload`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }})
    return data
}

const deleteCategoryPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/landing/category/${params.id}/photo/delete`,
        {...params.fields},
        {
            signal: abortController?.signal,
        })
    return data
}

const galleryApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/gallery', { signal: abortController?.signal })
    return data
}

const addGalleryApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/gallery',
        {...params},
        {
            signal: abortController?.signal,
        })
    return data
}

const updateGalleryApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/gallery/${params.id}`,
        {...params.fields},
        {
            signal: abortController?.signal,
        })
    return data
}

const deleteGalleryApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/gallery/${params.id}`,
        { signal: abortController?.signal })
    return data
}

const addGalleryPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/gallery/${params.id}/photo/upload`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }})
    return data
}

const deleteGalleryPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/gallery/${params.id}/photo/delete`,
        {...params.fields},
        {
            signal: abortController?.signal,
        })
    return data
}

const branApartApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/about-brand/hotel-info',
        {
            signal: abortController?.signal,
        })
    return data
}

const updateBranApartApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/hotel-info',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const uploadBranApartPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/hotel-info/photo/upload',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const deleteBranApartPhotoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/hotel-info/photo/delete',
        { ...params },
        { signal: abortController?.signal })
    return data
}

const founderInfoApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/about-brand/founder-info',
        {
            signal: abortController?.signal,
        })
    return data
}
const updateFounderInfoApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/founder-info',
        {...params},
        {
            signal: abortController?.signal,
        })
    return data
}

const addFounderApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/about-brand/founder-info/founder',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const updateFounderApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/about-brand/founder-info/founder/${params.id}`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    return data
}

const deleteFounderApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/about-brand/founder-info/founder/${params.id}`,
        {
            signal: abortController?.signal,
        })
    return data
}

export {
    agentInfoApiCall,
    updateAgentInfoApiCall,
    hotelInfoApiCall,
    updateHotelInfoApiCall,
    uploadHotelInfoPhotoApiCall,
    deleteHotelInfoPhotoApiCall,
    categoriesApiCall,
    sliderApiCall,
    addSliderItemApiCall,
    updateSliderItemApiCall,
    deleteSliderItemApiCall,
    addCategoryApiCall,
    updateCategoryApiCall,
    deleteCategoryApiCall,
    addCategoryPhotoApiCall,
    deleteCategoryPhotoApiCall,
    infrastructureApiCall,
    addInfrastructureItemApiCall,
    updateInfrastructureItemApiCall,
    deleteInfrastructureItemApiCall,
    awardApiCall,
    updateAwardApiCall,
    conceptApiCall,
    updateConceptApiCall,
    corpClientApiCall,
    updateCorpClientApiCall,
    contactApiCall,
    updateContactApiCall,
    galleryApiCall,
    addGalleryPhotoApiCall,
    deleteGalleryPhotoApiCall,
    bannerApiCall,
    updateBannerApiCall,
    valuesApiCall,
    addValuesApiCall,
    updateValuesApiCall,
    deleteValuesApiCall,
    advantageApiCall,
    addAdvantageApiCall,
    updateAdvantageApiCall,
    deleteAdvantageApiCall,
    aboutBrandApiCall,
    updateAboutBrandApiCall,
    branApartApiCall,
    updateBranApartApiCall,
    uploadBranApartPhotoApiCall,
    deleteBranApartPhotoApiCall,
    founderInfoApiCall,
    updateFounderInfoApiCall,
    addFounderApiCall,
    updateFounderApiCall,
    deleteFounderApiCall,
    addGalleryApiCall,
    updateGalleryApiCall,
    deleteGalleryApiCall,
};