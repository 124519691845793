import http from "../plugins/axios";

const loadWebsiteLink = async () => {
    const response = await http.get('/api/company/v1/website-link');
    return response.data;
};
const updateWebsiteLink = async (data) => {
    const response = await http.post('/api/company/v1/website-link', data);
    return response.data;
};

export {loadWebsiteLink, updateWebsiteLink};