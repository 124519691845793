<script>
import {computed, defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useMeta} from "vue-meta";
import ProgressSpinner from 'primevue/progressspinner';
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Checkbox from "primevue/checkbox";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import StateConstructorTable from "@/components/tables/StateConstructorTable.vue";
import MainCard from "@/components/cards/MainCard.vue";
import InputText from "primevue/inputtext";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@/utils/i18n-validators";
import {useI18n} from "vue-i18n";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {MainCard, Button, StateConstructorTable, DataTable, Column, Checkbox, ButtonSuccess, InputText, ProgressSpinner},
  setup() {
    const {t} = useI18n();
    useMeta({
      title: 'Контруктор состояний'
    });
    const store = useStore();
    const linkSettings = reactive({
      description: '',
      link: '',
    })
    const linkSettingsRules = {description: {required}, link: {required}}
    const modelStates = ref([]);
    const modelStatesCopy = ref([]);
    const changesStates = reactive({});
    const isUpdated = ref(false);
    const isLoadingPage = ref(true)
    const isSaveLoading = ref(false)
    const states = computed(() => store.getters.getStateList);
    const permissions = computed(() => store.getters.getPermissionList);
    const websiteData = computed(() => store.getters.getWebsiteLink)
    const v$ = useVuelidate(linkSettingsRules, linkSettings);
    const errors = ref(null)

    const fillChangesState = (stateId, permission) => {
      if (!changesStates[stateId]) {
        changesStates[stateId] = {};
      }

      changesStates[stateId][permission.id] = {
        paid: permission.paid,
        permission: permission.id,
        access: permission.access,
      }
    }

    const fillModelStates = () => {
      for (let state of states.value) {
        modelStates.value.push({
          ...state,
          permissions: [...permissions.value.map(permission => {
            return {
              id: permission.id,
              paid: state.permissions.find(p => permission.id === p.id)?.paid ?? 0,
              access: !!state.permissions.find(p => permission.id === p.id),
            }
          })]
        });
      }
    };

    const findPermission = (state, permission) => {
      return modelStates.value.find(s => s.id === state).permissions
          .find(p => p.id === permission);
    };

    const changeModelStateAccess = ({state, permission}) => {
      const perm = findPermission(state, permission);
      perm.access = !perm.access;

      if (perm.paid && !perm.access) {
        perm.paid = false;
      }

      fillChangesState(state, perm);
    }

    const changeModelStatePaid = ({state, permission}) => {
      const perm = findPermission(state, permission);
      perm.paid = !perm.paid;

      if (perm.paid && !perm.access) {
        perm.access = true;
      }

      fillChangesState(state, perm);
    };

    const storeChanges = async () => {
      errors.value = null
      isSaveLoading.value = true
      const result = await v$.value.$validate();
      if (!result) {
        isSaveLoading.value = false
        return;
      }
      for (let stateId in changesStates) {
        for (let permissionId in changesStates[stateId]) {
          let body = {
            permission: permissionId,
            paid: Number(changesStates[stateId][permissionId].paid),
          };

          if (!Number(changesStates[stateId][permissionId].access)) {
            body.remove = 1;
          }
          await store.dispatch('fetchUpdateState', {
            id: stateId,
            body
          });
        }
      }
      try {
        await store.dispatch('fetchUpdateWebsiteLink', linkSettings);
      } catch (e) {
        if (e.response.data.data) {
          errors.value = {...e.response.data.data}
        } else {
          errors.value = e.response.data.errors
        }
      }
      isSaveLoading.value = false
      if (!errors.value) {
        isUpdated.value = true;
      }
    };

    const checkDisabled = (slot, state, type) => {
      if (state.code === 'controlled') {
        if (type === 'Платный') {
          return ['Доступные сервисы', 'Скидки от партнёров', 'Свободная заявка', 'Статус объекта', 'Добавить арендатора или агента'].includes(slot.name)
        } else {
          return ['Доступные сервисы', 'Добавить арендатора или агента'].includes(slot.name)
        }
      }
    }

    watch(() => [changesStates, linkSettings], () => isUpdated.value = false, {deep: true});

    onBeforeMount(async () => {
      try {
        await store.dispatch('fetchPermissions');
        await store.dispatch('fetchStateList');
        await store.dispatch(('fetchWebsiteLink'))
        fillModelStates();
        modelStatesCopy.value = modelStates.value;
        if (websiteData.value) {
          linkSettings.link = websiteData.value.link
          linkSettings.description = websiteData.value.description
        }
      } catch (e) {
        console.log(e)
      }
      isLoadingPage.value = false
    });

    return {
      checkDisabled,
      states,
      permissions,
      modelStates,
      changeModelStateAccess,
      changeModelStatePaid,
      changesStates,
      storeChanges,
      isUpdated,
      linkSettings,
      isLoadingPage,
      isSaveLoading,
      v$,
      errors,
      t,
    };
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <h1 class="font-normal">Конструктор состояний</h1>
      <div class="flex">
        <ButtonSuccess v-if="isUpdated" label="Изменения сохранены" />
        <Button @click="storeChanges" :loading="isSaveLoading" label="Сохранить изменения" class="btn-primary font-light ml-2"/>
      </div>
    </div>
  </section>
  <div v-if="isLoadingPage" class="spinner__wrapper">
    <ProgressSpinner />
  </div>
  <section v-else class="py-2 mb-3">
    <DataTable :value="permissions">
      <Column header="Функция / блок">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
      </Column>

      <template v-for="(state, i) in states" :key="state.id">
        <Column :field="state.code" :header="state.name">
          <!--          1 Permission-->
          <template #body="slotProps">
            <div class="flex align-items-center mb-2">
              <Checkbox
                  :disabled="checkDisabled(slotProps.data, state, 'Доступен')"
                  @click="changeModelStateAccess({ state: state.id, permission: slotProps.data.id })"
                  :model-value="modelStates.find(s => s.id === state.id)?.permissions?.find(p => p.id === slotProps.data.id)?.access"
                  :binary="true"
                  name="category"
              />
              <label class="ml-2" :for="slotProps.data.id">Доступен</label>
            </div>

            <div class="flex align-items-center mb-2">
              <Checkbox
                  :disabled="checkDisabled(slotProps.data, state, 'Платный')"
                  @click="changeModelStatePaid({ state: state.id, permission: slotProps.data.id })"
                  :model-value="!!modelStates.find(s => s.id === state.id)?.permissions?.find(p => p.id === slotProps.data.id)?.paid"
                  :binary="true"
                  name="category"
              />
              <label class="ml-2" :for="slotProps.data.id">Платный</label>
            </div>
          </template>
        </Column>
      </template>
    </DataTable>
  </section>
  <section v-if="!isLoadingPage">
      <MainCard title="Настройки сообщения" class="mb-3">
        <div class="flex gap-4">
          <div class="mb-2 mt-2 w-full">
            <span class="p-float-label">
              <InputText
                  v-model="linkSettings.description"
                  id="checking_account"
                  :class="['w-full', {'p-invalid': v$.description.$errors.length || errors?.description}]"
              />
              <label for="checking_account">{{t('website-link.input.description')}} <i style="color: #E26B6B">*</i></label>
            </span>
            <span v-if="v$.description.$errors.length || errors?.description" class="color-red text-xs">
                {{ errors ? errors.description[0] : t(v$.description.$errors[0].$message) }}
            </span>
          </div>
          <div class="mb-2 mt-2 w-full">
            <span class="p-float-label">
              <InputText
                  v-model="linkSettings.link"
                  id="checking_account"
                  :class="['w-full', {'p-invalid': v$.link.$errors.length || errors?.link}]"
              />
              <label for="checking_account">{{ t('website-link.input.link')}} <i style="color: #E26B6B">*</i></label>
            </span>
            <span v-if="v$.link.$errors.length || errors?.link" class="color-red text-xs">
                {{ errors ? errors.link[0] : t(v$.link.$errors[0].$message) }}
            </span>
          </div>
        </div>
      </MainCard>
  </section>
</template>

<style scoped lang="scss">
.spinner__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 500px;
}
::v-deep(.p-progress-spinner-circle) {
  stroke: var(--color-primary) !important;
}
</style>
