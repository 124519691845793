<script>
import {defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useApiCall} from "@/composables/useApiCall";
import {
  receiptApiCall,
  receiptUploadApiCall
} from "@/services/receipt";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import MainCard from "@/components/cards/MainCard";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import Button from "primevue/button";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    ButtonFileUpload,
    DataTable,
    Column,
    Paginator,
    MainCard,
    Button,
  },
  setup() {
    const {t} = useI18n();
    const errors = ref([])
    const first = ref(0)
    const currentType = ref(null)
    const form = reactive({
      fields: {
        meters: null,
        balance: null,
        receipt: null,
      }
    })
    const {
      data: contentData,
      isLoading: contentLoading,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(receiptApiCall, true)

    const {
      isLoading: isContentUploadLoading,
      data: contentUploadData,
      error: contentUploadError,
      executeApiCall: uploadContentAction,
    } = useApiCall(receiptUploadApiCall, true)

    const destroyFileMemory = (key) => {
      deleteError(key)
      form.fields[key] = null
    }

    const loadFileMemory = (data, index) => {
      switch (index) {
        case 0:
          form.fields.meters = data.files[0]
          break
        case 1:
          form.fields.balance = data.files[0]
          break
        default:
          form.fields.receipt = data.files[0]
          break
      }
    }

    watch((first), async (index) => {
      await changePage();
    });

    const changePage = async () => {
      await getContentAction({
        page: ((first.value / (contentData.value?.data?.pagination?.per_page ?? 1)) + 1),
      })
      window.scrollTo(0, 0);
    }

    const uploadFile = async (type) => {
      deleteError(type)
      currentType.value = type
      try {
        const formData = new FormData()
        formData.append('file', form.fields[type])
        await uploadContentAction({data: formData, type})
        if (contentUploadData.value) {
          form.fields[type] = null
          first.value = 0
          currentType.value = null
          await getContentAction()
        }
      } catch (e) {
        if (contentUploadError.value && contentUploadError.value.status === 500) {
          errors.value.push({ value: { file: ['Ошибка'] }, key: type })
        } else if (contentUploadError.value) {
          errors.value.push({ value: contentUploadError.value.data, key: type })
        }
      }
    }

    const deleteError = (type) => {
      errors.value = [...errors.value.filter(item => item.key !== type)]
    }

    const getError = (type) => {
      const error = errors.value.find(item => item.key === type)
      if (error) {
        return error.value.file[0]
      } else {
        return null
      }
    }

    const getDataType = (type) => {
      switch (type) {
        case 'meters':
          return t('receipt.type.meters')
        case 'balance':
          return t('receipt.type.balance')
        default:
          return t('receipt.type.receipt')
      }
    }

    const getFileName = (file) => {
      return file.split('/').pop()
    }

    onBeforeMount(async () => {
      try {
        await getContentAction()
      } catch (e) {
        errors.value = e
        console.error(e)
      }
    });

    return {
      t,
      form,
      first,
      destroyFileMemory,
      loadFileMemory,
      uploadFile,
      getDataType,
      getFileName,
      contentLoading,
      isContentUploadLoading,
      errors,
      contentData,
      currentType,
      getError,
    };
  }
});
</script>

<template>
  <section class="py-2 mb-3 receipt ">
    <span class="receipt__title flex mb-4">{{ t('receipt.addFile') }}</span>
    <div class="receipt__upload">
      <MainCard v-for="(value, key, i) in form.fields" :title="t(`receipt.type.${key}`)">
        <div class="flex flex-column gap-4">
          <span  v-if="getError(key)" class="text-xs color-error">
              {{ getError(key) }}
          </span>
          <div v-if="value" class="receipt__card">
            <img src="@/assets/images/icons/file-text.svg">
            <span>{{ value.name }}</span>
              <button @click="destroyFileMemory(key)" :disabled="isContentUploadLoading && currentType === key" class="pi pi-times cansel-button cursor-pointer ml-auto receipt__delete" style="font-size: 1rem"></button>
          </div>
          <div>
            <ButtonFileUpload
                v-if="!value"
                :index="i"
                :accept="key === 'receipt' ? '.pdf' : '.xlsx'"
                @chooseFiles="loadFileMemory"
                :isLoading="isContentUploadLoading && currentType === key"
                :label="t('receipt.button.add')"
                :multiple="false"
                :clear-files-after-select="true"
                :classes="{'btn-fix-height': true}"
            />
            <Button
                v-else
                class="btn-primary btn-fix-height font-light"
                :label="t('receipt.button.upload')"
                :disabled="isContentUploadLoading && currentType !== key"
                :loading="isContentUploadLoading && currentType === key"
                @click="uploadFile(key)"
            />
          </div>
        </div>
      </MainCard>
    </div>
  </section>
  <section class="mb-3 py-2 receipt">
    <span class="receipt__title flex mb-4">{{ t('receipt.title') }}</span>
      <DataTable :value="contentData?.data?.data ? contentData.data.data : []" :loading="contentLoading" showGridlines tableStyle="min-width: 50rem" class="receipt__table">
        <Column field="uploaded_by" :header="t('receipt.table.name')" class="text-center">
          <template #body="slotProps">
            <span>{{ slotProps.data.uploaded_by.last_name }} {{ slotProps.data.uploaded_by.first_name }} {{ slotProps.data.uploaded_by.patronymic }}</span>
          </template>
        </Column>
        <Column field="uploaded_at" :header="t('receipt.table.date')" class="text-center"/>
        <Column field="type" :header="t('receipt.table.type')" class="text-center">
          <template #body="slotProps">
            <span>{{ getDataType(slotProps.data.type) }}</span>
          </template>
        </Column>
        <Column field="file" :header="t('receipt.table.file')" class="text-center">
          <template #body="slotProps">
            <a :href="slotProps.data.file" target="_blank" class="receipt__data">
              <img src="@/assets/images/icons/file-text.svg">
              <span class="color-primary">{{ getFileName(slotProps.data.file) }}</span>
            </a>
          </template>
        </Column>
      </DataTable>
      <Paginator
          v-model:first="first"
          :rows="contentData?.data?.pagination?.per_page ?? 0"
          :totalRecords="contentData?.data?.pagination?.total ?? 0"
          class="justify-content-start"
      ></Paginator>
  </section>
</template>

<style lang="scss" scoped>
.receipt {
  &__delete {
    border: none;
    background: transparent;
  }
  &__card {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    min-height: 48px;
    border-radius: 4px;
    border: 1px solid var(--color-black-20);
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

  }
  &__upload {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
    word-break: break-all;
    @media screen and (min-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
    ::v-deep(.p-card-title) {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      text-align: left;
    }
    ::v-deep(.btn-fix-height) {
      min-width: 208px;
      font-size: 1rem;
    }
  }
  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }
  &__data {
    display: flex;
    gap: 10px;
    word-break: break-all;
    text-decoration: unset;
    text-align: left;
  }
  &__table {
    ::v-deep(.p-datatable-table ) {
      th {
        width: 25%;
      }
      .p-column-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 21.6px;
        text-align: center;
      }
    }
  }
}
::v-deep(.p-component-overlay) {
  background-color: rgba(255, 255, 255, 0.6);
}
::v-deep(.p-datatable-loading-icon) {
  path {
    fill: var(--color-primary);
  }
}
</style>