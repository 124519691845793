<script>
import {computed, defineComponent, onBeforeMount, reactive, ref} from "vue";
import {useStore} from "vuex";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import Dropdown from "primevue/dropdown";
import MainCard from "@/components/cards/MainCard";
import Breadcrumb from "@/components/Breadcrumb";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Skeleton from "primevue/skeleton"
import Calendar from "primevue/calendar";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {required} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";
import {useApiCall} from "@/composables/useApiCall";
import {
  addArticlesApiCall,
  articlesItemApiCall, deleteArticlesPhotoApiCall,
  updateArticlesApiCall,
  uploadArticlesPhotoApiCall,
  deleteArticlesItemApiCall,
} from "@/services/articles";


export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    ButtonSuccess,
    Button,
    InputText,
    MainCard,
    Calendar,
    Breadcrumb,
    Editor,
    ButtonFileUpload,
    ImageCard,
    ConfirmationModal,
    Skeleton,
    Dropdown,
  },
  setup() {
    const {t} = useI18n();
    const store = useStore();
    const router = useRouter()
    const route = useRoute()
    const errors = ref(null)
    const breadcrumbs = ref([]);
    const form = reactive({
      fields: {
        id: '',
        title_ru: '',
        date: null,
        text_ru: '',
        photos: [],
        type: '',
      }
    })
    const rules = {
      title_ru: {required},
      text_ru: {required},
      date: {required},
      type: {required}
    };
    const deletePhotos = ref([])
    const v$ = useVuelidate(rules, form.fields);
    const visibleModal = ref(false);
    const isContentLoading = ref(true)
    const isUpdateLoading = computed(() =>
        isContentUpdateLoading.value ||
        isContentAddLoading.value ||
        isContentAddPhotoLoading.value ||
        isContentDeletePhotoLoading.value
    )

    const typeForms = [{label: 'Новость', value: 'news'}, {label: 'Акция', value: 'promotions'}]
    const {
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(articlesItemApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateArticlesApiCall, true)

    const {
      isLoading: isContentAddLoading,
      data: contentAddData,
      error: contentAddError,
      executeApiCall: addContentAction,
    } = useApiCall(addArticlesApiCall, true)

    const {
      isLoading: isContentDeleteLoading,
      data: contentDeleteData,
      error: contentDeleteError,
      executeApiCall: deleteContentAction,
    } = useApiCall(deleteArticlesItemApiCall, true)

    const {
      isLoading: isContentAddPhotoLoading,
      data: contentAddPhotoData,
      error: contentAddPhotoError,
      executeApiCall: addContentPhotoAction,
    } = useApiCall(uploadArticlesPhotoApiCall, true)

    const {
      isLoading: isContentDeletePhotoLoading,
      data: contentDeletePhotoData,
      error: contentDeletePhotoError,
      executeApiCall: deleteContentPhotoAction,
    } = useApiCall(deleteArticlesPhotoApiCall, true)

    const changeConfirmationStateModal = () => {
      visibleModal.value = !visibleModal.value
      store.dispatch('changeStateModal', {
        type: 'confirmationDestroyPartnerModal',
        bool: visibleModal.value
      });
    };

    const toggleDestroyPartner = async () => {
      try {
        await deleteContentAction({id: route.params.id});
        if (contentDeleteData.value) {
          await router.push({name: 'website', query: {tab: 'news'}});
        }
      } catch (e) {
        console.error(e);
      }
    };
    const destroyFileMemory = (file) => {
      if (file.uuid) {
        deletePhotos.value.push(file.uuid)
      }
      form.fields.photos = form.fields.photos.filter(item => item !== file)
    }
    const loadFileMemory = (data) => {
      form.fields.photos = form.fields.photos.concat(data.files)
    }

    const update = async () => {
      const result = await v$.value.$validate()
      errors.value = null
      if (result) {
        try {
          if (route.params.id) {
            await updateContentAction({
              fields: {
                title_ru: form.fields.title_ru,
                text_ru: form.fields.text_ru,
                date: form.fields.date.length ? form.fields.date : form.fields.date.toLocaleDateString(),
                type: form.fields.type,
              },
              id: route.params.id,
            })
          } else {
            await addContentAction({
              title_ru: form.fields.title_ru,
              text_ru: form.fields.text_ru,
              date: form.fields.date.toLocaleDateString(),
              type: form.fields.type,
            })
          }
          if (contentUpdateData.value || contentAddData.value) {
            if (form.fields.photos.length) {
              for (let i = 0; i < form.fields.photos.length; i += 1) {
                if (form.fields.photos[i].objectURL) {
                  try {
                    const formData = new FormData()
                    formData.append('photo', form.fields.photos[i])
                    await addContentPhotoAction({
                      id: route.params.id ? route.params.id : contentAddData.value.data.id,
                      fields: formData,
                    })
                  } catch (e) {
                    if (contentAddPhotoError.value) {
                      console.log(contentAddPhotoError.value.data)
                      errors.value = {error: contentAddPhotoError.value.data, index: form.fields.photos[i].objectURL}
                      console.log(errors.value)
                    }
                    console.error(e)
                    return
                  }
                }
              }
              if (deletePhotos.value.length) {
                for (let i = 0; i < deletePhotos.value.length; i += 1) {
                  try {
                    await deleteContentPhotoAction({
                      id: route.params.id ? route.params.id : contentAddData.value.data.id,
                      uuid: deletePhotos.value[i],
                    })
                  } catch (e) {
                    console.error(e)
                    return
                  }
                }
              }
            }
            await router.push({name: 'website', query: {tab: 'news'}})
          }
        } catch (e) {
          console.error(e)
          if (contentUpdateError.value) {
            errors.value = contentUpdateError.value.data
          }
          if (contentAddError.value) {
            errors.value = contentAddError.value.data
          }
          return
        }
      }
    }

    onBeforeMount(async () => {
      if (route.params.id) {
        try {
          await getContentAction({id: route.params.id})
          if (contentData.value) {
            form.fields.title_ru = contentData.value.data.title_ru
            form.fields.text_ru = contentData.value.data.text_ru
            form.fields.date = contentData.value.data.date
            form.fields.type = contentData.value.data.type
            form.fields.photos = contentData.value.data.photos
          }
        } catch (e) {
          console.error(e)
        }
      }
      breadcrumbs.value = [
        {label: 'Сайт / Акции и новости', router: {name: 'website', query: {tab: 'news'}}},
        {label: contentData.value ? `Новость ${contentData.value.data.date}` : 'Создание новости'}
      ];
      isContentLoading.value = false
    });

    return {
      t,
      form,
      breadcrumbs,
      route,
      update,
      destroyFileMemory,
      loadFileMemory,
      v$,
      errors,
      visibleModal,
      isContentDeleteLoading,
      isContentLoading,
      toggleDestroyPartner,
      changeConfirmationStateModal,
      isUpdateLoading,
      typeForms,
    };
  }
});
</script>

<template>
  <ConfirmationModal v-model:visible="visibleModal">
    <template #header>
      Удалить новость
    </template>

    <template #default>
      <span class="flex mt-2">Вы уверенны, что хотите удалить новость?</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button :label="t('labels.cancel')" @click="changeConfirmationStateModal"
                class="btn-primary-outlined font-light w-12"/>
        <Button :label="t('labels.destroy')" :loading="isContentDeleteLoading" @click="toggleDestroyPartner"
                class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>
      <div class="flex">
        <Button
            @click="update"
            :loading="isUpdateLoading"
            :label="route.params.id ? 'Сохранить изменения' : 'Создать'"
            :class="['btn-primary btn-fix-height font-light ml-3', {'px-8': !route.params.id}]"
        />
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <Skeleton v-if="isContentLoading" height="154px"></Skeleton>
    <MainCard v-else title="Заголовок">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  :class="{'p-invalid': v$.title_ru.$errors?.length || errors?.title_ru }"
                  id="name_ru"
                  class="w-full"
                  v-model="form.fields.title_ru"
              />
              <label for="name_ru">Введите заголовок <i style="color: #E26B6B">*</i></label>
            </span>
      <span v-if="v$.title_ru.$errors?.length || errors?.title_ru"
            class="color-error">{{ errors?.title_ru ? errors.title_ru[0] : t(v$.title_ru.$errors[0].$message) }}</span>
    </MainCard>
  </section>

  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-6">
        <Skeleton v-if="isContentLoading" height="154px"></Skeleton>
        <MainCard v-else title="Тип">
              <span class="p-float-label w-full mb-3">
                      <Dropdown
                          v-model="form.fields.type"
                          :class="{'p-invalid': v$.type.$errors?.length || errors?.type}"
                          :options="typeForms"
                          optionLabel="label"
                          optionValue="value"
                          class="w-full">
                      </Dropdown>
                     <label for="legal_form">Тип <i style="color: #E26B6B">*</i></label>
                  </span>
          <span v-if="v$.type.$errors?.length || errors?.type" class="text-xs color-error">
                    {{ errors?.type ? errors.type[0] : t(v$.type.$errors[0].$message) }}
                  </span>
        </MainCard>
      </div>
      <div class="col-6">
        <Skeleton v-if="isContentLoading" height="154px"></Skeleton>
        <MainCard v-else title="Дата">
            <span class="p-float-label mb-3">
              <Calendar
                  v-model="form.fields.date"
                  inputId="date"
                  dateFormat="dd.mm.yy"
                  :class="['w-full', '--calendar', {'p-invalid': v$.date.$errors?.length || errors?.date}]"
              />
              <label for="date">Укажите дату</label>
            </span>
          <span v-if="v$.date.$errors?.length || errors?.date"
                class="color-error">{{ errors?.date ? errors.date[0] : t(v$.date.$errors[0].$message) }}</span>
        </MainCard>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <Skeleton v-if="isContentLoading" height="195px"></Skeleton>
    <MainCard v-else title="Описание">
      <div class="grid">
        <div class="col-12">
          <Editor v-model="form.fields.text_ru" class="w-full"></Editor>
          <span v-if="v$.text_ru.$errors?.length || errors?.text_ru"
                class="color-error">{{ errors?.text_ru ? errors.text_ru[0] : t(v$.text_ru.$errors[0].$message) }}</span>
        </div>
      </div>
    </MainCard>
  </section>

  <section class="py-2 mb-3">
    <Skeleton v-if="isContentLoading" height="155px"></Skeleton>
    <MainCard v-else title="Добавьте изображения при необходимости">
      <div class="grid">
        <template v-if="form.fields.photos.length">
          <div v-for="(file, i) in form.fields.photos" :key="i" class="col-12 md:col-2">
            <ImageCard :src="file.uuid ? file.preview_url : file.objectURL" :handle="() => destroyFileMemory(file)"/>
            <span v-if="errors?.error?.photo.length && errors?.index === file.objectURL"
                  class="flex text-xs color-error mb-2">
              {{ errors.error.photo[0] }}
            </span>
          </div>
        </template>

        <div class="col-12">
          <ButtonFileUpload @chooseFiles="loadFileMemory" label="Добавить изображение" :multiple="true"
                            :clear-files-after-select="true"/>
        </div>
      </div>
    </MainCard>
  </section>
  <section v-if="route.params.id" class="py-2 mb-4">
    <div class="flex justify-content-end">
      <span class="color-error underline cursor-pointer" @click="changeConfirmationStateModal">
        Удалить новость
      </span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.--calendar {
  ::v-deep(input) {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}
</style>