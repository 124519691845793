import {useStore} from "vuex";
import {computed, ref, watch} from "vue";

import {useVuelidate} from "@vuelidate/core";
import {required, numeric} from "@/utils/i18n-validators";

export function useTariff() {
    const store = useStore();

    const tariff = computed(() => store.getters.getCurrentTariff);
    const tariffErrors = ref(null)
    const form = ref({
        name_ru: '',
        description_ru: '',
        short_description_ru: '-'
    });

    const rules = {
        name_ru: {required},
        description_ru: {required},
    };

    const v$ = useVuelidate(rules, form);

    const isCreated = ref(false);
    const isUpdated = ref(false);

    watch(form, () => {
        isCreated.value = false;
        isUpdated.value = false;
    });

    const loadTariff = async (id) => {
        await store.dispatch('fetchTariff', id);
    };

    const toggleCreateTariff = async () => {
        const result = await v$.value.$validate();
        if (!result) {
            return false;
        }
        tariffErrors.value = null
        try {
            await store.dispatch('fetchCreateTariff', form.value);
            isCreated.value = true;
        } catch (e) {
            tariffErrors.value = e.response.data.data
        }
    };

    const toggleSetDefault = () => {
        isCreated.value = false;
        for (let key in form.value) {
            form.value[key] = ""
        }
    };

    const updateTariff = async (id, body) => {
        const result = await v$.value.$validate();
        if (!result) {
            return;
        }

        await store.dispatch('fetchUpdateTariff', {id, body});

        isUpdated.value = true;
    };

    const destroyTariff = async (id) => {
        await store.dispatch('fetchDestroyTariff', id);
    };

    return {
        form,
        v$,
        isCreated,
        isUpdated,
        tariff,
        tariffErrors,
        toggleCreateTariff,
        toggleSetDefault,
        loadTariff,
        updateTariff,
        destroyTariff,
    };
}