<script>
import {computed, defineComponent, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {usePartners} from "@/hooks/partners";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import Breadcrumb from "@/components/Breadcrumb";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    ButtonSuccess,
    Button,
    InputText,
    MainCard,
    Dropdown,
    Breadcrumb,
    Editor,
    ButtonFileUpload,
    ImageCard,
    ConfirmationModal,
  },
  setup() {
    const {
      form,
      files,
      loadFileMemory,
      isUpdate,
      v$,
      destroyFileMemory,
      createPartner,
    } = usePartners();
    const {t} = useI18n();
    const store = useStore();
    const router = useRouter()
    const errors = ref(null)

    const visibleModal = ref(false);

    const partnerCategories = computed(() => store.getters.getListPartnerCategories);

    const promoTypes = ref([
      {label: 'QR-code', promo: 'qr'},
      {label: 'Промокод', promo: 'promocode'}
    ]);

    const breadcrumbs = ref([]);

    const loadPartnerCategories = async () => {
      await store.dispatch('fetchPartnerCategories');
    };

    const create = async () => {
      errors.value = null
      const result = await v$.value.$validate()
      if (result) {
        try {
          await createPartner();
          files.value = [];
          await router.push({name: 'partners-list'});
        } catch (e) {
          errors.value = e.response.data.data
        }
      }
    };

    onMounted(async () => {
      breadcrumbs.value = [
        {label: 'Партнеры', router: {name: 'partners-list'}},
        {label: 'Создание партнера'}
      ];
      await loadPartnerCategories();
    });

    return {
      t,
      files,
      form,
      partnerCategories,
      promoTypes,
      breadcrumbs,
      create,
      isUpdate,
      destroyFileMemory,
      loadFileMemory,
      visibleModal,
      v$,
      errors,
    };
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>

      <div class="flex">
        <Button
            @click="create"
            label="Сохранить изменения"
            class="btn-primary font-light ml-3"
        />

      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12 md:col-4">
        <MainCard title="Название партнера">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  :class="{'p-invalid': v$.name.$errors.length }"
                  id="name"
                  class="w-full"
                  v-model="form.name"
              />
              <label for="name">Название</label>
            </span>
          <span v-if="v$.name.$errors.length || errors?.name" class="color-error">{{ errors?.name ? errors.name[0] : t(v$.name.$errors[0].$message) }}</span>
        </MainCard>
      </div>
      <div class="col-12 md:col-4">
        <MainCard title="Идентификатор партнера">
          <Dropdown
              v-model="form.promo_type"
              :options="promoTypes"
              optionLabel="label"
              option-value="promo"
              placeholder="Идентификатор"
              class="w-full"/>
        </MainCard>
      </div>
      <div class="col-12 md:col-4">
        <MainCard title="Категория партнера">
          <Dropdown
              v-model="form.partner_category_id"
              :options="partnerCategories?.data?.data"
              :class="{'p-invalid': v$.partner_category_id.$errors.length }"
              optionLabel="name_ru"
              option-value="id"
              placeholder="Категории"
              class="w-full"/>
          <span v-if="v$.partner_category_id.$errors.length" class="text-xs color-error">
            {{ t(v$.partner_category_id.$errors[0].$message) }}
          </span>
        </MainCard>

      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Описание предложения">
      <div class="grid">
        <div class="col-12">
          <Editor v-model="form.description_ru" class="w-full"></Editor>
          <span v-if="v$.description_ru.$errors.length || errors?.description_ru" class="text-xs color-error">
            {{ errors?.description_ru ? errors.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}
          </span>
        </div>
      </div>
    </MainCard>
  </section>
  <section class="py-2 mb-3">
    <MainCard title="Промокод">
            <span class="p-float-label mb-3 w-full">
              <InputText
                  :class="{'p-invalid': errors?.promo_value}"
                  id="name"
                  class="w-full"
                  v-model="form.promo_value"
              />
              <label for="name">Промокод</label>
            </span>
      <span v-if="errors?.promo_value" class="color-error">{{ errors?.promo_value ? errors.promo_value[0] : t(v$.promo_value.$errors[0].$message) }}</span>
    </MainCard>
  </section>

  <section class="py-2 mb-3">
    <MainCard title="Изображения">
      <span v-if="errors?.photo" class="flex text-xs color-error mb-2">
              {{ errors.photo[0] }}
            </span>
      <div class="grid">
        <template v-if="files.length">
          <div v-for="(file, i) in files" :key="i" class="col-12 md:col-2">
            <ImageCard :src="file.objectURL" :handle="() => destroyFileMemory(file)"/>
          </div>
        </template>

        <div class="col-12">
          <ButtonFileUpload @chooseFiles="loadFileMemory" label="Добавить изображение" :multiple="true"
                            :clear-files-after-select="true"/>
        </div>
      </div>
    </MainCard>
  </section>
</template>

<style scoped>

</style>