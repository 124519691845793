import {loadWebsiteLink, updateWebsiteLink} from "@/services/website-link";

const state = () => ({
    websiteLink: null,
});

const actions = {
    async fetchWebsiteLink({commit}) {
        const data = await loadWebsiteLink();
        commit('updateWebsiteLink', data);
    },

    async fetchUpdateWebsiteLink({commit}, data) {
        await updateWebsiteLink(data);
    }
};

const mutations = {
    updateWebsiteLink(state, data) {
        state.websiteLink = data;
    }
};

const getters = {
    getWebsiteLink(state) {
        return state.websiteLink?.data;
    }
};

export default {state, actions, mutations, getters};
