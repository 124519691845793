import RoomCategoryService from "@/services/room-category";

const state = () => ({
    currentRoomCategory: [],
    currentRoomCategoryPhotos: {
        common: [],
        plan: [],
        places: [],
    },
    currentRoomCategoryDeletePhotos: [],
    roomCategoryTariffsList: [],
    roomCategoryServicesList: [],
    roomRoomCategoriesList: [],
});

const actions = {
    async fetchRoomCategory({commit}, {segmentId, roomCategoryId}){
        const {data: category} = await RoomCategoryService.loadRoomCategory(segmentId, roomCategoryId);
        commit('updateCurrentRoomCategory', category);
    },
    async fetchUpdateRoomCategory({commit}, {segmentId, roomCategoryId, body}) {
        const {data: category} = await RoomCategoryService.updateRoomCategory(segmentId, roomCategoryId, body);
        commit('updateCurrentRoomCategory', category);
    },
    async fetchDeleteRoomCategoryPhoto({commit}, {segmentId, roomCategoryId, body}) {
        await RoomCategoryService.deletePhotoRoomCategory(segmentId, roomCategoryId, body);
    },
    async fetchRoomCategoryTariffs({commit}, {segmentId, roomCategoryId}) {
        const {data: tariffs} = await RoomCategoryService.loadRoomCategoryTariffs(segmentId, roomCategoryId);
        commit('updateRoomCategoryTariffsList', tariffs);
    },
    async fetchRoomCategoryServices({commit}, {segmentId, roomCategoryId}) {
        const {data: services} = await RoomCategoryService.loadRoomCategoryServices(segmentId, roomCategoryId);
        commit('updateRoomCategoryServicesList', services);
    },
    async fetchRoomCategories({commit}, {segmentId, params}) {
        const categories = await RoomCategoryService.loadRoomCategories(segmentId, params);
        commit('updateRoomCategoriesList', categories);
    },
    async fetchTouchRoomCategoryTariff({commit}, {segmentId, roomCategoryId, body}){
        await RoomCategoryService.touchRoomCategoryTariff(segmentId, roomCategoryId, body);
    },
    async fetchTouchRoomCategoryService({commit}, {segmentId, roomCategoryId, body}){
        await RoomCategoryService.touchRoomCategoryService(segmentId, roomCategoryId, body);
    }
};

const mutations = {
    updateCurrentRoomCategory(state, category) {
        state.currentRoomCategory = category;
    },
    updateRoomCategoryTariffsList(state, tariffs) {
        state.roomCategoryTariffsList = tariffs;
    },
    updateRoomCategoryTariff(state, tariff) {
        const index = state.roomCategoryTariffsList.findIndex(item => item.id === tariff.id);
        if (index !== -1) {
            state.roomCategoryTariffsList[index] = {...tariff};
        }
    },
    updateRoomCategoryService(state, service) {
        const index = state.roomCategoryServicesList.findIndex(item => item.id === service.id);
        if (index !== -1) {
            state.roomCategoryServicesList[index] = {...service};
        }
    },
    updateRoomCategoriesList(state, categories) {
        state.roomRoomCategoriesList = categories;
    },
    updateRoomCategoryServicesList(state, services) {
        state.roomCategoryServicesList = services;
    },
    addRoomCategoryPhotos(state, data) {
        state.currentRoomCategoryPhotos[data.type].push(...data.files);
    },
    deleteRoomCategoryPhotos(state, data) {
        state.currentRoomCategoryPhotos[data.type] = state.currentRoomCategoryPhotos[data.type].filter(item => item !== data.file);
    },
    addDeleteRoomCategoryPhotos(state, data) {
        state.currentRoomCategoryDeletePhotos.push(data);
    },
    deleteLocalRoomCategoryPhotos(state) {
        state.currentRoomCategoryPhotos = {
            common: [],
            plan: [],
            places: [],
        }
        state.currentRoomCategoryDeletePhotos = [];
    }
};

const getters = {
    getCurrentRoomCategory(state) {
        return state.currentRoomCategory;
    },
    getRoomCategoryTariffsList(state) {
        return state.roomCategoryTariffsList;
    },
    getRoomCategoriesList(state) {
        return state.roomRoomCategoriesList;
    },
    getRoomCategoryServicesList(state) {
        return state.roomCategoryServicesList;
    },
    getRoomCategoryPhotos(state) {
        return state.currentRoomCategoryPhotos;
    },
    getRoomCategoryDeletePhotos(state) {
        return state.currentRoomCategoryDeletePhotos;
    },
};

export default {state, actions, mutations, getters};