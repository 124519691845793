import { computed, isReactive, ref, toRaw, watch } from 'vue'
const RequestStatus = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  NOT_STARTED: 'NOT_STARTED',
}
export const useApiCall = (
  apiCallFunction,
  externalCall = false,
  params = null
) => {
  let abortController = null
  const data = ref(null)
  const error = ref(null)
  const requestStatus = ref(
    RequestStatus.NOT_STARTED
  )

  const isLoading = computed(
    () => requestStatus.value === RequestStatus.PENDING,
  )

  const executeApiCall = async (externalParams) => {
    data.value = null
    error.value = null
    requestStatus.value = RequestStatus.PENDING
    try {
      abortController?.abort()
      abortController = new AbortController()
      const apiCallParams = externalParams instanceof FormData ? externalParams : toRaw(externalParams || params)
      const response = await apiCallFunction(
          apiCallParams,
          abortController,
      )
      data.value = response
      requestStatus.value = RequestStatus.SUCCESS
      abortController = null
    } catch (e) {
      const axiosError = e
      if (axiosError.code !== 'ERR_CANCELED') {
        requestStatus.value = RequestStatus.FAILED
        const status = axiosError.response?.status
        const response = axiosError.response
        if (response) {
          error.value = {
            status,
            data: response.data.data,
          }
        }
        if (externalCall) {
          throw new Error('Error for external call catch')
        }
      }
    }
  }

  if (!externalCall) {
    if (isReactive(params)) {
      watch(
        () => params,
        async () => await executeApiCall(toRaw(params)),
        {
          immediate: true,
          deep: true,
        },
      )
    } else {
      executeApiCall(params)
    }
  }
  return { data, error, isLoading, executeApiCall }
}
