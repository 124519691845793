<script>
import {defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import Button from "primevue/button";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import MainCard from "@/components/cards/MainCard.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import InputText from "primevue/inputtext";
import Textarea from 'primevue/textarea';
import {useI18n} from "vue-i18n";
import {required} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";
import {useApiCall} from "@/composables/useApiCall";
import {bannerApiCall, updateBannerApiCall} from "@/services/website";
import Skeleton from "primevue/skeleton"

export default defineComponent({
  components: {
    ButtonFileUpload, ImageCard, MainCard, InputText, Textarea,
    ButtonSuccess,
    Button,
    Skeleton,
  },
  setup() {
    const { t } = useI18n()
    const form = reactive({
      fields: {
        title_ru: '',
        subtitle_ru: '',
        photo: null,
      }
    });
    const formRules = {
      photo: {},
      title_ru: {required},
      subtitle_ru: {required},
    }
    const v$ = useVuelidate(formRules, form.fields);
    const isUpdated = ref(false)
    const isLoading = ref(true)

    const {
      data: contentData,
      executeApiCall: getContentAction,
    } = useApiCall(bannerApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateBannerApiCall, true)

    const loadFileMemory = (data) => {
      form.fields.photo = data.files[0]
    }
    const updateForm = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      try {
        const formData = new FormData()
        formData.append('title_ru', form.fields.title_ru)
        formData.append('subtitle_ru', form.fields.subtitle_ru)
        if (form.fields.photo?.objectURL) {
          formData.append('photo', form.fields.photo)
        }
        await updateContentAction(formData)
        if (contentUpdateData.value) {
          if (contentUpdateData.value.data.photo) {
            await updateFormFields(contentUpdateData.value.data)
          }
          isUpdated.value = true
        }
      } catch (e) {
        console.error(e)
      }
    }

    const updateFormFields = (data) => {
      form.fields.title_ru = data.title_ru
      form.fields.subtitle_ru = data.subtitle_ru
      form.fields.photo = data.photo
    }

    watch(() => form.fields,
        ()=> {
          isUpdated.value = false
        }, {deep: true})
    onBeforeMount( async () => {
      try {
        await getContentAction()
        if (contentData.value?.data) {
          updateFormFields(contentData.value.data)
        }
        isLoading.value = false
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      v$,
      form,
      isUpdated,
      loadFileMemory,
      updateForm,
      contentUpdateError,
      isLoading,
      isContentUpdateLoading,
    };
  }
})
</script>

<template>
  <div>
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>Главный баннер</h1>
      <ButtonSuccess v-if="isUpdated" label="Изменения сохранены" class="btn-fix-height"/>
      <Button
          v-else
          @click="updateForm"
          :loading="isContentUpdateLoading"
          :label="t('button.saveChange')"
          class="btn-primary font-light btn-fix-height"
      />
    </div>
    <Skeleton v-if="isLoading" width="100%" height="292px"></Skeleton>
    <div v-else class="brand__wrapper">
      <MainCard title="Изображение">
          <span v-if="contentUpdateError?.data.photo" class="flex mb-2 text-xs color-error">
            {{ contentUpdateError.data.photo[0] }}
          </span>
        <div class="flex flex-wrap gap-2">
          <template v-if="form.fields.photo">
            <div class="brand__image">
              <ImageCard v-if="form.fields.photo.preview_url" :src="form.fields.photo.preview_url" withoutDelete/>
              <ImageCard v-else :src="form.fields.photo.objectURL" withoutDelete/>
            </div>
          </template>
          <div class="brand__image">
            <ButtonFileUpload
                @chooseFiles="loadFileMemory"
                :label="form.fields.photo ? 'Заменить фото' : 'Добавить фото'"
                :multiple="false"
                :clear-files-after-select="true"
                :classes="{'w-full': true}"
            />
          </div>
        </div>
      </MainCard>
      <MainCard title="Заголовок">
        <div class="grid my-2 mx-0">
          <div class="w-full">
            <span class="p-float-label w-full">
              <InputText
                  v-model="form.fields.title_ru"
                  :class="['w-full', {'p-invalid': v$.title_ru.$errors.length || contentUpdateError?.data.title_ru}]"
                  id="brandBanner"
              />
              <label for="brandBanner">{{ t('input.placeholder.title') }} <i style="color: #E26B6B">*</i></label>
            </span>
            <span v-if="v$.title_ru.$errors.length || contentUpdateError?.data.title_ru" class="text-xs color-error">
                {{ contentUpdateError ? contentUpdateError.data.title_ru[0] : t(v$.title_ru.$errors[0].$message) }}
            </span>
          </div>
        </div>
      </MainCard>
      <MainCard title="Подзаголовок">
        <div class="grid my-2 mx-0">
          <div class="w-full">
              <span class="p-float-label w-full">
              <Textarea
                  v-model="form.fields.subtitle_ru"
                  :class="['w-full', 'p-inputtext', {'p-invalid': contentUpdateError?.data.subtitle_ru}]"
                  rows="3"
                  cols="30"
                  id="mainBannerDescription"
              />
              <label for="mainBannerDescription">Введите подзаголовок *</label>
            </span>
            <span v-if="v$.subtitle_ru.$errors.length || contentUpdateError?.data.subtitle_ru" class="text-xs color-error">
                {{ contentUpdateError ? contentUpdateError.data.subtitle_ru[0] : t(v$.subtitle_ru.$errors[0].$message) }}
            </span>
          </div>
        </div>
      </MainCard>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.brand {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    @media screen and (min-width: 1200px) {
      column-gap: 30px;
      grid-template-columns: calc(27% - 30px) calc(31.5% - 30px) 41.5%;
    }
  }
  &__image {
    width: 208px;
  }
}
</style>