<script>
import {defineComponent, ref, watch} from "vue";
import {useStore} from "vuex";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import FileUpload from "@/components/FileUpload";
import MainCard from "@/components/cards/MainCard";
import ImageCard from "@/components/cards/ImageCard.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import ImagesBuilding from '@/views/buildings/ImagesBuilding.vue'

export default defineComponent({
  components: {ButtonFileUpload, ImageCard, InputText, Editor, FileUpload, MainCard, ImagesBuilding},
  props: {
    photos: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
  },
  emits: ['changeFormData'],
  setup(props, {emit}) {
    const photosArray = ref(props.photos);
    const store = useStore();
    watch(photosArray, () => emit('changeFormData', photosArray));

    const deleteFile = (uuid) => {
      store.commit('addDeleteBuildingsImages', uuid)
      photosArray.value = photosArray.value.filter(item => item.uuid !== uuid)
    }
    const destroyFileMemory = (file) => {
      store.commit('deleteBuildingsImages', {key: props.type, file})
    }

    const loadFileMemory = (data) => {
      store.commit('addBuildingsImages', {key: props.type, files: data.files})
    }

    return {photosArray, deleteFile, destroyFileMemory, loadFileMemory};
  }
})
</script>

<template>
  <MainCard title="Изображения">
    <span v-if="errors?.photo" class="flex mb-2 text-xs color-error">
      {{ errors.photo[0] }}
    </span>
    <div class="grid">
      <div v-for="(image, i) in photos" :key="i" class="col-12 md:col-2">
        <ImageCard :src="image.preview_url" :handle="() => deleteFile(image.uuid)"/>
      </div>
      <template v-if="files.length">
        <div v-for="(file, i) in files" :key="i" class="col-12 md:col-2">
          <ImageCard :src="file.objectURL" :handle="() => destroyFileMemory(file)"/>
        </div>
      </template>
      <div class="col-12">
          <ButtonFileUpload @chooseFiles="loadFileMemory" label="Добавить изображение" :multiple="true"
                            :clear-files-after-select="true"/>
      </div>
    </div>
  </MainCard>
</template>
<style scoped>
</style>