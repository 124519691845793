<script>
import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import {useMeta} from "vue-meta";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import {useApiCall} from "@/composables/useApiCall";
import { sendSupportMessageApiCall } from "@/services/support";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {InputText, Button},
  setup() {
    useMeta({
      title: 'Поддержка'
    });
    const {
      data: contentData,
      isLoading: contentLoading,
      executeApiCall: sendContentAction,
    } = useApiCall(sendSupportMessageApiCall, true)

    const store = useStore();

    const message = ref('');
    const toggle = computed(() => store.getters.getToggle);
    const sendMessage = async () => {
      try {
        await sendContentAction({text: message.value})
        message.value = ''
      } catch (e) {
        console.error(e)
      }
    }

    const enterSend = async (e) => {
      if (e.key === 'Enter' && message.value) {
        await sendMessage()
      }
    }
    return {message, toggle, contentLoading, sendMessage, enterSend};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <h1>Поддержка</h1>
  </section>

  <section
      class="py-3 fixed content-messages"
      :class="{'message-section-showed': toggle, 'message-section-closed': !toggle}"
  >
    <div class="flex justify-content-center align-items-center h-full">
      <span class="color-black-40">Напишите о возникшей проблеме</span>
    </div>
  </section>

  <section
      class="py-3 px-3 bg-white fixed bottom-0"
      :class="{'message-section-showed': toggle, 'message-section-closed': !toggle}"
  >
    <span class="p-input-icon-right w-full relative">
      <Button :disabled="!message" icon="pi pi-arrow-up" class="message-section-send" :loading="contentLoading" @click="sendMessage" />
      <InputText @keyup="enterSend" v-model="message" class="border-round-3xl w-full" placeholder="Введите текст сообщения" />
    </span>
  </section>
</template>

<style scoped>
.message-section-showed {
  width: calc(100% - 300px);
  left: 300px;
}

.message-section-closed {
  width: calc(100% - 126px);
  left: 126px;
}

.content-messages {
  height: calc(100% - 190px);
}
.message-section-send {
  display: flex;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border: unset;
  background: unset;
  color: #333333;
  cursor: pointer !important;
  &:hover,
  &:focus {
    cursor: pointer;
    color: #333333;
    background: unset;
    border: unset;
  }
}
</style>