<script>
import {computed, defineComponent, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {useRouter} from "vue-router";

import TabMenu from "primevue/tabmenu";
import Button from "primevue/button";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {TabMenu, Button},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const uri = ref(route.name);
    const items = ref([
      {
        label: 'Список партнеров',
        to: '/partners/list'
      },
      {
        label: 'Категории партнеров',
        to: '/partners/categories'
      }
    ]);


    const label = computed(() => {
      switch (uri.value) {
        case 'partners-categories':
          return 'Создать категорию';
        case 'partners-list':
          return 'Создать партнера';
        default:
          return 'Создать категорию';
      }
    });

    const toCreate = async () => {
      switch (uri.value) {
        case 'partners-categories':
          await router.push({name: 'partner-category-create'});
          return;
        case 'partners-list':
          await router.push({name: 'partners-create'});
          return;
      }
    }

    watch(
        () => route.name,
        () => uri.value = route.name
    );

    return {items, label, toCreate};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <h1>Партнеры</h1>
      <Button
          v-if="label.length"
          @click="toCreate"
          :label="label"
          class="btn-primary font-light"
      />
    </div>
  </section>

  <section class="py-2 mb-3">
    <TabMenu :model="items"/>
  </section>

  <router-view></router-view>
</template>