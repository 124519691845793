<script>
import {computed, defineComponent, onBeforeMount, ref, unref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {useServiceCategory} from "@/hooks/service-category";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import FileUpload from "@/components/FileUpload";
import MainCard from "@/components/cards/MainCard";
import Breadcrumb from "@/components/Breadcrumb";
import ImageCard from "@/components/cards/ImageCard";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload";
import Editor from "primevue/editor";
import Calendar from "primevue/calendar";
import InputMask from "primevue/inputmask";
import Skeleton from "primevue/skeleton"

import {useBanks} from "@/hooks/banks";
import {useApiCall} from "@/composables/useApiCall";
import { repairFormApiCall, updateRepairFormApiCall, deletePhotoRepairFormApiCall, addPhotoRepairFormApiCall } from "@/services/repairForm"
import {required} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";
export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    InputText, Checkbox, Button,
    MainCard, Breadcrumb, ImageCard,
    Dropdown, FileUpload, ConfirmationModal,
    ButtonSuccess, ButtonFileUpload,
    Editor,
    Calendar,
    InputMask,
    Skeleton,
  },
  setup() {
    const {t} = useRoute()
    const {
      category, form, updateServiceCategory,
      selectIconFile, selectBannerFile,
      isUpdate, v$,
      icon, banner,
      files, vf$, errors,
      loadServiceCategory,
    } = useServiceCategory();
    const repairForm = ref({
      photo: null,
      description_ru: '',
      has_intervals: false,
      intervals: [],
    })
    const formRules = {
      description_ru: {required},
      has_intervals: {required},
    };
    const isLoading = ref(true)
    const repairFile = ref(null)
    const {selectBank, banks, loadAcquiring, acquiring, loadBanks} = useBanks();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isRepair = computed(() => route.params.id === '1')
    const intervalForm = ref({
      start: '',
      end: '',
      duration: ''
    });
    const intervalRules = {
      start: {required},
      end: {required},
      duration: {required},
    };
    const deletePhotoUuid = ref(null)
    const fv$ = useVuelidate(formRules, repairForm.value);
    const iv$ = useVuelidate(intervalRules, intervalForm.value);
    const serviceError = ref(null)
    const visible = computed(() => store.getters.getConfirmationDestroyServiceCategoryModal);
    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(repairFormApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateRepairFormApiCall, true)
    const {
      isLoading: isDeletePhotoLoading,
      data: deletePhotoData,
      error: deletePhotoError,
      executeApiCall: deletePhotoAction,
    } = useApiCall(deletePhotoRepairFormApiCall, true)

    const {
      isLoading: isAddPhotoLoading,
      data: addPhotoData,
      error: addPhotoError,
      executeApiCall: addPhotoAction,
    } = useApiCall(addPhotoRepairFormApiCall, true)

    const changeVisibleModal = () => {
      store.dispatch('changeStateModal', {
        type: 'confirmationDestroyServiceCategoryModal',
        bool: !visible.value
      });
    };

    const destroyServiceCategory = async () => {
      await store.dispatch('fetchDestroyServiceCategory', route.params.id);
      await router.push({name: 'services'});
    };

    watch(() => selectBank.value, async () => {
      await loadAcquiring()
    }, {deep: true});

    watch(() => [form.value, files.value], () => isUpdate.value = false, {deep: true})
    const breadcrumb = ref([
      {label: 'Услуги', router: {name: 'services'}},
    ])
    const isUpdateService = ref(false)
    const updateService = async () => {
      serviceError.value = null
      const resultValidateIntervals = await v$.value.$validate();
      const resultValidateForm = await v$.value.$validate();
      if (!resultValidateForm || (repairForm.value.has_intervals && !resultValidateIntervals)) {
        return;
      }
      if (deletePhotoUuid.value) {
        try {
          await deletePhotoRepairFormApiCall({uuid: deletePhotoUuid.value})
        } catch (e) {
          console.error(e)
          serviceError.value = deletePhotoError.value.data
          return
        }
      }
      if (repairFile.value) {
        try {
          const formDataPhoto = new FormData()
          formDataPhoto.append('photo', repairFile.value)
          await addPhotoAction(formDataPhoto)
        } catch (e) {
          console.error(e)
          serviceError.value = addPhotoError.value.data
          return
        }
      }
      const formData = new FormData()
      formData.append('description_ru', repairForm.value.description_ru)
      formData.append('has_intervals', Number(repairForm.value.has_intervals))
      if (repairForm.value.has_intervals) {
        formData.append('intervals[start]', intervalForm.value.start ? [intervalForm.value.start.getHours(), intervalForm.value.start.getMinutes()].map(function (x) {
          return x < 10 ? "0" + x : x
        }).join(":") : '')
        formData.append('intervals[end]', intervalForm.value.end ? [intervalForm.value.end.getHours(), intervalForm.value.end.getMinutes()].map(function (x) {
          return x < 10 ? "0" + x : x
        }).join(":") : '')
        formData.append('intervals[duration]', intervalForm.value.duration)
      }
      try {
        await updateContentAction(formData)
        if (contentUpdateData.value) {
          isUpdateService.value = true
        }
      } catch (e) {
        serviceError.value = contentUpdateError.value.data
        console.error(e)
      }
    }
    const deletePhoto = () => {
      deletePhotoUuid.value = repairForm.value.photo.uuid
      repairForm.value.photo = null
      repairFile.value = null
    }
    const destroyFileMemory = () => {
      repairForm.value.photo = null
      repairFile.value = null
    }
    const selectFiles = (data) => {
      if (repairForm.value.photo) {
        repairForm.value.photo = null
      }
      repairFile.value = data.files[0]
    }
    const normalizeTime = (time) => {
      const regex = /^\b[0-2]?\d:[0-5]\d\b$/;
      let date = '';
      if (regex.test(time)) {
        const [h, m] = time.split(':');
        date = new Date();
        date.setHours(h);
        date.setMinutes(m);
      }
      return date;
    }
    watch(() => [repairForm.value, intervalForm.value, repairFile.value],
        () => isUpdateService.value = false, {deep: true})

    onBeforeMount(async () => {
      try {
        await loadServiceCategory(route.params.id);
        await loadBanks();
        breadcrumb.value.push({label: category.value.name_ru})
        form.value = unref(Object.assign({
          ...category.value,
          acquiring_id: category.value?.acquiring?.id ?? '',
          quick_access: !!category.value?.quick_access,
          description_ru: '-',
        }));
        if (isRepair.value) {
          await getContentAction()
          if (contentData.value) {
            repairForm.value = {
              ...contentData.value.data,
              has_date: Boolean(contentData.value.data)
            }
            repairForm.value.has_intervals = !!contentData.value.data.has_intervals
            if (repairForm.value.intervals && repairForm.value.intervals !== "null") {
              let {end, start, duration} = JSON.parse(repairForm.value.intervals);
              if (!(end && start && duration)) {
                intervalForm.value = {end: '', start: '', duration: ''};
              } else {
                end = normalizeTime(end);
                start = normalizeTime(start);
                duration = duration.length === 4 ? `0${duration}` : duration;
                intervalForm.value = {end, start, duration};
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
      selectBank.value = form.value.acquiring?.bank_id
      isLoading.value = false
    })

    return {
      files,
      selectIconFile,
      selectBannerFile,
      icon,
      banner,
      category,
      breadcrumb,
      form,
      selectBank,
      acquiring,
      banks,
      updateServiceCategory,
      changeVisibleModal,
      destroyServiceCategory,
      visible,
      isUpdate,
      v$,
      fv$,
      errors,
      isUpdateService,
      updateService,
      repairForm,
      isRepair,
      repairFile,
      selectFiles,
      intervalForm,
      intervalRules,
      iv$,
      t,
      serviceError,
      isLoading,
      isContentUpdateLoading,
      isDeletePhotoLoading,
      deletePhoto,
      destroyFileMemory,
      isAddPhotoLoading,
    };
  }
});
</script>

<template>
  <ConfirmationModal :visible="visible">
    <template #header>
      Удалить категорию
    </template>

    <template #default>
      <span>Вы уверены,  что хотите удалить категорию? Это действие будет невозможно отменить</span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button label="Отменить" @click="changeVisibleModal" class="btn-primary-outlined font-light w-12"/>
        <Button label="Удалить" @click="destroyServiceCategory" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>

  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumb" separator="/"/>
      <div class="flex">
        <ButtonSuccess v-if="isUpdate" label="Изменения сохранены" class="btn-fix-height"></ButtonSuccess>
        <Button v-else @click="updateServiceCategory" label="Сохранить изменения"
                class="ml-2 btn-fix-height btn-primary font-light"/>
      </div>
    </div>
  </section>
  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12 md:col-5">
        <div class="mb-2">
          <Skeleton v-if="isLoading" width="100%" height="138px"></Skeleton>
          <MainCard v-else title="Наименование категории">
            <span class="p-float-label w-full">
              <InputText
                  v-model="form.name_ru"
                  :class="{'p-invalid': v$.name_ru.$errors.length || errors?.name_ru}"
                  id="patronymic"
                  class="w-full"
              />
              <label for="patronymic">Введите наименование категории *</label>
            </span>
            <span v-if="v$.name_ru.$errors.length || errors?.name_ru" class="text-xs color-error">
              {{ errors?.name_ru ? errors.name_ru[0] : v$.name_ru.$errors[0].$message }}
            </span>
          </MainCard>
        </div>

        <div class="mb-2">
          <Skeleton v-if="isLoading" width="100%" height="147px"></Skeleton>
          <MainCard v-else title="Изображения иконки">
            <span v-if="errors?.icon" class="text-xs color-error">
              {{ errors.icon[0] }}
            </span>
            <div class="flex">
              <img
                  v-if="files.icon"
                  :src="files.icon.objectURL"
                  class="mr-2 h-2rem w-2rem"
                  alt=""
              >
              <img
                  v-else-if="category.icon"
                  :src="category.icon"
                  class="mr-2 h-2rem w-2rem"
                  alt=""
              >
              <ButtonFileUpload
                  @chooseFiles="selectIconFile"
                  :clear-files-after-select="true"
                  :label="(category.icon || files.icon) ? 'Изменить изображение' : 'Добавить изображение'"
              />
            </div>
          </MainCard>
        </div>
        <Skeleton v-if="isLoading" width="100%" height="191px"></Skeleton>
        <MainCard v-else title="Реквизиты и эквайринг">
          <div class="flex flex-column gap-3">
            <Dropdown
                v-model="selectBank"
                :options="banks"
                optionLabel="name"
                optionValue="id"
                placeholder="Банк"
                class="w-full"
            />
            <Dropdown
                v-model="form.acquiring_id"
                :options="acquiring"
                optionLabel="identifier"
                optionValue="id"
                placeholder="Идентификатор эквайринга"
                class="w-full"
            />
          </div>
        </MainCard>
      </div>
      <div class="col-12 md:col-7">
        <div class="mb-2">
          <Skeleton v-if="isLoading" width="100%" height="227.44px"></Skeleton>
          <MainCard v-else title="Изображения категории">
            <span v-if="errors?.banner" class="text-xs color-error">
              {{ errors.banner[0] }}
            </span>
            <div class="grid">
              <div class="flex flex-column col-12 md:col-6">
                <div class="mb-2">
                  <img v-if="files.banner" :src="files.banner.objectURL" alt="" class="w-full mb-2 w-5rem">
                  <img v-else-if="category.banner" :src="category.banner" alt="" class="w-full mb-2 w-5rem">
                </div>
                <ButtonFileUpload
                    @chooseFiles="selectBannerFile"
                    :clear-files-after-select="true"
                    :label="(category.banner || files.banner) ? 'Изменить изображение' : 'Добавить изображение'"
                />
              </div>
            </div>
          </MainCard>
        </div>
        <Skeleton v-if="isLoading" width="100%" height="121px"></Skeleton>
        <MainCard v-else title="Видимость">
          <Checkbox
              v-model="form.quick_access"
              :binary="true"
              name="quick_access"
          />
          <label class="ml-2" for="quick_access">Видимость</label>
        </MainCard>
      </div>
    </div>
  </section>

  <section v-if="isRepair" class="py-2 mb-3">
    <div class="flex justify-content-between align-items-center">
      <h1>Редактирование услуги</h1>
      <div class="flex">
        <ButtonSuccess v-if="isUpdateService" label="Изменения сохранены" class="btn-fix-height"></ButtonSuccess>
        <Button v-else @click="updateService"
                :loading="isContentUpdateLoading || isDeletePhotoLoading || isAddPhotoLoading"
                label="Сохранить изменения" class="ml-2  btn-fix-height btn-primary font-light"/>
      </div>
    </div>
  </section>
  <section v-if="isRepair && repairForm" class="py-2 mb-3">
    <Skeleton v-if="isLoading" width="100%" height="204px"></Skeleton>
    <MainCard v-else title="Описание услуги">
      <Editor v-model="repairForm.description_ru" class="w-full"></Editor>
      <span v-if="fv$.description_ru.$errors.length || serviceError?.description_ru" class="text-xs color-error">
        {{ serviceError?.description_ru ? serviceError.description_ru[0] : t(fv$.description_ru.$errors[0].$message) }}
      </span>
    </MainCard>
  </section>

  <section v-if="isRepair && repairForm" class="py-2 mb-3">
    <Skeleton v-if="isLoading" width="100%" height="362px"></Skeleton>
    <MainCard v-else title="Изображения">
      <span v-if="serviceError?.photo" class="flex mb-2 text-xs color-error">
        {{ serviceError.photo[0] }}
      </span>
      <div class="grid">
        <div v-if="repairForm.photo" class="col-12 md:col-2">
          <ImageCard :src="repairForm.photo.preview_url" :handle="() => deletePhoto()"/>
        </div>
        <div v-if="repairFile" class="col-12 md:col-2">
          <ImageCard :src="repairFile.objectURL" :handle="() => destroyFileMemory()"/>
        </div>
        <div class="col-12">
          <ButtonFileUpload
              @chooseFiles="selectFiles"
              :label="repairForm.photo || repairFile ? 'Заменить изображение' : 'Добавить изображение'"
              :clear-files-after-select="true"
              :multiple="false"
          />
        </div>
      </div>
    </MainCard>
  </section>

  <section v-if="isRepair" class="py-2 mb-3">
    <h2 class="mb-4">Интервалы оказания услуг</h2>
    <div class="grid h-max">
      <div class="col-12 md:col-6">
        <Skeleton v-if="isLoading" width="100%" height="185px"></Skeleton>
        <MainCard v-else title="Период оказания услуги">
          <span v-if="iv$.start.$errors.length" class="flex mb-2 text-xs color-error">
            {{ t(iv$.start.$errors[0].$message) }}: От
          </span>
          <span v-if="(serviceError && serviceError['intervals.start'])" class="flex mb-2 text-xs color-error">
            {{ serviceError['intervals.start'][0] }}
          </span>
          <span v-if="iv$.end.$errors.length" class="flex mb-2 text-xs color-error">
            {{ t(iv$.end.$errors[0].$message) }}: До
          </span>
          <span v-if="(serviceError && serviceError['intervals.end'])" class="flex mb-2 text-xs color-error">
            {{ serviceError['intervals.end'][0] }}
          </span>
          <span v-if="iv$.duration.$errors.length" class="flex mb-2 text-xs color-error">
            {{ t(iv$.duration.$errors[0].$message) }}: Продолжительность
          </span>
          <span v-if="(serviceError && serviceError['intervals.duration'])" class="flex mb-2 text-xs color-error">
            {{ serviceError['intervals.duration'][0] }}
          </span>
          <div class="flex w-100">
            <Calendar
                :disabled="!repairForm.has_intervals"
                v-model="intervalForm.start"
                placeholder="От"
                timeOnly
                class="w-10rem"
            />
            <Calendar
                :disabled="!repairForm.has_intervals"
                v-model="intervalForm.end"
                placeholder="До"
                timeOnly
                class="ml-2 w-10rem"/>
            <InputMask
                :disabled="!repairForm.has_intervals"
                v-model="intervalForm.duration"
                mask="99:99"
                placeholder="Продолжительность"
                class="ml-2 w-12rem"/>
          </div>
          <span
              class="text-xs color-black-20 mb-1">Доступные интервалы: 08:00, 08:30, 09:00, 09:30, ... 18:30</span><br>
          <Checkbox
              v-model="repairForm.has_intervals"
              class="mt-2"
              name="intervals"
              binary
              :trueValue="false"
              :falseValue="true"
          />
          <label class="ml-2" for="intervals">Оказание услуг не требует интервалов</label>
        </MainCard>
      </div>
    </div>
  </section>
  <!--  <section class="py-2 mb-3">-->
  <!--    <div class="flex justify-content-end">-->
  <!--      <span @click="changeVisibleModal" class="cursor-pointer color-error underline">Удалить категорию</span>-->
  <!--    </div>-->
  <!--  </section>-->
</template>