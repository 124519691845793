import http from "@/plugins/axios";
const infrastructureInfoApiCall = async (
    abortController = null,
) => {
    const { data } = await http.get('/api/company/v1/infrastructure-info',
        {
            signal: abortController?.signal,
        })
    return data
}

const infrastructureItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.get(`/api/company/v1/infrastructure-info/${params.id}`,
        {
            signal: abortController?.signal,
        })
    return data
}

const addInfrastructureItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post('/api/company/v1/infrastructure-info',
        params,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    return data
}

const updateInfrastructureItemApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/infrastructure-info/${params.id}`,
        {...params.fields},
        {
            signal: abortController?.signal,
        })
    return data
}
const uploadPhotoInfrastructureApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/infrastructure-info/${params.id}/photo/upload`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    return data
}

const deletePhotoInfrastructureApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/infrastructure-info/${params.id}/photo/delete`,
        {...params.fields},
        {
            signal: abortController?.signal,
        })
    return data
}

const addAdvantageInfrastructureApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/infrastructure-info/${params.id}/advantage`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    return data
}

const updateAdvantageInfrastructureApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.post(`/api/company/v1/infrastructure-info/${params.id}/advantage/${params.advantageId}`,
        params.fields,
        {
            signal: abortController?.signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    return data
}

const deleteAdvantageInfrastructureApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/infrastructure-info/${params.id}/advantage/${params.advantageId}`,
        {
            signal: abortController?.signal,
        })
    return data
}

const deleteInfrastructureApiCall = async (
    params,
    abortController = null,
) => {
    const { data } = await http.delete(`/api/company/v1/infrastructure-info/${params.id}`,
        {
            signal: abortController?.signal,
        })
    return data
}

export {
    infrastructureInfoApiCall,
    deleteInfrastructureApiCall,
    infrastructureItemApiCall,
    addInfrastructureItemApiCall,
    updateInfrastructureItemApiCall,
    uploadPhotoInfrastructureApiCall,
    deletePhotoInfrastructureApiCall,
    addAdvantageInfrastructureApiCall,
    updateAdvantageInfrastructureApiCall,
    deleteAdvantageInfrastructureApiCall,
}