import {createI18n} from 'vue-i18n';
import en from '@/i18n/en.json'
import ru from '@/i18n/ru.json'

export const i18n = createI18n({
    fallbackLocale: 'en',
    globalInjection: true,
    locale: 'en',
    legacy: false,
    messages: {
        en,
        ru,
    },
});
