<script>
import {computed, defineComponent, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useError} from "@/hooks/useErrors";
import {useRoute, useRouter} from "vue-router";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import MainCard from "@/components/cards/MainCard.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";


export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {ButtonFileUpload, InputText, MainCard, Breadcrumb, Button, Dropdown, ButtonSuccess},
  async beforeRouteEnter(to, from, next) {
    const store = useStore();
    const route = useRoute()
    if (route.name === 'building-attractions-points-create') {
      await store.dispatch('fetchTypeAttractions');
    } else {
      await store.dispatch('fetchTypeLocations');
    }
    next();
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter()
    const errors = useError();
    const isLoading = ref(false)

    const locationTypes = computed(() => route.name === 'building-attractions-points-create' ? store.getters.getListTypeAttractions : store.getters.getListTypeLocations);

    const form = reactive({
      name_ru: '',
      address_ru: '',
      lat: '',
      lon: '',
      point_type_id: '',
      icon: null,
      comment: ''
    });
    const createPoint = async () => {
      isLoading.value = true
      try {
        const formData = new FormData()
        for (const key in form) {
          formData.append(key, form[key])
        }
        await store.dispatch('fetchCreateBuildingLocationPoint', {
          id: route.params.id,
          type: route.name === 'building-attractions-points-create' ? 'attraction' : 'location',
          body: formData,
        });
        await router.push({name: 'building-edit'})
      } catch (e) {
        errors.setErrors(e.response.data.data);
      }
      isLoading.value = false
    }

    const loadFileMemory = (data) => {
      form.icon = data.files[0]
    }

    watch(form, () => {
      errors.setErrors();
    });

    const breadcrumb = ref([
      {label: 'Структура', router: {name: 'buildings'}},
      {label: 'Создание точки'}
    ]);

    return {breadcrumb, locationTypes, createPoint, form, errors: errors.errors, loadFileMemory, isLoading};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumb" separator="/" />
      <Button @click="createPoint" :loading="isLoading" label="Создать точку" class="btn-primary font-light" />
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12 md:col-8">
        <MainCard title="Сведения">
          <div class="grid">
            <div class="col-6">
              <div class="w-full mb-4">
                <span class="p-float-label w-full">
                  <InputText
                      v-model="form.name_ru"
                      :class="{'p-invalid': errors?.name_ru}"
                      id="name_ru"
                      class="w-full"
                  />
                  <label for="name_ru">Название точки</label>
                </span>
                <span v-if="errors.name_ru" class="text-xs color-error">
                  {{ errors.name_ru[0] }}
                </span>
              </div>
              <div class="w-full mb-4">
                <span class="p-float-label w-full">
                  <InputText
                      v-model="form.lat"
                      :class="{'p-invalid': errors?.lat}"
                      id="lat"
                      class="w-full"
                  />
                  <label for="lat">Широта</label>
                </span>
                <span v-if="errors?.lat" class="text-xs color-error">
                  {{ errors.lat[0] }}
                </span>
              </div>
              <div class="w-full mb-4">
                <span class="p-float-label w-full">
                  <InputText
                      v-model="form.comment"
                      :class="{'p-invalid': errors?.comment}"
                      id="lat"
                      class="w-full"
                  />
                  <label for="lat">Расстояние до точки</label>
                </span>
                <span v-if="errors?.comment" class="text-xs color-error">
                  {{ errors.comment[0] }}
                </span>
              </div>
            </div>
            <div class="col-6">
              <div class="w-full mb-4">
                <span class="p-float-label w-full">
                  <InputText
                      v-model="form.address_ru"
                      :class="{'p-invalid': errors?.address_ru}"
                      id="address_ru"
                      class="w-full"
                  />
                  <label for="address_ru">Адрес точки</label>
                </span>
                <span v-if="errors?.address_ru" class="text-xs color-error">
                  {{ errors.address_ru[0] }}
                </span>
              </div>
              <div class="w-full">
                <span class="p-float-label w-full">
                  <InputText
                      v-model="form.lon"
                      :class="{'p-invalid': errors?.lon}"
                      id="lon"
                      class="w-full"
                  />
                  <label for="lon">Долгота</label>
                </span>
                <span v-if="errors?.lon" class="text-xs color-error">
                  {{ errors?.lon[0] }}
                </span>
              </div>
            </div>
          </div>
        </MainCard>
      </div>
      <div class="col-12 md:col-4">
        <MainCard title="Выберите тип точки">
          <Dropdown
              v-model="form.point_type_id"
              :class="['w-full', {'p-invalid': errors?.point_type_id}]"
              :options="locationTypes"
              optionLabel="name_ru"
              optionValue="id"
              placeholder="Тип точки"
          />
          <span v-if="errors?.point_type_id" class="text-xs color-error">
            {{ errors.point_type_id[0] }}
          </span>
        </MainCard>
      </div>
      <div class="col-12 md:col-4">
        <MainCard title="Изображение иконки" isRequired>
          <span  v-if="errors?.icon" class="flex text-xs color-error mb-2">
              {{ errors.icon[0] }}
          </span>
            <div>
              <div v-if="form.icon" class="card__wrapper">
                <img :src="form.icon.preview_url ? form.icon.preview_url : form.icon.objectURL" class="mr-2 h-2rem w-2rem" alt=""/>
                <span>{{ form.icon.name ? form.icon.name : form.icon.original_url.split('/').pop() }}</span>
              </div>
            </div>
            <div>
              <ButtonFileUpload
                  @chooseFiles="loadFileMemory"
                  :label="form.icon ? 'Сменить иконку' : 'Добавить иконку'"
                  :multiple="false"
                  :clear-files-after-select="true"
                  :classes="{'btn-fix-height': true}"
              />
            </div>
        </MainCard>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.card__wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #DBD9D9;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  span {
    word-break: break-all;
  }
}
</style>