<script>
import {defineComponent, onBeforeMount, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import MainCard from "@/components/cards/MainCard.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {ButtonFileUpload, ButtonSuccess, MainCard, Dropdown, InputText, Breadcrumb, Button, ConfirmationModal},
  setup() {
    const store = useStore();
    const route = useRoute();
    const building = ref(null);
    const locationTypes = ref([]);
    const isUpdated = ref(false)
    const visible = ref(false);
    const points = ref([]);
    const destroyPoint = ref(null);
    const errorsArray = ref(null)
    const showModalDestroyPoint = (point) => {
      destroyPoint.value = point;
      visible.value = true;
    }

    const loadLocationTypes = async () => {
      try {
        if(route.name === 'building-attractions-points-edit') {
          await store.dispatch('fetchTypeAttractions');
        } else {
          await store.dispatch('fetchTypeLocations');
        }
      } catch (e) {
        console.error(e);
      }
    };
    const isLoading = ref(false)
    const updatePointHandler = async () => {
      isLoading.value = true
      errorsArray.value = []
      for (let i = 0; i < points.value.length; i += 1 ) {
        try {
          const formData = new FormData()
          for (const key in points.value[i]) {
            if (key !== 'icon' || (key === 'icon' && !points.value[i].icon?.uuid)) {
              formData.append(key, points.value[i][key])
            }
          }
          await store.dispatch('fetchUpdateBuildingLocationPoint', {
            buildingId: route.params.id,
            pointId: points.value[i].id,
            type: route.name === 'building-attractions-points-edit' ? 'attraction' : 'location',
            body: formData
          });
        } catch (e) {
          errorsArray.value = {
            index: i,
            value: e.response.data.data
          }
          return
        }
      }
      isLoading.value = false
      if (!errorsArray.value.length) {
        isUpdated.value = true
      }
    };

    const destroyPointHandler = async () => {
      try {
        await store.dispatch('fetchDeleteLocationPoint', {
          buildingId: route.params.id,
          pointId: destroyPoint.value.id,
          type: route.name === 'building-attractions-points-edit' ? 'attraction' : 'location',
        });
        points.value = points.value.filter(point => point.id !== destroyPoint.value.id);
        visible.value = false;
      } catch (e) {
        console.error(e)
      }
    };

    const loadFileMemory = (data, index) => {
      points.value[index].icon = data.files[0]
    }

    const isError = (index) => {
      return errorsArray.value.find(item => item.index === index)
    }

    watch(() => points.value,
        () => isUpdated.value = false, {deep: true}
    )

    onBeforeMount(async () => {
      try {
        await loadLocationTypes();
        await store.dispatch('fetchBuilding', route.params.id);
        building.value = store.getters.getCurrentBuilding
        locationTypes.value = route.name === 'building-attractions-points-edit' ? store.getters.getListTypeAttractions : store.getters.getListTypeLocations
        points.value = route.name === 'building-attractions-points-edit' ? building.value.attraction_points : building.value.location_points
      } catch (e) {
        console.error(e)
      }
    });

    return {
      locationTypes, building, visible, points, destroyPoint,
      destroyPointHandler, updatePointHandler, showModalDestroyPoint,
      isUpdated, route, errorsArray, loadFileMemory, isError, isLoading
    };
  },
})
</script>

<template>
  <ConfirmationModal v-model:visible="visible">
    <template #header>
      Удалить точку
    </template>

    <template #default>
      <span v-if="destroyPoint">
        Вы уверены, что хотите удалить точку {{ destroyPoint.name_ru }}
      </span>
    </template>

    <template #footer>
      <div class="flex justify-content-between">
        <Button @click="visible = false" label="Отмена" class="btn-black-20-outlined font-light w-full"/>
        <Button @click="destroyPointHandler" label="Удалить" class="btn-primary font-light ml-3 w-full"/>
      </div>
    </template>
  </ConfirmationModal>

  <section class="py-2 mb-3">
    <div class="flex justify-content-between align-items-center">
      <router-link
          :to="{name: 'building-edit', params: {id: route.params.id}}"
          class="color-black-80 font-bold no-underline text-3xl flex align-items-center"
      >
        <i class="pi pi-arrow-left text-1xl"></i>&nbsp;Редактирование
      </router-link>
      <ButtonSuccess v-if="isUpdated" label="Изменения сохранены" />
      <Button v-else  @click="updatePointHandler" :loading="isLoading" label="Сохранить изменения" class="btn-primary"/>
    </div>
  </section>
  <section v-if="points?.length" class="py-2 mb-3">
    <div class="grid">
      <div  class="col-12 md:col-4" v-for="(point, index) in points">
        <MainCard :title="point.name_ru">
          <template v-slot:title-action>
            <span
                @click="showModalDestroyPoint(point)"
                class="color-error underline text-xl cursor-pointer delete-button"
            >Удалить точку</span>
          </template>

          <div class="grid gap-3">
            <div class="col-12">
              <div class="w-full">
              <span class="p-float-label w-full">
                <InputText
                    v-model="point.name_ru"
                    id="last_name"
                    class="w-full"
                />
                <label for="last_name">Название точки <i style="color: #E26B6B">*</i></label>
              </span>
                <span v-if="errorsArray?.index === index && errorsArray?.value.name_ru" class="text-xs color-error">
                  {{ errorsArray.value.name_ru[0] }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="w-full">
              <span class="p-float-label w-full">
                <InputText
                    v-model="point.address_ru"
                    id="last_name"
                    class="w-full"
                />
                <label for="last_name">Адрес точки <i style="color: #E26B6B">*</i></label>
              </span>
                <span v-if="errorsArray?.index === index && errorsArray?.value.address_ru" class="text-xs color-error">
                  {{ errorsArray.value.address_ru[0] }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="w-full">
              <span class="p-float-label w-full">
                <InputText
                    v-model="point.lat"
                    id="last_name"
                    class="w-full"
                />
                <label for="last_name">Широта точки <i style="color: #E26B6B">*</i></label>
              </span>
                <span v-if="errorsArray?.index === index && errorsArray?.value.lat" class="text-xs color-error">
                  {{ errorsArray.value.lat[0] }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="w-full">
              <span class="p-float-label w-full">
                <InputText
                    v-model="point.lon"
                    id="last_name"
                    class="w-full"
                />
                <label for="last_name">Долгота точки <i style="color: #E26B6B">*</i></label>
              </span>
                <span v-if="errorsArray?.index === index && errorsArray?.value.lon" class="text-xs color-error">
                  {{ errorsArray.value.lon[0] }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="w-full">
              <span class="p-float-label w-full">
                <InputText
                    v-model="point.comment"
                    id="comment"
                    class="w-full"
                />
                <label for="comment">Расстояние до точки</label>
              </span>
                <span v-if="errorsArray?.index === index && errorsArray?.value.comment" class="text-xs color-error">
                  {{ errorsArray.value.comment[0] }}
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="p-float-label w-full">
                <Dropdown
                    v-model="point.point_type_id"
                    :options="locationTypes"
                    optionLabel="name_ru"
                    optionValue="id"
                    placeholder="Тип точки"
                    class="w-full"/>
                <label for="legal_form">Тип точки <i style="color: #E26B6B">*</i></label>
              </div>
              <span v-if="errorsArray?.index === index && errorsArray?.value.point_type_id" class="text-xs color-error">
                  {{ errorsArray.value.point_type_id[0] }}
                </span>
            </div>
          </div>
          <div>
            <span class="p-float-label w-full mb-2">Изображение иконки</span>
            <span  v-if="errorsArray?.index === index && errorsArray.value.icon" class="flex text-xs color-error mb-2">
              {{ errorsArray.value.icon[0] }}
          </span>
              <div v-if="point.icon" class="card__wrapper">
                <img :src="point.icon.original_url ? point.icon.original_url : point.icon.objectURL" class="mr-2 h-2rem w-2rem" alt=""/>
                <span>{{ point.icon.name ? point.icon.name : point.icon.original_url.split('/').pop() }}</span>
              </div>
            <div>
              <ButtonFileUpload
                  @chooseFiles="loadFileMemory"
                  :label="point.icon ? 'Сменить иконку' : 'Добавить иконку'"
                  :multiple="false"
                  :index="index"
                  :clear-files-after-select="true"
                  :classes="{'btn-fix-height': true}"
              />
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.card__wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #DBD9D9;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  span {
    word-break: break-all;
  }
}
</style>