<script>
import {defineComponent, ref} from "vue";

import FileUpload from "primevue/fileupload";
import Button from "primevue/button";

export default defineComponent({
  components: {FileUpload, Button},
  props: {
    label: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: false,
    },
    accept: {
      type: String,
      required: false,
    },
    multiple: {
      type: Boolean,
      required: false,
    },
    clearFilesAfterSelect: {
      type: Boolean,
      required: false,
    },
    classes: {
      type: Object,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    buttonText: {
      type: Boolean,
      required: false
    },
  },
  emits: ['chooseFiles'],
  setup(props, {emit}) {
    const upload = ref();
    const filesArray = ref([]);

    const toSelect = (files) => {
      filesArray.value = files;

      if (props.clearFilesAfterSelect === true) {
        upload.value.clear();
      }
      if(props.index || props.index === 0) {
        emit('chooseFiles' , filesArray.value, props.index);
      } else {
        emit('chooseFiles' , filesArray.value);
      }
    }

    return {upload, toSelect};
  }
});
</script>

<template>
  <div class="hidden">
    <FileUpload
        :mode="mode ? mode : 'advanced'"
        :accept="accept ? accept : 'image/*'"
        :multiple="multiple ?? true"
        name="demo[]"
        @select="toSelect"
        ref="upload"
    />
  </div>
  <span v-if="buttonText" @click="upload.choose()" :loading="isLoading" :class="classes ? classes : {}" class="color-primary underline cursor-pointer" >{{ label }}</span>
  <Button v-else :label="label" @click="upload.choose()" :loading="isLoading" :class="classes ? classes : {}" class="btn-primary btn-fix-height font-light" />
</template>