<script>
import MainCard from "@/components/cards/MainCard.vue";
import ImageCard from "@/components/cards/ImageCard.vue";
import ButtonFileUpload from "@/components/buttons/ButtonFileUpload.vue";
import InputText from "primevue/inputtext";
import {reactive, defineComponent, onMounted, ref, watch, computed} from "vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import WebsiteAddButton from "@/components/website/WebsiteAddButton.vue";
import MainCategoryItem from "@/components/website/MainCategoryItem.vue";
import Skeleton from "primevue/skeleton"
import Button from "primevue/button";
import {useApiCall} from "@/composables/useApiCall";
import {
  galleryApiCall,
  updateGalleryApiCall,
  addGalleryPhotoApiCall,
  deleteGalleryPhotoApiCall,
  addGalleryApiCall,
  deleteGalleryApiCall,
} from "@/services/website";
import {useVuelidate} from "@vuelidate/core";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { helpers, required } from '@vuelidate/validators'
import WebsiteCard from "@/components/cards/WebsiteCard.vue";

export default defineComponent({
  components: {
    WebsiteCard,
    ConfirmationModal,
    ButtonSuccess,
    ButtonFileUpload,
    ImageCard,
    InputText,
    MainCard,
    Button,
    WebsiteAddButton,
    Skeleton,
    MainCategoryItem,
  },
  setup() {
    const form = reactive({
      fields: []
    });
    const validError = ref(false)
    const errors = ref(null)
    const isUpdated = ref(false)
    const visible = ref(false)
    const addError = ref(null)
    const scope = 'category'
    const formRules = {
      fields: {
        $each: helpers.forEach({
          title_ru: {
            required
          }
        })
      }
    }
    const v$ = useVuelidate(formRules, form);
    const deleteItem = ref(null)
    const deletePhotos = ref([])

    const isSendLoading = computed(() =>
        isContentUpdateLoading.value ||
        isContentAddPhotoLoading.value ||
        isContentDeletePhotoLoading.value ||
        isContentAddLoading.value
    )

    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(galleryApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateGalleryApiCall, true)

    const {
      isLoading: isContentAddPhotoLoading,
      data: contentAddPhotoData,
      error: contentAddPhotoError,
      executeApiCall: addPhotoContentAction,
    } = useApiCall(addGalleryPhotoApiCall, true)

    const {
      isLoading: isContentDeletePhotoLoading,
      data: contentDeletePhotoData,
      error: contentDeletePhotoError,
      executeApiCall: deletePhotoContentAction,
    } = useApiCall(deleteGalleryPhotoApiCall, true)

    const {
      isLoading: isContentAddLoading,
      data: contentAddData,
      error: contentAddError,
      executeApiCall: addContentAction,
    } = useApiCall(addGalleryApiCall, true)

    const {
      isLoading: isContentDeleteLoading,
      data: contentDeleteData,
      error: contentDeleteError,
      executeApiCall: deleteContentAction,
    } = useApiCall(deleteGalleryApiCall, true)

    const destroyFileMemory = (file, index) => {
      form.fields[index].photos = form.fields[index].photos.filter(item => item !== file)
    }
    const deleteCategoryPhoto = (url, index) => {
      form.fields[index].photos = form.fields[index].photos.filter(item => item.url !== url)
    }

    const loadCategoryFileMemory = (data, index) => {
      form.fields[index].photos = form.fields[index].photos.concat(data.files)
    }

    const updateForm = async () => {
      addError.value = null
      validError.value = false
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      for (let i = 0; i < deletePhotos.value.length; i += 1) {
        try {
          await deletePhotoContentAction({id: deletePhotos.value[i].id, fields: {uuid: deletePhotos.value[i].uuid}})
        } catch (e) {
          console.error(e)
          return
        }
      }
      deletePhotos.value = []
      for (let i = 0; i < form.fields.length; i += 1) {
        if(!form.fields[i].id.toString().includes('newItem')) {
          const oldItem = contentData.value.data.findIndex(item => item.id === form.fields[i].id)
          if (contentData.value.data[oldItem] && contentData.value.data[oldItem].title_ru !== form.fields[i].title_ru) {
            try {
              await updateContentAction({id: form.fields[i].id, fields: {title_ru: form.fields[i].title_ru}})
              if (contentUpdateData.value) {
                contentData.value.data[oldItem].title_ru = contentUpdateData.value.data.title_ru
                form.fields[i].title_ru = contentUpdateData.value.data.title_ru
              }
            } catch (e) {
              addError.value = {item: form.fields[i], error: contentUpdateError.value.data}
              console.error(e)
              return
            }
          }
          if (contentData.value.data[oldItem] && contentData.value.data[oldItem].photos !== form.fields[i].photos) {
            const newImage = form.fields[i].photos.filter(item => !item.id)
            if (newImage.length) {
              for (let j = 0; j < form.fields[i].photos.length; j += 1) {
                if(!form.fields[i].photos[j].id) {
                  const formData = new FormData()
                  formData.append('photo', form.fields[i].photos[j])
                  try {
                    await addPhotoContentAction({id: form.fields[i].id, fields: formData})
                    if (contentAddPhotoData.value) {
                      contentData.value.data[oldItem] = {...contentAddPhotoData.value.data}
                      form.fields[i].photos[j] = contentAddPhotoData.value.data.photos[j]
                    }
                  } catch (e) {
                    addError.value = {item: form.fields[i], error: {text: contentAddPhotoError.value.data, url: form.fields[i].photos[j].objectURL}}
                    console.error(e)
                    return
                  }
                }
              }
            }
          }
        } else {
          try {
            await addContentAction({title_ru: form.fields[i].title_ru})
            if (contentAddData.value) {
              contentData.value.data.push(contentAddData.value.data)
              form.fields[i].id = contentAddData.value.data.id
            }
            if (form.fields[i].photos.length) {
              for (let j = 0; j < form.fields[i].photos.length; j += 1) {
                const formData = new FormData()
                formData.append('photo', form.fields[i].photos[j])
                try {
                  await addPhotoContentAction({id: form.fields[i].id, fields: formData})
                  if (contentAddPhotoData.value) {
                    contentData.value.data[i] = {...contentAddPhotoData.value.data}
                    form.fields[i].photos[j] = contentAddPhotoData.value.data.photos[j]
                  }
                } catch (e) {
                  addError.value = {item: form.fields[i], error: {text: contentAddPhotoError.value.data, index: j}}
                  console.error(e)
                  return
                }
              }
            }
          } catch (e) {
            addError.value = {item: form.fields[i], error: contentAddError.value.data}
            console.error(e)
            return
          }
        }
      }
      if(!addError.value) {
        isUpdated.value = true
      }
    }

    const addCategory = () => {
      form.fields.push({
        id: `newItem${form.fields.length + 1}`,
        photos: [],
        title_ru:''
      })
    }

    const changeVisibleModal = (index = null) => {
      visible.value = !visible.value
      if (index || index === 0) {
        deleteItem.value = index
      }
    }
    const deleteCategory = async () => {
      if (!form.fields[deleteItem.value].id.toString().includes('newItem')) {
        try {
          await deleteContentAction({id: form.fields[deleteItem.value].id })
        } catch (e) {
          console.error(e)
        }
      }
      if (contentDeleteData.value || form.fields[deleteItem.value].id.toString().includes('newItem')) {
        deletePhotos.value = deletePhotos.value.filter(item => item.id !== form.fields[deleteItem.value].id)
        form.fields.splice(deleteItem.value, 1);
        deleteItem.value = null
        changeVisibleModal()
      }
    }

    const getError = (item) => {
      if (addError.value && addError.value.item === item) {
        return addError.value.error
      } else {
        return null
      }
    }

    const changeCategory = (data, index) => {
      form.fields[index] = data
    }
    const deletePhotoCategory = (data) => {
      deletePhotos.value.push(data)
    }

    watch(() => form.fields,
        ()=> {
          isUpdated.value = false
        }, {deep: true})

    onMounted(async () => {
      try {
        await getContentAction()
        if (contentData.value?.data) {
          contentData.value.data.forEach(item => form.fields.push({
            id: item.id,
            photos: [...item.photos],
            title_ru: item.title_ru,
          }))
          form.fields = [...contentData.value.data]
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      form,
      errors,
      isUpdated,
      isContentLoading,
      visible,
      v$,
      addError,
      scope,
      changeVisibleModal,
      updateForm,
      destroyFileMemory,
      deleteCategoryPhoto,
      loadCategoryFileMemory,
      addCategory,
      deleteCategory,
      getError,
      validError,
      changeCategory,
      deletePhotoCategory,
      isSendLoading,
    };
  }
})
</script>

<template>
  <ConfirmationModal :visible="visible">
    <template #header>
      Удалить категорию
    </template>
    <template #default>
      <span>Вы уверены,  что хотите удалить категорию? Это действие будет невозможно отменить</span>
    </template>
    <template #footer>
      <div class="flex justify-content-between">
        <Button label="Отменить" @click="changeVisibleModal" class="btn-primary-outlined font-light w-12"/>
        <Button label="Удалить" @click="deleteCategory" class="btn-primary font-light ml-3 w-12"/>
      </div>
    </template>
  </ConfirmationModal>
  <div class="website-category">
    <div v-if="form.fields.length" class="flex justify-content-between align-items-center mb-4">
      <ButtonSuccess v-if="isUpdated" label="Изменения сохранены" class="ml-auto btn-fix-height"/>
      <Button v-else @click="updateForm" :loading="isSendLoading" label="Сохранить изменения" class="ml-auto btn-primary font-light btn-fix-height" />
    </div>
    <div v-if="form.fields.length" class="website-category__item">
      <MainCategoryItem
          v-for="(item, i) in form.fields"
          :key="item.id"
          :scope="scope"
          :category="item"
          :index="i"
          @deleteCategory="changeVisibleModal"
          @changeCategory="changeCategory"
          @deletePhotoCategory="deletePhotoCategory"
          :validError="validError"
          :errors="getError(item)"
          type="gallery"
      />
    </div>
    <Skeleton v-if="isContentLoading" height="121px"></Skeleton>
    <div v-else class="my-4">
      <WebsiteAddButton
          @clickHandler="addCategory"
          title="Добавить категорию"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.website-category {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    row-gap: 16px;
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>