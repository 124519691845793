<script>
import {computed, defineComponent} from "vue";

import DataView from "primevue/dataview";
import Checkbox from "primevue/checkbox";
import CategorySegmentTariffsForm from "@/views/buildings/category-segment/tabs/CategorySegmentTariffsForm.vue";
import {useStore} from "vuex";
export default defineComponent({
  components: {DataView, Checkbox, CategorySegmentTariffsForm},
  setup() {
    const store = useStore();
    const categoryTariffs = computed(() => store.getters.getRoomCategoryTariffsList);
    return {categoryTariffs};
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <span class="text-3xl font-bold">Долгосрочные тарифы</span>
  </section>

  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12 md:col-6" v-for="(tariff, i) in categoryTariffs" :key="i">
        <CategorySegmentTariffsForm :tariff="tariff" />
      </div>
    </div>
  </section>
</template>

<style>

</style>