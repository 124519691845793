<script>
import {defineComponent, onBeforeMount, reactive, ref, watch} from "vue";
import Editor from "primevue/editor";
import MainCard from "@/components/cards/MainCard";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";
import Button from "primevue/button";
import Skeleton from "primevue/skeleton"
import { aboutBrandApiCall, updateAboutBrandApiCall } from "@/services/website";
import { useApiCall } from '@/composables/useApiCall'
import {useI18n} from "vue-i18n";
import {required} from "@/utils/i18n-validators";
import {useVuelidate} from "@vuelidate/core";
export default defineComponent({
  components: {
    ButtonSuccess,
    Editor,
    MainCard,
    Button,
    Skeleton,
  },
  setup() {
    const {t} = useI18n()
    const form = reactive({
      text_ru: ''
    });
    const formRules = {
      text_ru: {required},
    }
    const v$ = useVuelidate(formRules, form);
    const isUpdated = ref(false)
    const {
      isLoading: isContentLoading,
      data: contentData,
      error: contentError,
      executeApiCall: getContentAction,
    } = useApiCall(aboutBrandApiCall, true)

    const {
      isLoading: isContentUpdateLoading,
      data: contentUpdateData,
      error: contentUpdateError,
      executeApiCall: updateContentAction,
    } = useApiCall(updateAboutBrandApiCall, true)

    const updateForm = async () => {
      const result = await v$.value.$validate();
      if (!result) {
        return;
      }
      try {
        await updateContentAction(form)
        if (contentUpdateData.value) {
          isUpdated.value = true
        }
      } catch (e) {
        console.error(e)
      }
    }

    watch(() => form.text_ru,
        () => {
          isUpdated.value = false
        })

    onBeforeMount( async () => {
      try {
        await getContentAction()
        if (contentData.value?.data) {
          form.text_ru = contentData.value.data.text_ru
        }
      } catch (e) {
        console.error(e)
      }
    })

    return {
      t,
      form,
      isUpdated,
      v$,
      updateForm,
      contentUpdateError,
      isContentLoading,
      contentError,
      isContentUpdateLoading,
    };
  }
})
</script>

<template>
  <div class="mt-7">
    <div class="flex justify-content-between align-items-center mb-4">
      <h1>{{ t('website.aboutBrand.title') }}</h1>
      <ButtonSuccess v-if="isUpdated" :label="t('button.changesSaved')" class="btn-fix-height"/>
      <Button v-else @click="updateForm" :loading="isContentUpdateLoading" :label="t('button.saveChange')" class="btn-primary font-light btn-fix-height" />
    </div>
    <Skeleton v-if="isContentLoading" width="100%" height="100px"></Skeleton>
    <MainCard v-else :title="t('description')" class="mb-3">
      <Editor v-model="form.text_ru" class="w-full"></Editor>
      <span v-if="v$.text_ru.$errors.length || contentUpdateError?.data.text_ru" class="text-xs color-error">
        {{ contentUpdateError ? contentUpdateError.data.text_ru[0] : t(v$.text_ru.$errors[0].$message) }}
      </span>
    </MainCard>
  </div>
</template>
