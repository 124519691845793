<script>
import {defineComponent, reactive, ref} from "vue";

import InputText from "primevue/inputtext"
import Checkbox from 'primevue/checkbox'
import {useStore} from "vuex";
import {useRoute} from "vue-router";
export default defineComponent({
  components: {InputText, Checkbox},
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const form = reactive({
      fields: {
        set: props.tariff.set,
        id: props.tariff.id,
        price_for_1_adult: props.tariff.set || props.tariff.price_for_1_adult ? props.tariff.price_for_1_adult : '',
        price_for_2_adult: props.tariff.set || props.tariff.price_for_2_adult ? props.tariff.price_for_2_adult : '',
        price_for_3_adult: props.tariff.set || props.tariff.price_for_3_adult ? props.tariff.price_for_3_adult : '',
        price_for_extra_adult: props.tariff.set || props.tariff.price_for_extra_adult ? props.tariff.price_for_extra_adult : '',
        price_for_1_child: props.tariff.set || props.tariff.price_for_1_child ? props.tariff.price_for_1_child : '',
        price_for_2_child: props.tariff.set || props.tariff.price_for_2_child ? props.tariff.price_for_2_child : '',
        price_for_3_child: props.tariff.set || props.tariff.price_for_3_child ? props.tariff.price_for_3_child : '',
      }
    });
    const errors = ref(null)

    const prepareData = (formData) => {
      return {
        price_for_1_adult: Number(formData.price_for_1_adult),
        price_for_2_adult: Number(formData.price_for_2_adult),
        price_for_3_adult: Number(formData.price_for_3_adult),
        price_for_extra_adult: Number(formData.price_for_extra_adult),
        price_for_1_child: Number(formData.price_for_1_child),
        price_for_2_child: Number(formData.price_for_2_child),
        price_for_3_child: Number(formData.price_for_3_child),
      }
    }
    const changeSetTariff = async () => {
      errors.value = null;
      const data = prepareData(form.fields);
      try {
        await store.dispatch('fetchTouchRoomCategoryTariff', {
          segmentId: route.params.segmentId,
          roomCategoryId: route.params.categoryId,
          body: {
            ...data,
            set: Number(!form.fields.set),
            tariff: form.fields.id,
          }
        })
        store.commit('updateRoomCategoryTariff', {
          ...data,
          set: form.fields.set,
          id: form.fields.id,
          description_ch: props.tariff.description_ch,
          description_en: props.tariff.description_en,
          description_ru: props.tariff.description_ru,
          name_ch: props.tariff.name_ch,
          name_en: props.tariff.name_en,
          name_ru: props.tariff.name_ru,
          short_description_ch: props.tariff.short_description_ch,
          short_description_en: props.tariff.short_description_en,
          short_description_ru: props.tariff.short_description_ru,
        })
      } catch (e) {
        form.fields.set = false
        errors.value = e.response.data.data
      }
    };

    return {form, changeSetTariff, errors};
  }
});
</script>

<template>
  <div class="flex flex-column align-items-start p-4 bg-white border-round-xl">
    <div class="flex flex-row align-items-start gap-3">
      <Checkbox
          @click="changeSetTariff"
          :binary="true"
          v-model="form.fields.set"
          class="mt-2"
      />
      <div class="flex flex-column align-items-center sm:align-items-start gap-3">
        <div class="text-2xl font-bold">
                <span class="text-xl font-bold text-900">
                  {{ tariff.name_ru }}
                </span>
        </div>
        <div class="flex align-items-center gap-3">
                  <span class="flex align-items-center color-black-40 gap-2">
                    <span class="font-semibold" v-html="tariff.description_ru"></span>
                  </span>
        </div>
      </div>
    </div>
    <div class="form mt-5 px-5">
      <div class="flex-column mb-5">
      <span class="p-float-label">
        <InputText
            v-model="form.fields.price_for_1_adult"
            :class="{'p-invalid': errors?.price_for_1_adult}"
            id="price_for_1_adult"
            class="w-full"
            :disabled="form.fields.set"
        />
        <label class="text-xs" for="price_for_1_adult">Стоимость за 1 человека, ₽</label>
      </span>
        <span v-if="errors?.price_for_1_adult" class="flex text-xs color-error">
          {{ errors.price_for_1_adult[0] }}
      </span>
      </div>
      <div class="flex-column mb-5">
      <span class="p-float-label ">
        <InputText
            v-model="form.fields.price_for_2_adult"
            :class="{'p-invalid': errors?.price_for_2_adult}"
            id="price_for_2_adult"
            class="w-full"
            :disabled="form.fields.set"
        />
        <label class="text-xs" for="price_for_2_adult">Стоимость за 2 людей, ₽</label>
      </span>
        <span v-if="errors?.price_for_2_adult" class="flex text-xs color-error">
          {{ errors.price_for_2_adult[0] }}
      </span>
      </div>
      <div class="flex-column mb-5">
      <span class="p-float-label">
        <InputText
            v-model="form.fields.price_for_3_adult"
            :class="{'p-invalid': errors?.price_for_3_adult}"
            id="price_for_3_adult"
            class="w-full"
            :disabled="form.fields.set"
        />
        <label class="text-xs" for="price_for_3_adult">Стоимость за 3 людей, ₽</label>
      </span>
        <span v-if="errors?.price_for_3_adult" class="flex text-xs color-error">
          {{ errors.price_for_3_adult[0] }}
      </span>
      </div>
      <div class="flex-column mb-5">
      <span class="p-float-label">
        <InputText
            v-model="form.fields.price_for_1_child"
            :class="{'p-invalid': errors?.price_for_1_child}"
            id="price_for_1_child"
            class="w-full"
            :disabled="form.fields.set"
        />
        <label class="text-xs" for="price_for_1_child">Стоимость за 1 ребенка, ₽</label>
      </span>
        <span v-if="errors?.price_for_1_child" class="flex text-xs color-error">
          {{ errors.price_for_1_child[0] }}
      </span>
      </div>
      <div class="flex-column mb-5">
      <span class="p-float-label">
        <InputText
            v-model="form.fields.price_for_2_child"
            :class="{'p-invalid': errors?.price_for_2_child}"
            id="price_for_2_child"
            class="w-full"
            :disabled="form.fields.set"
        />
        <label class="text-xs" for="price_for_2_child">Стоимость за 2 детей, ₽</label>
      </span>
        <span v-if="errors?.price_for_2_child" class="flex text-xs color-error">
          {{ errors.price_for_2_child[0] }}
      </span>
      </div>
      <div class="flex-column mb-5">
      <span class="p-float-label">
        <InputText
            v-model="form.fields.price_for_3_child"
            :class="{'p-invalid': errors?.price_for_3_child}"
            id="price_for_3_child"
            class="w-full"
            :disabled="form.fields.set"
        />
        <label class="text-xs" for="price_for_3_child">Стоимость за 3 детей, ₽</label>
      </span>
        <span v-if="errors?.price_for_3_child" class="flex text-xs color-error">
          {{ errors.price_for_3_child[0] }}
      </span>
      </div>
      <div class="flex-column mb-5">
      <span class="p-float-label">
        <InputText
            v-model="form.fields.price_for_extra_adult"
            :class="{'p-invalid': errors?.price_for_extra_adult}"
            id="price_for_extra_adult"
            class="w-full"
            :disabled="form.fields.set"
        />
        <label class="text-xs" for="price_for_extra_adult">Доплата за 1 человека, ₽</label>
      </span>
        <span v-if="errors?.price_for_extra_adult" class="flex text-xs color-error">
          {{ errors.price_for_extra_adult[0] }}
      </span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .form {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 100%;
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1800px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
</style>