<script>
import {defineComponent, unref} from "vue";
import {useTariff} from "@/hooks/tariff";

import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import Breadcrumb from "@/components/Breadcrumb.vue";
import MainCard from "@/components/cards/MainCard.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import ButtonSuccess from "@/components/buttons/ButtonSuccess";
import {useI18n} from "vue-i18n";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {Button, InputText, Dropdown, Editor, Breadcrumb, MainCard, ConfirmationModal, ButtonSuccess},
  setup() {
    const {t} = useI18n();
    const {form, v$, toggleSetDefault, toggleCreateTariff, tariffErrors, isCreated, typeTariffs} = useTariff();

    const breadcrumbs = [
      {label: t('menu.tariffs'), router: {name: 'tariffs'}},
      {label: t('headers.tariff-create')}
    ];

    return {
      t,
      form,
      typeTariffs,
      breadcrumbs,
      toggleCreateTariff,
      isCreated,
      toggleSetDefault,
      v$,
      tariffErrors,
    };
  }
});
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/"/>
      <Button v-if="!isCreated" :label="t('labels.tariff-create')" @click="toggleCreateTariff" class="btn-primary font-light"/>
      <ButtonSuccess v-if="isCreated" :label="t('labels.tariff-created')" @click="toggleSetDefault"/>
    </div>
  </section>
  <section class="py-2 mb-3">
    <div class="grid h-max">
      <div class="col-12 md:col-4">
        <MainCard :styles="{'h-full': true}" :title="t('card-names.tariff-name')">
          <div class="grid">
            <div class="col-12">
             <span class="p-float-label w-full">
              <InputText
                  :class="{'p-invalid': v$.name_ru.$errors.length || tariffErrors?.name_ru}"
                  v-model="form.name_ru"
                  id="name"
                  class="w-full"
              />
              <label for="name">{{ t('card-names.name') }} *</label>
             </span>
              <span class="color-error text-xs" v-if="v$.name_ru.$errors.length || tariffErrors?.name_ru">
                {{  tariffErrors ? tariffErrors.name_ru[0] : t(v$.name_ru.$errors[0].$message) }}
              </span>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  </section>

  <section class="py-2 mb-3">
    <div class="grid">
      <div class="col-12">
        <MainCard :styles="{'h-full': true}" :title="t('card-names.tariff-cost-description')">
          <div class="grid">
            <div class="col-12">
              <Editor v-model="form.description_ru" class="w-full" placeholder="Введите описание"/>
              <span v-if="v$.description_ru.$errors.length || tariffErrors?.description_ru" class="color-error text-xs">
                {{ tariffErrors ? tariffErrors.description_ru[0] : t(v$.description_ru.$errors[0].$message) }}}
              </span>
            </div>
          </div>
        </MainCard>
      </div>
    </div>
  </section>
</template>