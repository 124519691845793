<script>
import {defineComponent} from "vue";
import MainSlider from "@/components/website/MainSlider.vue";
import MainCategory from "@/components/website/MainCategory.vue";
import MainAbout from "@/components/website/MainAbout.vue";
import MainDescription from "@/components/website/MainDescription.vue";
import MainConcept from "@/components/website/MainConcept.vue";
import MainCorporativeClients from "@/components/website/MainCorporativeClients.vue";
import MainContacts from "@/components/website/MainContacts.vue";

export default defineComponent({
  components: {
    MainContacts,
    MainCorporativeClients,
    MainConcept,
    MainDescription,
    MainAbout,
    MainSlider,
    MainCategory,
  },
  setup() {
    return {
    };
  }
})
</script>

<template>
  <section class="website">
    <MainSlider type="slider"/>
    <MainCategory />
    <MainAbout />
    <MainDescription type="agentInfo"/>
    <MainSlider type="infrastructure"/>
    <MainConcept />
    <MainDescription type="awards"/>
    <MainCorporativeClients />
    <MainContacts />
  </section>
</template>
<style lang="scss" scoped>
.website {
  ::v-deep(.p-inputtext) {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>