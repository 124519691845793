<script>

import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useMeta} from "vue-meta";
import {useError} from "@/hooks/useErrors";
import {useCreateReactiveCopy} from "@/hooks/useCreateReactiveCopy";
import TabMenu from "primevue/tabmenu";
import Button from "primevue/button";
import Paginator from "primevue/paginator";
import Breadcrumb from "@/components/Breadcrumb.vue";
import SegmentsTabView from "@/views/buildings/tabs/SegmentsTabView.vue";
import InfrastructureTabView from "@/views/buildings/tabs/InfrastructureTabView.vue";
import ImprovementTabView from "@/views/buildings/tabs/ImprovementTabView.vue";
import LocationTabView from "@/views/buildings/tabs/LocationTabView.vue";
import AttractionTabView from "@/views/buildings/tabs/AttractionTabView.vue";
import DescriptionTabView from "@/views/buildings/tabs/DescriptionTabView.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import ButtonSuccess from "@/components/buttons/ButtonSuccess.vue";

export default defineComponent({
  layout: {name: 'AdminLayout'},
  components: {
    SegmentsTabView, ImprovementTabView, InfrastructureTabView, DescriptionTabView,
    LocationTabView, AttractionTabView, Breadcrumb, TabMenu, Button,
    Paginator, ConfirmationModal, ButtonSuccess,
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const store = useStore();
      await store.dispatch('fetchTypeLocations');
      await store.dispatch('fetchTypeAttractions');
      await store.dispatch('fetchBuilding', to.params.id);
      await store.dispatch('fetchBuildingSegments');
      next();
    } catch (e) {
      console.error(e);
    }
  },
  setup() {
    useMeta({
      title: "Редактирование здания"
    });
    const store = useStore();
    const errors = useError();

    const form = ref({
      name_ru: '', name_en: '', name_ch: '',
      address_ru: '', address_en: '', address_ch: '',
      description_ru: '', description_en: '', description_ch: '',
      location_ru: '', location_en: '', location_ch: '',
      infrastructure_ru: '', infrastructure_en: '', infrastructure_ch: '',
      improvement_ru: '', improvement_en: '', improvement_ch: '',
      attraction_ru: '', attraction_en: '', attraction_ch: '',
      lat: '', lon: '', common_photos: [], infrastructure_photos: [], improvement_photos: [],
      attraction_points: [], location_points: []
    });

    const breadcrumbs = ref([]);

    const tabs = ref([
      {label: 'Сегменты', component: 'SegmentsTabView'},
      {label: 'Описание', component: 'DescriptionTabView'},
      {label: 'Инфраструктура', component: 'InfrastructureTabView'},
      {label: 'Благоустройство', component: 'ImprovementTabView'},
      {label: 'Расположение', component: 'LocationTabView'},
      {label: 'Достопримечательности', component: 'AttractionTabView'}
    ]);

    const activeTabIndex = ref(0);
    const first = ref(0);
    const visible = ref(false);
    const isUpdated = ref(false);

    const activeTabComponent = computed(() => tabs.value[activeTabIndex.value]);
    const segments = computed(() => store.getters.getListBuildingSegments);
    const locationTypes = computed(() => store.getters.getListTypeLocations);
    const attractionsTypes = computed(() => store.getters.getListTypeAttractions);
    const building = computed(() => store.getters.getCurrentBuilding);
    const commonFiles = computed(() => store.getters.getCommonBuildingPhotos)
    const infrastructureFiles = computed(() => store.getters.getInfrastructureBuildingPhotos)
    const improvementFiles = computed(() => store.getters.getImprovementBuildingPhotos)
    const deleteFiles = computed(() => store.getters.getDeleteBuildingPhotos)
    const changeFormData = (data) => {
      form.value = data;
      isUpdated.value = false
    };

    const updateBuildingLocationPoint = async ({id, point_type_id}, type) => {
      await store.dispatch('fetchChangePointTypeLocationPoint', {
        buildingId: building.value.id,
        pointId: id,
        type: type,
        body: {point_type_id},
      });
    };

    const updateBuilding = async () => {
      errors.clearErrors()
      try {
        if(deleteFiles.value.length) {
          for (let i = 0; i < deleteFiles.value.length; i += 1) {
            await store.dispatch('fetchDeleteBuildingPhoto', {
              id: building.value.id,
              body: { uuid: deleteFiles.value[i]},
            })
          }
        }
        if(commonFiles.value.length) {
          const formData = new FormData();
          for (let i = 0; i < commonFiles.value.length; i += 1) {
            formData.append('type', 'common')
            formData.append('photo', commonFiles.value[i])
            await store.dispatch('fetchAddBuildingPhoto', {
              id: building.value.id,
              body: formData,
            })
          }
        }
        if(infrastructureFiles.value.length) {
          const formData = new FormData();
          for (let i = 0; i < infrastructureFiles.value.length; i += 1) {
            formData.append('type', 'infrastructure')
            formData.append('photo', infrastructureFiles.value[i])
            await store.dispatch('fetchAddBuildingPhoto', {
              id: building.value.id,
              body: formData,
            })
          }
        }
        if(improvementFiles.value.length) {
          const formData = new FormData();
          for (let i = 0; i < improvementFiles.value.length; i += 1) {
            formData.append('type', 'improvement')
            formData.append('photo', improvementFiles.value[i])
            await store.dispatch('fetchAddBuildingPhoto', {
              id: building.value.id,
              body: formData,
            })
          }
        }
        await store.dispatch('fetchUpdateBuilding', {
          id: building.value.id,
          body: {
            ...form.value,
            name: form.value.name_ru,
            description: form.value.description_ru,
            address: form.value.address_ru
          },
        });
        await changeFormData(building.value)
        isUpdated.value = true;
      } catch (e) {
        errors.setErrors(e.response.data.data);
      }
    };

    watch (() => [form.value, deleteFiles.value, improvementFiles.value, infrastructureFiles.value, commonFiles.value], () => {
          isUpdated.value = false
        }, {deep:true}
    )
    onMounted(async () => {
      try {
        breadcrumbs.value = [
          {label: 'Структура', router: {name: 'buildings'}},
          {label: building.value.name_ru}
        ];

        useCreateReactiveCopy(form.value, building.value);
      } catch (e) {
        console.error(e);
      }
    });

    return {
      tabs,
      visible,
      isUpdated,
      activeTabIndex,
      breadcrumbs,
      activeTabComponent,
      locationTypes,
      segments,
      building,
      first,
      form,
      changeFormData,
      updateBuildingLocationPoint,
      updateBuilding,
      errors: errors.errors,
      commonFiles,
      infrastructureFiles,
      improvementFiles,
      attractionsTypes,
    };
  }
})
</script>

<template>
  <section class="py-2 mb-3">
    <div class="flex justify-content-between">
      <Breadcrumb :data="breadcrumbs" separator="/" />
      <div class="flex">
        <ButtonSuccess v-if="isUpdated" label="Изменения сохранены" />
        <Button v-if="!isUpdated" @click="updateBuilding" label="Сохранить изменение" class="btn-primary font-light" />
      </div>
    </div>
  </section>
  <section class="py-2 mb-3">
    <TabMenu v-model:active-index="activeTabIndex" :model="tabs" />
  </section>
  <template v-if="activeTabComponent.component === 'SegmentsTabView'">
    <SegmentsTabView
        :segments="segments?.data?.data ?? []"
        :buildingId="building.id"
    />
    <Paginator
        v-model:first="first"
        :rows="segments?.data?.pagination?.per_page ?? 0"
        :totalRecords="segments?.data?.pagination?.total ?? 0"
        class="justify-content-start"
    ></Paginator>
  </template>
  <template v-else-if="activeTabComponent.component === 'AttractionTabView'">
    <AttractionTabView
        @toggleChangePointLocationType="updateBuildingLocationPoint"
        @changeFormData="changeFormData"
        :errors="errors"
        :formData="form"
        :locationTypes="attractionsTypes"
    />
  </template>
  <template v-else-if="activeTabComponent.component === 'LocationTabView'">
    <LocationTabView
        @toggleChangePointLocationType="updateBuildingLocationPoint"
        @changeFormData="changeFormData"
        :errors="errors"
        :formData="form"
        :locationTypes="locationTypes"
    />
  </template>
  <template v-else-if="activeTabComponent.component === 'DescriptionTabView'">
    <DescriptionTabView
        :formData="form"
        @changeFormData="changeFormData"
        :errors="errors"
        :files="commonFiles"
    />
  </template>
  <template v-else-if="activeTabComponent.component === 'InfrastructureTabView'">
    <InfrastructureTabView
        :formData="form"
        @changeFormData="changeFormData"
        :errors="errors"
        :files="infrastructureFiles"
    />
  </template>
  <template v-else-if="activeTabComponent.component === 'ImprovementTabView'">
    <ImprovementTabView
        :formData="form"
        @changeFormData="changeFormData"
        :errors="errors"
        :files="improvementFiles"
    />
  </template>
</template>
