import http from "../plugins/axios";

const loadBuildings = async (params = {}) => {
    const response = await http.get('/api/company/v1/building', {params});
    return response.data;
};

const loadBuilding = async (id) => {
    const response = await http.get('/api/company/v1/building/' + id);
    return response.data;
};

const createBuilding = async (body = {}) => {
    const response = await http.post('/api/company/v1/building', body);
    return response.data;
};

const updateBuilding = async (id, body) => {
    const response = await http.post('/api/company/v1/building/' + id, body);
    return response.data;
};

const destroyBuilding = async (id) => {
     // TODO: ДОБАВИТЬ ЗАПРОС НА УДАЛЕНИЕ ЗДАНИЯ
}

const createBuildingLocationPoint = async (id, type, body = {}) => {
    const response = await http.post(`/api/company/v1/building/${id}/point/${type}`, body);
    return response.data;
};

const updateBuildingLocationPoint = async (buildingId, pointId, type, body) => {
    const response = await http.post(
        `/api/company/v1/building/${buildingId}/point/${type}/${pointId}`, body
    );

    return response.data;
};

const changePointTypeLocationPoint = async (buildingId, pointId, type, body = {}) => {
    const response = await http.post(
        `/api/company/v1/building/${buildingId}/point/${type}/${pointId}/type`,
        body,
    );

    return response.data;
};

const deletePointLocationPoint = async (buildingId, pointId, type) => {
    const response = await http.get(
        `/api/company/v1/building/${buildingId}/point/${type}/${pointId}/delete`,
    );

    return response.data;
};

const addBuildingPhoto = async (id, body = {}) => {
    const response = await http.post(`/api/company/v1/building/${id}/photo/upload`, body);
    return response.data;
};

const deleteBuildingPhoto = async (id, body = {}) => {
    const response = await http.post(`/api/company/v1/building/${id}/photo/delete`, body);
    return response.data;
};

export {
    loadBuildings,
    loadBuilding,
    createBuilding,
    destroyBuilding,
    createBuildingLocationPoint,
    updateBuildingLocationPoint,
    updateBuilding,
    changePointTypeLocationPoint,
    addBuildingPhoto,
    deleteBuildingPhoto,
    deletePointLocationPoint,
};